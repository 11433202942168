import {Box, Button} from '@mantine/core';
import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {withTranslation} from "react-i18next";
import {modals} from '@mantine/modals';
import {signOut} from "firebase/auth";

import LoginForm from "../components/auth/LoginForm";
import RegistrationForm from "../components/auth/RegistrationForm";
import {globalContext} from "../App";
import headerClasses from "./Header.module.css";

const LoginButtons = observer(({t, drawerClassName = ''}: IProps) => {
    const globalStore = useContext(globalContext);
    const isAuthed = globalStore.userStore.isAuthed;
    const sighIn: string = t('Sign in');
    const sighUp: string = t('Sign up');
    const sighOut: string = t('Sign out');

    function logout() {
        signOut(globalStore.firebaseStore.getAuth()).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }

    function loadModal(isSignIn: boolean = false) {
        modals.open({
            title: isSignIn ? sighIn : sighUp,
            children: (
                <>
                    {isSignIn ? <LoginForm/> : <RegistrationForm/>}
                </>
            )
        });
    }

    return (
        <Box>
            {!isAuthed &&
                <Box className={drawerClassName || headerClasses.authButtonContainer}>
                    <Button variant={"transparent"}
                            className={headerClasses.headerButton}
                            onClick={() => loadModal(true)}>
                        {sighIn}
                    </Button>

                    <Button variant={"transparent"}
                            className={headerClasses.headerButton}
                            onClick={() => loadModal()}>
                        {sighUp}
                    </Button>
                </Box>
            }

            {isAuthed &&
                <Button variant={"transparent"}
                        className={headerClasses.headerButton}
                        onClick={logout}>
                    {sighOut}
                </Button>
            }
        </Box>
    );
})

export default withTranslation()(LoginButtons)

interface IProps {
    t: any;
    drawerClassName?: string
}
