import {Button, Image} from "@mantine/core";
import {useContext} from "react";
import {observer} from "mobx-react-lite";
import cx from 'clsx';
import {globalContext} from "../../../App";
import eye from "../../../assets/images/eye.png";
import eyeSlash from "../../../assets/images/eye-slash.png";
import minimize from "../../../assets/images/minimize.png";
import maximize from "../../../assets/images/maximize.png";
import c from "./Scenes.module.css";

const UiControls = observer((props: IProps) => {
    const global = useContext(globalContext);
    const stateStore = global.stateStore;
    const tourStore = global.tourStore;

    // TODO this function in four files and idential
    function toggleVisible() {
        const content: HTMLElement = document.querySelector('#minimapBlock');

        if (content) {
            if (stateStore.state.visibleUi) {
                content.style.cssText = 'display: none';
            } else {
                content.style.cssText = 'display: block';
            }
        }

        stateStore.updateAndSave({visibleUi: !stateStore.state.visibleUi}, 'uiControls update visible ui')
    }

    function twoDPlan() {
        if (tourStore.switcherMode === 'plan') {
            return true;
        }

        return !tourStore.ifHideUiForPopup;
    }

    return (
        <>
            {twoDPlan() &&
                <>
                    <Button className={cx(c.roundButton, c.topMediaScreenUi, c.zIndexForButtons)}
                            onClick={toggleVisible}>
                        {stateStore.state.visibleUi ? <Image src={eye}/> : <Image src={eyeSlash}/>}
                    </Button>

                    {
                        stateStore.state.visibleUi &&
                            <Button className={cx(c.roundButton, c.bottomMediaScreenUi, c.zIndexForButtons)}
                                    onClick={() => {props.toggle()}}>
                                {stateStore.state.fullscreen ? <Image src={minimize}/> : <Image src={maximize}/>}
                            </Button>
                    }
                </>
            }
        </>
    );
})

export default UiControls;

interface IProps {
    toggle: Function;
}
