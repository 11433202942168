import {HEXToRGB, validateOpacity} from "../utils/HotspotTools";
import {measureText} from "./hotspot-text-utils";
import {Entity, THREE} from "aframe";
import {IHotspotVisualDataText} from "../../../models/shared/IHotspotVisual";

export async function drawTextureOnCanvas(
    textOptions: IHotspotVisualDataText,
    message: string,
    fontSize: number,
    fontFace: string): Promise<HTMLCanvasElement>{

    const borderThickness = textOptions.borderThickness;
    const halfBorder = borderThickness / 2;
    const doubleBorder = borderThickness * 2
    const borderRadius = 3
    const backgroundColor = HEXToRGB(textOptions.backgroundColor ? textOptions.backgroundColor : '#DC4B3A')
    const backgroundOpacity = validateOpacity(textOptions.backgroundColorOpacity)
    const borderColor = HEXToRGB(textOptions.borderColor ? textOptions.borderColor : '#161718')
    const borderOpacity = validateOpacity(textOptions.borderColorOpacity)

    const textColor = HEXToRGB(textOptions.textColor ? textOptions.textColor : '#58AAEC')
    const textOpacity = validateOpacity(Number(textOptions.textOpacity))

    const loadFont =  new FontFace(fontFace, `url("/fonts/${fontFace}.ttf")`, {}).load()
    const canvas: HTMLCanvasElement = document.createElement('canvas') as unknown as HTMLCanvasElement;
    return await loadFont.then(result => {
        document.fonts.add(result);
        const messageWidth = measureText(message, fontSize, result)
        const canvasWidth = messageWidth + doubleBorder + (fontSize * 0.2)
        const canvasHeight = (fontSize + doubleBorder) * 2 - (fontSize * 0.235)
        canvas.width = canvasWidth
        canvas.height = canvasHeight

        const context: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;

        context.font = `${result.style} ${fontSize}px ${result.family}`;

        // get text size  (height depends only on font size)
        const metrics = context.measureText(message);
        const textWidth = metrics.width;

        // background options
        const bgWidth = textWidth + borderThickness + (fontSize * 0.2)
        const bgHeight = fontSize * 1.5 + borderThickness

        context.fillStyle = "rgba(" + backgroundColor.r + "," + backgroundColor.g + ","
            + backgroundColor.b + "," + backgroundOpacity + ")";

        // border options
        context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + ","
            + borderColor.b + "," + borderOpacity + ")";
        context.lineWidth = borderThickness;

        roundRect(
            context,
            halfBorder,
            halfBorder,
            bgWidth,
            bgHeight,
            borderRadius,
            textOptions.border,
            textOptions.background
        );

        // text options
        const textPositionX = borderThickness + (fontSize * 0.1)
        const textPositionY = fontSize * 1.15 + borderThickness
        context.fillStyle = `rgba(${textColor.r},${textColor.g},${textColor.b},${textOpacity})`;
        context.fillText(message, textPositionX, textPositionY);
        return canvas
    })




}

export function createSprite(texture: THREE.CanvasTexture, canvas: HTMLCanvasElement, parent: Entity){
    const spriteMaterial = new THREE.MeshBasicMaterial({map: texture, side: THREE.DoubleSide, transparent: true});
    const width = canvas.width / 400;
    const height = canvas.height / 400

    const geometry = new THREE.PlaneGeometry( width, height );
    const sprite = new THREE.Mesh( geometry, spriteMaterial );
    const spriteEntity: typeof AFRAME.AEntity = document.createElement('a-entity') as typeof AFRAME.AEntity
    spriteEntity.setAttribute('position', '0 0 0');
    spriteEntity.setAttribute('rotation', '0 0 0');

    spriteEntity.object3D.add(sprite);

    parent.appendChild(spriteEntity)
}
function roundRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    withBorder: boolean,
    withBG: boolean
) {

    ctx.beginPath();
    ctx.moveTo(x+radius, y);
    ctx.lineTo(x+width-radius, y);
    ctx.quadraticCurveTo(x+width, y, x+width, y+radius);
    ctx.lineTo(x+width, y+height-radius);
    ctx.quadraticCurveTo(x+width, y+height, x+width-radius, y+height);
    ctx.lineTo(x+radius, y+height);
    ctx.quadraticCurveTo(x, y+height, x, y+height-radius);
    ctx.lineTo(x, y+radius);
    ctx.quadraticCurveTo(x, y, x+radius, y);
    ctx.closePath();

    if (withBG){
        ctx.fill();
    }

    if (withBorder){
        ctx.stroke();
    }
}
