import React, {useContext, useEffect, useRef, useState} from 'react';
import {Box} from "@mantine/core";
import {observer} from "mobx-react-lite";
import cx from 'clsx';
import 'aframe';
import {Entity} from "aframe";
import {Camera} from "three";

import {globalContext} from "../../App";
import classes from "./view/Scenes.module.css";
import './scripts/aframe-components'
import {flushToDom} from "./scripts/flushToDOM";
import {useZoomControls} from "./hooks/useZoomControls"
import {useWindowDimensions} from "./hooks/useWindowDimensions";
import UiControls from "./view/UiControls";
import HotspotsRender from "./view/HotspotsRender";
import RightControls from "./view/RightControls";
import CustomOverlay from "./view/CustomOverlay";
import {defaultCameraRotation, mobileDeviceMaxWidth} from "../../vars";
import AudioAssets from "./AudioAssets";
import {prepareAudioHotspots} from "./utils/HotspotTools";
import {HotspotAudioData} from "../../models/shared/HotspotBehaviour";
import {IImageSceneProps} from "../../models/ISceneProps";
import {getInitCameraRotation, updateCameraAngle} from "./utils/Camera";
import {playMediaSafely} from "./utils/AudioVideoUtils";
import Minimap from "./view/Minimap";

const ImageScene = observer(({
                                 sceneData,
                                 clickHandler,
                                 toggle
                             }: IImageSceneProps) => {
        const cameraId = 'imageTourCamera';
        const {
            disabledZoomIn, disabledZoomOut, toggleZoomIn, toggleZoomOut,
            addZoomListener, removeZoomListener,
            getCameraSettings,
        } = useZoomControls(cameraId)
        const {orientation, width, height} = useWindowDimensions()
        const global = useContext(globalContext);
        const sceneStore = global.sceneStore;
        const tourStore = global.tourStore;
        const stateStore = global.stateStore;
        const skyPictureEl = useRef<Entity>(null);
        const sceneEl = useRef<Entity>(null);
        const cameraEl = useRef<Entity<Camera>>(null);
        const [imageLoading, setImageLoading] = useState<boolean>(true);
        const [hotspotsAudio, setHotspotsAudio] = useState<HotspotAudioData[]>([]);
        const menuType = tourStore.menuType

        useEffect(() => {
            sceneEl.current!.addEventListener('image-loaded', () => {
                setImageLoading(false);
                sceneStore.setLoadedScene(true);

                const audioElem: HTMLAudioElement = document.getElementById('customAudio') as HTMLAudioElement;

                if (audioElem?.src) {
                    sceneStore.setCurrentAudioElement(audioElem)
                    playMediaSafely(sceneStore.currentAudioElement)
                }

                const lookControls = cameraEl.current!.components['look-controls'];
                let {x, y, z} = getInitCameraRotation(lookControls, sceneData.cameraData!, stateStore.state.rotation);
                updateCameraAngle(lookControls, x, y);
                flushToDom()
            })

            stateStore.updateAndSave({sceneNodeName: sceneData.nodeName}, 'image scene set scene name')
            setHotspotsAudio(prepareAudioHotspots(sceneData.hotspots))
            addZoomListener("imageScene");

            return function cleanup() {
                removeZoomListener("imageScene");
                stateStore.updateAndSave({rotation: defaultCameraRotation}, 'image scene set default rotation')
                sceneStore.setSceneCamera(null);
            };
        }, []);

        useEffect(() => {
            cameraEl.current && sceneStore.setSceneCamera(cameraEl.current);
        }, [cameraEl.current]);

        return (
            <Box className={classes.sceneContainer}>
                <>
                    <CustomOverlay visible={imageLoading}></CustomOverlay>

                    <Box className={classes.sceneBody}>
                        <a-scene ref={sceneEl}
                                 image-scene={"skyPicture: #skyTour; imageTour: #imageTour; imgLink: " + sceneData.nodeDataLink + "; "}
                                 device-orientation-permission-ui="enabled: false"
                                 embedded={"true"}
                                 key={sceneData.nodeName}
                                 vr-mode-ui="enabled: false"
                                 id="imageScene"
                                 scale="1 1 1">
                            <a-assets id="sceneAssets">
                                <img id="imageTour" crossOrigin="anonymous" alt=''/>
                                <AudioAssets hotspotsAudio={hotspotsAudio} sceneData={sceneData}/>
                            </a-assets>

                            {!imageLoading && sceneData.hotspots?.length > 0 &&
                                <HotspotsRender hotspots={sceneData.hotspots}
                                                clickHandler={clickHandler}
                                                key={'hotspots-' + sceneData.nodeName}/>}

                            <a-entity id={cameraId}
                                      ref={cameraEl}
                                      camera={getCameraSettings()}
                                      look-controls="reverseMouseDrag: true; magicWindowTrackingEnabled: false">
                            </a-entity>

                            <a-sky id="skyTour" ref={skyPictureEl}/>
                        </a-scene>
                    </Box>

                    {stateStore.state.visibleUi &&
                        <>
                            <Minimap/>

                            {tourStore.generalSettings.showControlsMenuButton &&
                                <>
                                    {/*TODO не нравиться что 2 елемента RightControls, нужно посмотреть оптимизацию и переиспользование*/}
                                    <Box className={cx(
                                        (orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth)
                                            ? classes.sceneControlsTop
                                            : classes.sceneControls,
                                        classes[menuType + 'SceneControls']
                                    )}>
                                        <RightControls toggleZoomIn={toggleZoomIn}
                                                       toggleZoomOut={toggleZoomOut}
                                                       disabledZoomIn={disabledZoomIn}
                                                       disabledZoomOut={disabledZoomOut}/>
                                    </Box>

                                    {/*{global.tourStore.switcherMode === SwitcherType.plan &&*/}
                                    {/*    <Box className={cx(*/}
                                    {/*        (orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth)*/}
                                    {/*            ? classes.sceneControlsTop*/}
                                    {/*            : classes.sceneControls,*/}
                                    {/*        classes[menuType + 'SceneControls'],*/}
                                    {/*        classes.zIndexForButtons)}>*/}
                                    {/*        <RightControls2DPlan/>*/}
                                    {/*    </Box>*/}
                                    {/*}*/}
                                </>
                            }
                        </>
                    }

                    <Box className={cx(classes.contentUiVisible, classes[menuType + 'UiVisible'])}>
                        <UiControls toggle={toggle}/>
                    </Box>
                </>
            </Box>
        );
    }
)

export default ImageScene;
