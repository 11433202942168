import {IHotspotData, INodeData} from "./IProjectBase";
import {IHotspotVisualDataIcon} from "./IHotspotVisual";

export interface I2DPlanScene extends INodeData {
    bgColor?: string; // not implemented at viewer so far
    linkedScenes: string[]; // nodeName
    hotspots: IHotspot2dPlan[];
}

export interface IHotspot2dPlan {
    hotspotData: IHotspotData2dPlan;
}
export interface IHotspotData2dPlan extends IHotspotData {
    hotspotCurrentState: IHotspotVisualDataIcon, // styles for red target icon
}

export enum MiniMapState {
    "small" = "small",
    "big" = "big",
}
