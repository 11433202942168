import {defaultHotspotSize, maxHotspotSize} from "../../../vars";

AFRAME.registerComponent('virtik-hotspot-button', {
    schema: {
        behaviourParam: {type: 'string', default: '' },
        behaviourType: {type: 'string', default: ''},
        iconColor: {type: 'string', default: ''},
        iconSize: {type: 'number', default: 0},
        pngUrl: {type: 'string', default: ''},
        hotspotName: {type: 'string', default: ''},
    },
    init: function() {
        var data = this.data;
        var el = this.el;
        var item = el.getAttribute("virtik-item");
        // @ts-ignore
        this.item = item;

        let hotspotWidth = item.width
        let hotspotHeight = item.height

        if (data.iconSize && data.iconSize > 0) {
            if (data.iconSize > maxHotspotSize) {
                hotspotWidth = maxHotspotSize
                hotspotHeight = maxHotspotSize
            } else {
                hotspotWidth = data.iconSize / defaultHotspotSize
                hotspotHeight = data.iconSize / defaultHotspotSize
            }
        }

        el.setAttribute('geometry', `primitive: plane; skipCache: false; height: ${hotspotHeight}; width: ${hotspotWidth};`);
        el.setAttribute('material', `shader: flat; transparent: true; opacity: 0.0; alphaTest: 0.5; side:double; color: #fff`);
        // var buttonEntity = document.createElement("a-entity");
        // buttonEntity.setAttribute('rotation', data.rotation);

        var pngImage = document.createElement("a-image");
        pngImage.setAttribute('src', data.pngUrl)
        pngImage.setAttribute('material', `shader: flat; opacity: 1; side:double; color: ${data.iconColor}; alphaTest: 0.01`);
        pngImage.setAttribute('width', hotspotWidth)
        pngImage.setAttribute('height', hotspotHeight)
        pngImage.setAttribute('rotation', data.rotation);
        pngImage.setAttribute('position', data.position);

        var buttonEntity = document.createElement("a-entity");
        buttonEntity.appendChild(pngImage);

        el.appendChild(buttonEntity);
    },
    play: function () {},
    update: function () {},
});

AFRAME.registerPrimitive( 'a-virtik-hotspot-button', {
    defaultComponents: {
        'virtik-item': { type: 'hotspot-button' },
        'virtik-hotspot-button': { }
    },
    mappings: {
        'width': 'virtik-item.width',
        'height': 'virtik-item.height',
        'margin': 'virtik-item.margin',
        'icon-color': 'virtik-hotspot-button.iconColor',
        'icon-size': 'virtik-hotspot-button.iconSize',
        'png-url': 'virtik-hotspot-button.pngUrl',
        'behaviour-type': 'virtik-hotspot-button.behaviourType',
        'behaviour-parameters': 'virtik-hotspot-button.behaviourParam',
    }
});

export {}
