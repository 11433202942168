import {Box, Button, Image, ScrollArea} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import * as DOMPurify from "dompurify";
import close from "../../../assets/images/close.png";
import minimize from "../../../assets/images/minimize.png";
import maximize from "../../../assets/images/maximize.png";
import zoomInIcon from "../../../assets/images/zoom-in.png";
import zoomOutIcon from "../../../assets/images/zoom-out.png";
import scrollbarStyles from "./TextScrollbar.module.css"
import sceneStyles from "../view/Scenes.module.css";
import modalStyles from "./ModalHotspots.module.css";
import {HotspotBehaviourText} from "../../../models/shared/HotspotBehaviour";

const fonts = ["Inter", "Montserrat", "Open Sans", "Roboto", "Tinos"];

const ModalHotspotsText = observer(({data, closeHandler, fullscreenHandler}: IProps) => {
    const [isMaximize, setIsMaximize] = useState<boolean>(false);
    const defaultScale = 16;
    const minScale = 8;
    const maxScale = 36;
    const stepScale = 2;
    const [percentScale, setPercentScale] = useState(1);
    const [currentScale, setCurrentScale] = useState(defaultScale);

    useEffect(() => {
        if (data?.text) {
            data.text = purifyText(data.text);
        }
    }, []);

    function purifyText(text: string) {
        const replaced = text
            .replaceAll(/<align=(.*?)>/g, '<p align="$1">')
            .replaceAll('</align>', '</p>')
            .replaceAll(/<font=([\w]*)[^>]*?>/g, (m, g1) => `<span style="font-family: ${getCanonicalFontName(g1)}">`)
            .replaceAll('</font>', '</span>')
            .replaceAll(/<color=(.*?)>/g, '<span style="color: $1">')
            .replaceAll('</color>', '</span>')
            .replaceAll(/<size=(.*?)>/g, '<span class="custom-font-size" style="font-size: $1px">')
            .replaceAll('</size>', '</span>')
            .replaceAll(/<i>/g, '<i style="font-style: italic">')
            .replaceAll('\\n', '<br />');

        const purified: string = DOMPurify.sanitize(replaced,
            {
                ALLOWED_TAGS: ['b', 'i', 'u', 'p', 'br', 'span'],
            });
        loadRequiredFonts(purified);
        return purified;
    }

    function ignoreSpaces(raw, f) {
        return raw.includes(f.split(" ").join(""))
    }

    function loadRequiredFonts(purified: string) {
        const usedFonts = Array.from(purified.matchAll(/font-family:\s*([\w\s]*?)"/g))
            .map(a => a[1])
            .filter(raw => fonts.some(f => ignoreSpaces(raw, f)));

        usedFonts.forEach(raw => {
            const canonicalFontName = fonts.find(f => ignoreSpaces(raw, f))!;
            new FontFace(canonicalFontName, `url("/fonts/${canonicalFontName}.ttf")`, {}).load();
        })
    }

    function getCanonicalFontName(raw) {
        return fonts.find(f => ignoreSpaces(raw, f));
    }

    function handleZoomIn() {
        const element = document.getElementById('scaleBlock')!;
        const scale = currentScale + stepScale;
        const elements = document.getElementsByClassName('custom-font-size') as HTMLCollectionOf<HTMLElement>;

        setCurrentScale(scale);
        setPercentScale(percentScale + (stepScale / 10));
        element.style.fontSize = scale + 'px';

        for (let i = 0; i < elements.length; i++) {
            const computedStyle = window.getComputedStyle(elements[i]);
            const currentFontSize = parseInt(computedStyle.getPropertyValue('font-size'));
            const zoomInValue = currentFontSize + stepScale;
            elements[i].style.fontSize = zoomInValue + 'px';
        }
    }

    function handleZoomOut() {
        const element = document.getElementById('scaleBlock')!;
        const scale = currentScale - stepScale;
        const elements = document.getElementsByClassName('custom-font-size') as HTMLCollectionOf<HTMLElement>;

        setCurrentScale(scale);
        setPercentScale(percentScale - (stepScale / 10));
        element.style.fontSize = scale + 'px';

        for (let i = 0; i < elements.length; i++) {
            const computedStyle = window.getComputedStyle(elements[i]);
            const currentFontSize = parseInt(computedStyle.getPropertyValue('font-size'));
            const zoomInValue = currentFontSize - stepScale;
            elements[i].style.fontSize = zoomInValue + 'px';
        }
    }

    return (
        <Box className={modalStyles.pdfBody}>
            <Box className={modalStyles.hotspotPdfControls}>
                <Button onClick={() => {closeHandler()}}
                        className={sceneStyles.roundButton}>
                    <Image src={close}/>
                </Button>

                <Button className={sceneStyles.roundButton}
                        onClick={() => {
                            fullscreenHandler(isMaximize);
                            setIsMaximize(!isMaximize);
                        }}>
                    {isMaximize ? <Image src={minimize}/> : <Image src={maximize}/>}
                </Button>

                <Button onClick={handleZoomIn}
                        disabled={currentScale >= maxScale}
                        className={sceneStyles.roundButton}>
                    <Image src={zoomInIcon}/>
                </Button>

                <span>
                    {Math.round(percentScale * 100)}%
                </span>

                <Button onClick={handleZoomOut}
                        disabled={currentScale <= minScale}
                        className={sceneStyles.roundButton}>
                    <Image src={zoomOutIcon}/>
                </Button>
            </Box>

            <ScrollArea classNames={scrollbarStyles} style={{backgroundColor: data.backgroundColor}}>
                <Box id="scaleBlock" className={modalStyles.pdfWrapper}>
                    {data &&
                        <pre className={modalStyles.preTextContent}>
                            <div dangerouslySetInnerHTML={{__html: data.text}}/>
                        </pre>
                    }
                </Box>
            </ScrollArea>
        </Box>
    );
})

interface IProps {
    data: HotspotBehaviourText;
    closeHandler: CallableFunction;
    fullscreenHandler: CallableFunction;
}

export default ModalHotspotsText
