import {getCssVariable, getEmSize} from "./utils/cssHelper";

export const mobileDeviceMaxWidth = 480
export const tabletsMaxWidth = 768
export const laptopsMaxWidth = 1024
export const desktopsMaxWidth = 1200
export const extraLargeMinWidth = 1201
export const extraLargeMaxHeight = 750
export const videoStep: number = 5; //seconds
export const contentMaxWidth =  getCssVariable('--maxWidth')
export const contentMaxHeight = getCssVariable('--maxHeight')
export const maxHotspotSize = 128
export const defaultHotspotSize = 44 // TODO double check, editor uses 128 px as scale 1 icon
export const maxFontSize = 20
export const minFontSize = 10
export const defaultCameraRotation = {x: 0, y: -Math.PI / 2, z: 0}
export const defaultZoomData = {zoomIn: 2, zoomOut: 0.5, initialZoom: 1}
export const breakpointXS = getEmSize(mobileDeviceMaxWidth)
export const breakpointXSDefault = '36em'
export const breakpointSM = getEmSize(tabletsMaxWidth)
export const breakpointMD = getEmSize(laptopsMaxWidth)
export const breakpointLG = getEmSize(desktopsMaxWidth)
export const breakpointXL = '88em'

export const keyOrange       = '#ed5b21' // rgb(237, 91, 33) Light orange
export const key_orange_light = '#ef8c60' // rgb (239, 140, 96) Extra Light Orange
export const keyGrey         = '#22252a' // rgb(34, 37, 42) Standard grey
export const keyGreyDark    = '#2c3037' // rgb(44, 48, 55) Medium grey
export const key_grey_light   = '#606876' // rgb(96, 104, 118) Light grey
export const keyOffWhite     = '#d3d3d4' // rgb(211, 211, 212) Extra Light grey
export const keyWhite        = '#fff'

// default colors scheme
export const mainBlackBlueWhiteColor = '#005EEB';
export const mainBlackRedWhiteColor = '#c40f0f';
export const defaultBackgroundDarkColor = '#212529';
export const defaultBackgroundContentDarkColor = '#262626';
export const defaultContentTitleColor = '#101113';

export const headerButtonBackgroundColor = mainBlackBlueWhiteColor;
export const headerCopiedButtonBackgroundColor = '#12B886';
export const headerTourNameTextColor = '#F8F9FA';

export const buttonTextTransparentColor = mainBlackBlueWhiteColor;
export const buttonTextTransparentColorSecondary = mainBlackRedWhiteColor;
export const buttonOutlineColor = mainBlackBlueWhiteColor;
export const buttonOutlineColorSecondary = mainBlackRedWhiteColor;

export const contentButtonBackgroundColor = '#434447';
export const contentButtonHoverColor = '#707274';
export const contentButtonActiveColor = mainBlackBlueWhiteColor;
export const contentButtonActiveColorSecondary = mainBlackRedWhiteColor;
export const contentButtonDisabledColor = '#161718';

export const switcherBackgroundActiveColor = mainBlackBlueWhiteColor;
export const switcherBackgroundActiveColorSecondary = mainBlackRedWhiteColor;
export const switcherBackgroundColor = '#161718';
export const switcherIconDefaultColor = '#707274';
export const switcherIconHoverColor = '#434447';
