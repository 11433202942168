import 'aframe';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Box, Notification} from "@mantine/core";
import cx from 'clsx';
import {Camera} from "three";
import {useInterval} from "@mantine/hooks";
import {observer} from "mobx-react-lite";
import {withTranslation} from "react-i18next";
import {Entity} from "aframe";
import {globalContext} from "../../App";
import {useVideoControls} from "./hooks/useVideoControls";
import {useVideoHandlers} from "./hooks/useVideoHandlers";
import {useZoomControls} from "./hooks/useZoomControls";
import {useWindowDimensions} from "./hooks/useWindowDimensions";
import RightControls from "./view/RightControls";
import VideoSlider from "./view/VideoSlider";
import UiControls from "./view/UiControls";
import HotspotsRender from "./view/HotspotsRender";
import CustomOverlay from "./view/CustomOverlay";
import classes from "./view/Scenes.module.css";
import {IVideoSceneProps} from "../../models/ISceneProps";
import AudioAssets from "./AudioAssets";
import {prepareAudioHotspots} from "./utils/HotspotTools";
import {getCameraRotation, getInitCameraRotation, updateCameraAngle} from "./utils/Camera";
import RightControls2DPlan from "./plan2d/RightControls2DPlan";
import {flushToDom} from "./scripts/flushToDOM";
import {defaultCameraRotation, mobileDeviceMaxWidth} from "../../vars";
import Minimap from "./view/Minimap";

const VideoScene = observer(({
                                 sceneData,
                                 clickHandler,
                                 toggle
                             }: IVideoSceneProps) => {
        const {orientation, width, height, isIos} = useWindowDimensions()
        const global = useContext(globalContext);
        const stateStore = global.stateStore;
        const tourStore = global.tourStore;
        const sceneStore = global.sceneStore;
        const [videoUrl, setVideoUrl] = useState<string>('');
        const [hotspotsAudio, setHotspotsAudio] = useState<any>([]);
        const cameraEl = useRef<Entity<Camera>>(null);
        const cameraId = 'customCamera';
        const {start: setCamSaveLoop, stop: stopCamSaveLoop} = useInterval(() => {
            if (!cameraEl.current) return
            const lookControls = cameraEl.current!.components['look-controls'] as any;
            stateStore.updateAndSave({rotation: getCameraRotation(lookControls)}, 'video scene useInterval')
        }, 5000);
        const menuType = tourStore.menuType

        const {
            isPlay, togglePlay,
            rangeValue,
            updateRangeValue,
            backwardStep,
            forwardStep,
            initVideoElem,
            initCustomAudioElem,
            resetVideoRotation,
            setVideoCurrentTime,
            resetVideoElem
        } = useVideoControls();
        const {
            loadVideoListeners,
            loading,
            hasError,
            showError,
            isWaiting,
            bufferSize
        } = useVideoHandlers(
            togglePlay,
            updateRangeValue,
            initVideoElem,
            initCustomAudioElem
        )
        const {
            disabledZoomIn, disabledZoomOut, toggleZoomIn, toggleZoomOut,
            addZoomListener, removeZoomListener,
            getCameraSettings,
        } = useZoomControls(cameraId)

        useEffect(() => {
            if (!sceneData?.nodeDataLink) {
                return console.error("Scene is missing nodeDataLink!");
            }

            setVideoUrl(sceneData.nodeDataLink)
            stateStore.updateAndSave({sceneNodeName: sceneData.nodeName}, 'video scene set scene name')
            setHotspotsAudio(prepareAudioHotspots(sceneData.hotspots))

            return function cleanup() {
                removeZoomListener("videoScene");
                stopCamSaveLoop();
                resetVideoElem();
                stateStore.updateAndSave({rotation: defaultCameraRotation}, 'video scene set default rotation')
                sceneStore.setSceneCamera(null);
            };
        }, []);

        useEffect(() => {
            loadVideoElem();
            addZoomListener("videoScene");
        }, [videoUrl])

        useEffect(() => {
            if (!loading && !hasError) {
                initCamera();
                setCamSaveLoop();
            }
        }, [loading, hasError])

        useEffect(() => {
            cameraEl.current && sceneStore.setSceneCamera(cameraEl.current);
        }, [cameraEl.current]);


        function loadVideoElem() {
            const item: HTMLVideoElement = document.getElementById('video') as HTMLVideoElement;

            if (!item) return

            const audioEl: HTMLAudioElement = document.getElementById('customAudio') as HTMLAudioElement;

            initVideoElem(item)
            audioEl && initCustomAudioElem(audioEl)
            loadVideoListeners()
        }

        function initCamera() {
            if (!cameraEl.current) return

            const lookControls = cameraEl.current!.components['look-controls'];
            let {x, y, z} = getInitCameraRotation(lookControls, sceneData.cameraData!, stateStore.state.rotation);
            stateStore.updateAndSave({rotation: getCameraRotation(lookControls)}, 'video scene update rotation')
            updateCameraAngle(lookControls, x, y);
            flushToDom()
        }

        function isMuted() {
            if (sceneData?.isVideoMuted) {
                return !sceneData?.isVideoMuted ? stateStore.state.mute : sceneData?.isVideoMuted
            } else {
                return stateStore.state.mute
            }
        }

        return (
            <Box className={classes.sceneContainer}>
                {videoUrl &&
                    <>
                        <CustomOverlay visible={loading}></CustomOverlay>
                        <CustomOverlay visible={isWaiting}></CustomOverlay>

                        {!hasError &&
                            <>
                                <Box className={classes.sceneBody}>
                                    <a-scene device-orientation-permission-ui="enabled: false"
                                             embedded='true'
                                             scale='1 1 1'
                                             vr-mode-ui="enabled: false"
                                             id="videoScene">
                                        <a-assets id="sceneAssets">
                                            {isIos()
                                                ? <video id="video"
                                                         playsInline={true}
                                                         autoPlay={true}
                                                         muted={isMuted()}
                                                         crossOrigin="anonymous"
                                                         src={videoUrl}/>
                                                : <video id="video"
                                                         muted={isMuted()}
                                                         crossOrigin="anonymous"
                                                         src={videoUrl}/>
                                            }

                                            <AudioAssets hotspotsAudio={hotspotsAudio} sceneData={sceneData}/>
                                        </a-assets>

                                        {!loading && sceneData.hotspots?.length > 0 &&
                                            <HotspotsRender hotspots={sceneData.hotspots}
                                                            clickHandler={clickHandler}
                                                            key={'hotspots-' + sceneData.nodeName}/>}

                                        {/*@ts-ignore*/}
                                        <a-videosphere id='videosphereCustom' src='#video' position="0 0 -1.5"/>

                                        <a-entity id={cameraId}
                                                  ref={cameraEl}
                                                  camera={getCameraSettings()}
                                                  wasd-controls-enabled={false}
                                                  look-controls="reverseMouseDrag: true; magicWindowTrackingEnabled: false">
                                        </a-entity>
                                    </a-scene>
                                </Box>

                                {stateStore.state.visibleUi &&
                                    <>
                                        <Minimap/>

                                        {tourStore.generalSettings.showControlsMenuButton &&
                                            <>
                                                <Box className={cx((orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth) ? classes.sceneControlsTop : classes.sceneControls, classes[menuType + 'SceneControls'])}>
                                                    <RightControls toggleZoomIn={toggleZoomIn}
                                                                   toggleZoomOut={toggleZoomOut}
                                                                   disabledZoomIn={disabledZoomIn}
                                                                   disabledZoomOut={disabledZoomOut}/>
                                                </Box>

                                                {global.tourStore.switcherMode === 'plan' &&
                                                    <Box className={cx((orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth) ? classes.sceneControlsTop : classes.sceneControls, classes[menuType + 'SceneControls'], classes.zIndexForButtons)}>
                                                        <RightControls2DPlan/>
                                                    </Box>
                                                }
                                            </>
                                        }

                                        {!tourStore.ifHideUiForPopup &&
                                            <Box className={classes.videoRange}>
                                                <VideoSlider isPlay={isPlay}
                                                             rangeValue={rangeValue}
                                                             bufferSize={bufferSize}
                                                             backwardStep={backwardStep}
                                                             forwardStep={forwardStep}
                                                             updateRangeValue={updateRangeValue}
                                                             maxVideoDuration={sceneStore.videoDuration}
                                                             togglePlay={togglePlay}
                                                             setVideoCurrentTime={setVideoCurrentTime}
                                                             rotateVideo={resetVideoRotation}
                                                             isMinWidth={stateStore.state.fullscreen}/>
                                            </Box>
                                        }
                                    </>
                                }

                                <Box className={cx(classes.contentUiVisible, classes[menuType + 'UiVisible'])}>
                                    <UiControls toggle={toggle}/>
                                </Box>
                            </>
                        }

                        {(!loading && hasError && showError) &&
                            <Notification title="Notification" withBorder>
                                Video Error
                            </Notification>
                        }
                    </>
                }
            </Box>
        );
    }
)
export default withTranslation()(VideoScene);
