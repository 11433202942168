import {Box, Button, Image, ScrollArea} from "@mantine/core";
import React, {useRef, useState} from "react";
import {Document, Page, pdfjs} from 'react-pdf';
import {observer} from "mobx-react-lite";
import "./css/pdf.css";
import close from "../../../assets/images/close.png";
import minimize from "../../../assets/images/minimize.png";
import maximize from "../../../assets/images/maximize.png";
import zoomInIcon from "../../../assets/images/zoom-in.png";
import zoomOutIcon from "../../../assets/images/zoom-out.png";
import rightArrow from "../../../assets/images/right_arrow.png"
import leftArrow from "../../../assets/images/left_arrow.png"
import {debounce} from "../utils/Common";
import CustomOverlay from "../view/CustomOverlay";
import sceneStyles from "../view/Scenes.module.css";
import modalStyles from "./ModalHotspots.module.css";
import {HotspotBehaviourPdf} from "../../../models/shared/HotspotBehaviour";
import scrollbarStyles from "./TextScrollbarMobile.module.css";
import cx from 'clsx';

const initialOptions = {
    cMapUrl: `${process.env.PUBLIC_URL}/pdfjs/cmaps/`,
    standardFontDataUrl: `${process.env.PUBLIC_URL}/pdfjs/standard_fonts/`,
};

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdfjs/pdf.worker.min.js`;

const ModalHotspotsPdfMobile = observer(({data, closeHandler, fullscreenHandler}: IProps) => {
    const [pdfLoading, setPdfLoading] = useState<boolean>(true);
    const [numPagesInit, setNumPages] = useState(1);
    const [pageScale, setPageScale] = useState(1);
    const [isMaximize, setIsMaximize] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const minScalePdfDocument = 0.2;
    const maxScalePdfDocument = 3;
    const stepScalePdfDocument = 0.2;
    const onLoadPdfProgress = useRef(false)
    let countZoomIteration = Number((pageScale / stepScalePdfDocument).toFixed(2))
    const maxZoomIteration = maxScalePdfDocument / stepScalePdfDocument
    const processZoomIn = debounce(() => handleZoomIn());
    const processZoomOut = debounce(() => handleZoomOut());
    const processPrevPage = debounce(() => previousPage());
    const processNextPage = debounce(() => nextPage());

    function onDocumentLoadSuccess({numPages}) {
        onLoadPdfProgress.current = true

        if (pdfLoading) {
            setCurrentPage(1)
            setPdfLoading(false);
            setNumPages(numPages);
        }
    }

    function handleZoomIn() {
        if (!onLoadPdfProgress.current) return

        if (pageScale < maxScalePdfDocument) {
            setPageScale(Number((countZoomIteration * stepScalePdfDocument).toFixed(2)));
        }
    }

    function handleZoomOut() {
        if (!onLoadPdfProgress.current) return

        if (pageScale > minScalePdfDocument) {
            setPageScale(Number((countZoomIteration * stepScalePdfDocument).toFixed(2)));
        }
    }

    function changePage(offset: number) {
        if (!onLoadPdfProgress.current) return

        setCurrentPage(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <Box className={modalStyles.pdfBody}>
            <CustomOverlay visible={pdfLoading}></CustomOverlay>

            <Box className={modalStyles.mobileHotspotPdfControls}>
                <span>
                    {currentPage}/{numPagesInit || '--'}
                </span>

                <Button onClick={() => {
                            if (countZoomIteration < 1 || countZoomIteration === 1) {
                                countZoomIteration = 1
                            } else {
                                countZoomIteration--
                            }

                            processZoomOut()
                        }}
                        disabled={pageScale <= minScalePdfDocument}
                        className={sceneStyles.roundButton}>
                    <Image src={zoomOutIcon}/>
                </Button>

                <span className={modalStyles.percentValue}>
                    {Math.round(pageScale * 100)}%
                </span>

                <Button onClick={() => {
                            if (countZoomIteration > maxZoomIteration || countZoomIteration === maxZoomIteration) {
                                countZoomIteration = maxZoomIteration
                            } else {
                                countZoomIteration++
                            }

                            processZoomIn()
                        }}
                        disabled={pageScale >= maxScalePdfDocument}
                        className={sceneStyles.roundButton}>
                    <Image src={zoomInIcon}/>
                </Button>

                <Button className={sceneStyles.roundButton}
                        onClick={() => {
                            fullscreenHandler(isMaximize);
                            setIsMaximize(!isMaximize);
                        }}>
                    {isMaximize ? <Image src={minimize}/> : <Image src={maximize}/>}
                </Button>

                <Button onClick={() => {
                            closeHandler()
                        }}
                        className={sceneStyles.roundButton}>
                    <Image src={close}/>
                </Button>
            </Box>

            <ScrollArea classNames={scrollbarStyles}>
                <Box className={modalStyles.pdfWrapper}>
                    {data.fileLink &&
                        <Box>
                            <Document loading={''}
                                      className={modalStyles.pdfMobileDocument}
                                      file={data.fileLink}
                                      options={initialOptions}
                                      onLoadSuccess={onDocumentLoadSuccess}>
                                <Page loading={''}
                                      className={modalStyles.pdfOnePage}
                                      renderAnnotationLayer={false}
                                      renderTextLayer={false}
                                      renderMode={'canvas'}
                                      scale={pageScale}
                                      pageNumber={currentPage}
                                />
                            </Document>

                            <Box className={modalStyles.navigationButtonContainer}>
                                <Button className={cx(sceneStyles.roundButton, modalStyles.pdfNavigationButton)}
                                        disabled={currentPage <= 1}
                                        onClick={processPrevPage}>
                                    <Image src={leftArrow}/>
                                </Button>

                                <Button className={cx(sceneStyles.roundButton, modalStyles.pdfNavigationButton)}
                                        disabled={currentPage >= numPagesInit}
                                        onClick={processNextPage}>
                                    <Image src={rightArrow}/>
                                </Button>
                            </Box>
                        </Box>
                    }
                </Box>
            </ScrollArea>
        </Box>
    );
})

interface IProps {
    data: HotspotBehaviourPdf;
    closeHandler: CallableFunction;
    fullscreenHandler: CallableFunction;
}

export default ModalHotspotsPdfMobile
