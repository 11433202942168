import {useContext, useState} from 'react';
import {flushToDom} from "../scripts/flushToDOM";
import {globalContext} from "../../../App";
import {playMediaSafely} from "../utils/AudioVideoUtils";

export function useVideoHandlers(
    togglePlay,
    updateRangeValue,
    initVideoElem,
    initCustomAudioElem
) {
    const [loading, setLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(true)
    const [bufferSize, setBufferSize] = useState<number>(0)
    const [isWaiting, setIsWaiting] = useState<boolean>(false);
    const [isSeeking, setIsSeeking] = useState<boolean>(false);
    const global = useContext(globalContext);
    const stateStore = global.stateStore;
    const sceneStore = global.sceneStore;
    const logger: boolean = false

    function toggleLoading(val: boolean) {
        setLoading(val)
    }

    function loadEmptyArea() {
        initVideoElem(null);
        initCustomAudioElem(null);
        toggleLoading(false)
        setHasError(true);
        toggleShowError(true);
    }

    function toggleShowError(val) {
        setShowError(val);
    }

    function seekingVideo(e: boolean = true) {
        if (!sceneStore.currentVideoElement) return;

        setIsSeeking(e);
    }

    function loadVideoListeners() {
        if (!sceneStore.currentVideoElement) return

        sceneStore.currentVideoElement.onloadstart = function (evt) {
            if (logger) {
                console.groupCollapsed('onloadstart');
                console.log('Событие loadstart происходит, когда обозреватель начинает поиск указанного аудио/видео. Это происходит, когда начинается процесс загрузки.');
                console.warn("Starting to load video", evt);
                console.groupEnd()
            }

            sceneStore.setLoadedScene(true);
            togglePlay();
            updateRangeValue(stateStore.state.videoLastTime);
            sceneStore.currentVideoElement.currentTime = stateStore.state.videoLastTime as number;
            sceneStore.currentAudioElement && (sceneStore.currentAudioElement.currentTime = stateStore.state.videoLastTime as number)
            setBufferSize(0);
        };

        sceneStore.currentVideoElement.ondurationchange = function (event) {
            if (logger) {
                console.groupCollapsed('ondurationchange');
                console.log('Событие durationchange происходит при изменении длительности данных указанного аудио/видео.');
                console.warn("The video duration has changed", event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onloadedmetadata = (event) => {
            if (logger) {
                console.groupCollapsed('onloadedmetadata');
                console.log('Оповещение при загрузке мета-данных для видео');
                console.warn('The duration of the media are now known.');
                console.log('duration: ', sceneStore.currentVideoElement.duration);
                console.groupEnd()
            }

            sceneStore.setVideoDuration(sceneStore.currentVideoElement.duration)
        };

        sceneStore.currentVideoElement.onloadeddata = (event) => {
            if (logger) {
                console.groupCollapsed('loadeddata');
                console.log('Событие loadeddata происходит при загрузке данных для текущего кадра, но недостаточно данных для воспроизведения следующего кадра указанного аудио/видео.');
                console.warn('Browser has loaded the current frame.', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onprogress = function (event) {
            if (logger) {
                console.groupCollapsed('onprogress');
                console.log('Событие progress происходит, когда браузер загружает указанное аудио/видео.');
            }

            const duration = sceneStore.currentVideoElement.duration;
            if (duration > 0) {
                if (sceneStore.currentVideoElement.buffered.length > 0) {
                    logger && console.log(sceneStore.currentVideoElement.buffered.length);

                    for (let i = 1; i <= sceneStore.currentVideoElement.buffered.length; i++) {
                        logger && console.error('Downloading video.', 'buffered end ' + (100 * (sceneStore.currentVideoElement.buffered.end(i - 1)) / duration) + "%");
                        logger && console.error('Downloading video.', 'buffered start ' + (100 * (sceneStore.currentVideoElement.buffered.start(i - 1)) / duration) + "%");

                        const bufValue = (100 * (sceneStore.currentVideoElement.buffered.end(i - 1)) / duration);
                        setBufferSize(parseInt(bufValue.toFixed(2)));
                    }
                }
            }

            logger && console.groupEnd()
        };

        sceneStore.currentVideoElement.oncanplay = function (event) {
            if (logger) {
                console.groupCollapsed('oncanplay');
                console.log('Событие canplay происходит, когда обозреватель может начать воспроизведение указанного аудио/видео (когда он достаточно буферизован, чтобы начать).');
                console.warn('Можно начать воспроизведение видео.', event);
                console.groupEnd()
            }

            toggleLoading(false)
            setIsWaiting(false)

            isSeeking && seekingVideo(false)
        };

        sceneStore.currentVideoElement.oncanplaythrough = function (event) {
            if (logger) {
                console.groupCollapsed('oncanplaythrough');
                console.log('Событие canplaythrough происходит, когда обозреватель оценивает его можно воспроизвести через указанное аудио/видео без остановки для буферизации.');
                console.warn('Can play through video without stopping.', event);
                console.groupEnd()
                console.groupEnd()
            }

            flushToDom()
        };

        sceneStore.currentVideoElement.onplay = function (event) {
            if (logger) {
                console.groupCollapsed('onplay');
                console.warn('The onplay event occurs when an audio/video is started.', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onplaying = function (event) {
            if (logger) {
                console.groupCollapsed('onplaying');
                console.warn('The onplaying event occurs when an audio/video starts playing. The onplaying event also occurs when an audio/video resumes after beeing paused or buffered.', event);
                console.groupEnd()
            }

            if (!sceneStore.currentVideoElement.paused && sceneStore.currentAudioElement?.src) {
                playMediaSafely(sceneStore.currentAudioElement)
            }
        };

        sceneStore.currentVideoElement.ontimeupdate = function (event) {
            if (logger) {
                console.groupCollapsed('ontimeupdate');
                console.warn('The ontimeupdate event occurs when the play time of a media changes. The ontimeupdate event occurs while the media is playing. The ontimeupdate event occurs when the user moves the play position', event);
            }

            stateStore.updateAndSave({videoLastTime: sceneStore.currentVideoElement.currentTime}, 'video handler update lastTime')
            const videoDuration = sceneStore.currentVideoElement.duration;
            const timeVideo = videoDuration / (videoDuration / sceneStore.currentVideoElement.currentTime);
            const newRangeValue = Math.round((timeVideo + Number.EPSILON) * 100) / 100;
            updateRangeValue(newRangeValue);

            logger && console.groupEnd()
        };

        sceneStore.currentVideoElement.onended = function (event) {
            togglePlay();

            if (logger) {
                console.groupCollapsed('onended');
                console.warn('The onended event occurs when an audio/video has reached the end. The onended event is useful for messages like "thanks for listening"', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onabort = function (event) {
            if (logger) {
                console.groupCollapsed('----------onabort');
                console.warn('Call a function when the loading of a video is aborted:', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onemptied = function (event) {
            if (logger) {
                console.groupCollapsed('----------onemptied');
                console.warn('The onemptied event occurs when the an audio or video is empty:', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onerror = function (event) {
            if (logger) {
                console.groupCollapsed('----------onerror');
                console.warn('The onerror event occurs when an error occurs during the loading of a media.', event);
                console.groupEnd()
            }

            loadEmptyArea();
        };

        sceneStore.currentVideoElement.onpause = function (event) {
            if (logger) {
                console.groupCollapsed('----------onpause');
                console.warn('The onpause event occurs when an audio/video is paused. The onpause event also occurs when an audio/video has reached the end.:', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onratechange = function (event) {
            if (logger) {
                console.groupCollapsed('----------onratechange');
                console.warn('The onratechange event occurs when the playing speed of a media is changed (like when a user switches to a slow motion or fast forward mode).', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onseeked = function (event) {
            if (logger) {
                console.groupCollapsed('----------onseeked');
                console.warn('The onseeked event occurs when a user has seeked a new position in a media.', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onseeking = function (event) {
            if (logger) {
                console.groupCollapsed('----------onseeking');
                console.warn('The onseeking event occurs when a user starts seeking a new position in a media.', event);
                console.groupEnd()
            }

            setIsWaiting(true)
            seekingVideo()
        };

        sceneStore.currentVideoElement.onstalled = function (event) {
            if (logger) {
                console.groupCollapsed('----------onstalled');
                console.warn('The onstalled event occurs when the browser is trying to get media data that is not available.', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onsuspend = function (event) {
            if (logger) {
                console.groupCollapsed('----------onsuspend');
                console.warn('onsuspend событие происходит, когда браузер не получает мультимедийные данные.\n' +
                    'Событие onsuspend происходит, когда загрузка предотвращается или приостанавливается.:', event);
                console.groupEnd()
            }

            setIsWaiting(false)
        };

        sceneStore.currentVideoElement.onvolumechange = function (event) {
            if (logger) {
                console.groupCollapsed('----------onvolumechange');
                console.warn('The onvolumechange event occurs when a media volume is changed.', event);
                console.groupEnd()
            }
        };

        sceneStore.currentVideoElement.onwaiting = function (event) {
            if (logger) {
                console.groupCollapsed('----------onwaiting');
                console.warn('The onwaiting event occurs when a media must wait for the next frame.', event);
                console.groupEnd()
            }

            sceneStore.currentAudioElement && sceneStore.currentAudioElement.pause()
            setIsWaiting(true)
            seekingVideo()
        };
    }

    return {
        loadVideoListeners,
        loading,
        hasError,
        isWaiting,
        showError,
        bufferSize
    };
}
