export function playMediaSafely(playingItem) {
    const playPromise = playingItem.play();

    if (playPromise !== undefined) {
        playPromise.then(_ => {})
            .catch((error) => {
                console.error(error)
            });
    }
}

export function pauseSceneSound(audioElem, videoElem) {
    let isVideoPlaying = false;
    let isAudioPlaying = false;

    if (audioElem?.src) {
        isAudioPlaying = !audioElem.paused
        isAudioPlaying && audioElem.pause()
    }
    if (videoElem?.src) {
        isVideoPlaying = !videoElem.paused
        isVideoPlaying && videoElem.pause()
    }

    return {isAudioPlaying, isVideoPlaying};
}

export function playSceneSound(soundsData, audioElem, videoElem) {
    if (audioElem?.src && soundsData.isAudioPlaying) {
        playMediaSafely(audioElem)
    }

    if (videoElem?.src && soundsData.isVideoPlaying) {
        playMediaSafely(videoElem)
    }
}
