import {Box, Image} from "@mantine/core";
import classes from "../modals/ModalHotspots.module.css";
import logo360 from "../../../assets/images/logo360.png";

export const Logo360 = () => {
    return (
        <Box>
            <Image src={logo360}
                   w={30}
                   h={21}
                   fit={"initial"}
                   className={classes.logo360}/>
        </Box>
    );
}
