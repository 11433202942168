import {THREE} from "aframe";
import {IVec3} from "../../../models/shared/IProjectBase";
import {validateColor, validateOpacity} from "../utils/HotspotTools";
import {LineGeometry} from "three/examples/jsm/lines/LineGeometry";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial";
import {Line2} from "three/examples/jsm/lines/Line2";


AFRAME.registerComponent('virtik-hotspot-polygon', {
    schema: {
        points: {type: 'string', default: ''},
        border: {type: 'boolean', default: false},
        id: {type: 'string', default: ''},
        borderOpacity: {type: 'number', default: 1},
        borderThickness: {type: 'number', default: 1},
        borderColor: {type: 'string', default: ''},
        backgroundColor: {type: 'string', default: ''},
        backgroundOpacity: {type: 'number', default: 1},
    },
    init: function() {
        const data = this.data;
        const elAEntity = this.el;
        const entityPolygon: typeof AFRAME.AEntity = document.createElement('a-entity') as typeof AFRAME.AEntity

        let polygonCoords = JSON.parse(data.points) as IVec3[]; // get polygon coordinate
        const borderPolygonArr: THREE.Vector3[] = [];
        if (polygonCoords.length < 3) {
            console.error('polygon cords very small. length:', polygonCoords.length)
            return;
        }

        if (polygonCoords.length > 100) {
            polygonCoords = polygonCoords.splice(0, 100)
        }

        polygonCoords.forEach(point => {
            borderPolygonArr.push(new THREE.Vector3(point.x, point.y, point.z))
        })
        //For closing shape first point should be the same as last
        borderPolygonArr.push(new THREE.Vector3(polygonCoords[0].x, polygonCoords[0].y, polygonCoords[0].z))

        this.drawPolygon(polygonCoords, entityPolygon);

        if (data.border) {
            this.drawBorder(borderPolygonArr, entityPolygon);
        }

        elAEntity.appendChild(entityPolygon)
    },
    update: function () {},
    drawPolygon(polygonCoords: IVec3[], entityPolygon) {
        const data = this.data;
        const points: THREE.Vector3[] = [];
        polygonCoords.forEach(r => {
            points.push(new THREE.Vector3(r.x, r.y, r.z));
        });

        const shape = new THREE.Shape(points as any);
        const shapeGeom = new THREE.ShapeGeometry(shape);
        const shapeMaterial = new THREE.MeshBasicMaterial({
            color: validateColor(data.backgroundColor, "blue"),
            side: THREE.DoubleSide,
            wireframe: false
        })
        shapeMaterial.transparent = true;
        shapeMaterial.opacity = validateOpacity(data.backgroundOpacity);
        const mesh: any = new THREE.Mesh(shapeGeom,shapeMaterial );

        points.forEach((point, index) => {
            mesh.geometry.attributes.position.setXYZ(index,point.x, point.y, point.z)
        })
        mesh.geometry.attributes.position.needsUpdate = true;

        entityPolygon.setObject3D('mesh', mesh);

        entityPolygon.classList.add("hoverable")
    },
    drawBorder(borderPolygonArr: THREE.Vector3[], entityPolygon){
        const data = this.data;
        const widthK = 0.1;
        const borderColor = new THREE.Color(data.borderColor ?? 'white')
        const borderOpacity = validateOpacity(data.borderOpacity);
        const lineMaterial = new LineMaterial( {
            color: borderColor.getHex(),
            linewidth: data.borderThickness * widthK, // in pixels
            opacity: borderOpacity,
            alphaToCoverage: false,
            worldUnits: true,
        })
        const positions: number[] = []
        console.log(borderPolygonArr);
        borderPolygonArr.forEach(v => {
            const k = 1.1;// push border a bit back to not interfere with polygon mesh
            positions.push(v.x*k, v.y*k, v.z*k);
        })
        const lineGeo = new LineGeometry();
        lineGeo.setPositions( positions );
        const line2 = new Line2( lineGeo, lineMaterial );
        entityPolygon.object3D.add(line2);
    }
});

AFRAME.registerPrimitive( 'a-virtik-hotspot-polygon', {
    defaultComponents: {
        'virtik-item': { type: 'hotspot-polygon' },
        'virtik-hotspot-polygon': { },
    },
    mappings: {
        'points': 'virtik-hotspot-polygon.points',
        'border': 'virtik-hotspot-polygon.border',
        'id': 'virtik-hotspot-polygon.id',
        'border-opacity': 'virtik-hotspot-polygon.borderOpacity',
        'border-thickness': 'virtik-hotspot-polygon.borderThickness',
        'border-color': 'virtik-hotspot-polygon.borderColor',
        'background-color': 'virtik-hotspot-polygon.backgroundColor',
        'background-opacity': 'virtik-hotspot-polygon.backgroundOpacity',
    }
});


