import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {withTranslation} from "react-i18next";
import {modals} from "@mantine/modals";
import {globalContext} from "../../../App";
import ContentMinimap2DPlan from "./view/ContentMinimap2DPlan";
import Content2DPlan from "./view/Content2DPlan";
import {HotspotBehaviourLocation, HotspotBehaviourType} from "../../../models/shared/HotspotBehaviour";
import {ViewMode} from "../../../models/shared/SceneType";
import {I2DPlanScene} from "../../../models/shared/Plan2D";

const ModalPlan2D = observer(({t, plan2DData, clickHandler, minimap = false}: IProps) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const plan2DStore = global.plan2DStore;
    const key = Math.floor(Date.now() / 1000)

    function changeScene(hotspotIndex: number) {
        const tour = tourStore.projectData;
        const clickedHotspot = getHotspots()[hotspotIndex];
        const targetSceneName = (clickedHotspot.hotspotData.behaviourData as HotspotBehaviourLocation).transitionToScene;
        const targetScene = tour?.tour.tourNodes.find(t => t.node.nodeName === targetSceneName);

        if (!targetScene) {
            return console.error('Cannot find scene to transfer, given name:', targetSceneName);
        }

        if (targetSceneName === sceneStore.currentScene?.nodeName) {
            return false;
        }

        clickHandler( HotspotBehaviourType.location, {}, {
            sceneNodeName: targetSceneName
        });

        modals.closeAll();
        tourStore.setSwitcherMode(ViewMode.scene360);
        tourStore.setIfHideUiForPopup(false);
    }

    function getHotspots() {
        return tourStore.plan[plan2DStore.current2DPlanElement ?? 0].hotspots
    }

    return (
        <>
            {minimap &&
                <ContentMinimap2DPlan plan2DData={plan2DData}
                                      getHotspots={getHotspots}
                                      changeSceneHandler={changeScene}
                                      key={key}/>
            }

            {!minimap &&
                <Content2DPlan plan2DData={plan2DData}
                               getHotspots={getHotspots}
                               changeSceneHandler={changeScene}
                               key={key}/>
            }
        </>
    );
})

export default withTranslation()(ModalPlan2D)

interface IProps {
    t: any;
    plan2DData: I2DPlanScene[];
    clickHandler: Function;
    minimap: boolean;
}
