import React from "react";
import {createBrowserRouter} from "react-router-dom";
import TourNotFound from "../pages/TourNotFound";
import UploaderFile from "../pages/UploaderFile";
import StartScene from "../pages/StartScene";
import {firebaseConfig} from "../cfg/FirebaseCfg";
import ToursList from "../pages/ToursList";
import {doFetch} from "../utils/request";
import ApiEnvironmentSwitcher from "../utils/ApiEnvironmentSwitcher";

export default function getRouter(overlay: boolean, setOverlay: CallableFunction) {
    return createBrowserRouter([
        {
            path: "/",
            element: <TourNotFound/>,
            loader: () => {
                overlay && setOverlay(false);
                return true;
            },
            errorElement: <TourNotFound/>,
        },
        {
            path: "/toursList",
            element: <ToursList/>,
            loader: () => {
                overlay && setOverlay(false);
                return true;
            },
        },
        {
            path: "/:link/*",
            element: <StartScene/>,
            errorElement: <TourNotFound/>,
            loader: async ({params}) => {
                // const domain = 'http://127.0.0.1:5001/virtik-virtual-tour-software/us-central1';

                try {
                    const domain = ApiEnvironmentSwitcher.getApiPath();
                    const res = await doFetch(`${domain}/ifProjectAvailable?referenceCode=${params.link}`)
                    setOverlay(false);

                    if (!res || !res?.status || res.status !== 200) {
                        throw new Response("Not Found", {status: 404});
                    }

                    return res
                } catch (e) {
                    setOverlay(false);
                    throw new Response("Not Found", {status: 404});
                }
            },
        },
        {
            path: "/upload_file/",
            element: <UploaderFile/>,
        },
    ]);
}
