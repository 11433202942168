import {IVec3} from "./IProjectBase";

export enum HotspotVisualType {
  "text" = "text",
  "icon" = "icon", // available for 2d plan
  "polygon" = "polygon", // available for 2d plan
}

export type IHotspotVisualData = IHotspotVisualDataIcon | IHotspotVisualDataPolygon | IHotspotVisualDataText;

export interface IHotspotVisualDataIcon {
    iconPath: string;
    /** Direct link to file; generated by back-end during processing tour .zip archive */
    iconLink?: string;
    size?: number,
    color?: string, // not implemented yet; use #FFFFFF color by default
}

export interface IHotspotVisualDataPolygon {
    points: IVec3[];
    border: boolean;
    id: string;
    borderOpacity: number;
    borderThickness: number;
    borderColor: string;
    background: boolean; // TODO is it necessary yet?
    backgroundColor: string;
    backgroundOpacity: number;
}

export interface IHotspotVisualDataText {
    text: string,
    fontSize: number,
    font: string,
    textColor: string,
    textOpacity: string,
    background: boolean,
    backgroundColor: string,
    backgroundColorOpacity: number,
    border: boolean,
    borderColor: string,
    borderColorOpacity: number,
    borderThickness: number,
}
