import {globalContext} from "../../../App";
import React, {useContext, useEffect, useState} from "react";
import cx from 'clsx';
import {Box} from "@mantine/core";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./switcher.css";
import classes from "./Plan2DSwitcher.module.css";
import currentLocation from "../../../assets/images/current_location.svg";
import {getSelectedPlanIndex} from "../utils/Plan2D";
import {slider2DSettings} from "./Slider2DSettings";

const Plan2DSwitcher = observer(({t}: any) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const plan2DStore = global.plan2DStore;
    const plan2d = tourStore.plan;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [attachedPlanIndex, setAttachedPlanIndex] = useState(
        getSelectedPlanIndex(plan2d, sceneStore)
    );

    useEffect(() => {
        selectedPlan(plan2DStore.current2DPlanElement);
    }, [plan2DStore.current2DPlanElement]);

    function selectedPlan(index) {
        setSelectedIndex(index);
        plan2DStore.setCurrent2DPlanElement(index);
        setAttachedPlanIndex(getSelectedPlanIndex(plan2d, sceneStore))
    }

    return (
        <Box className={classes.navPlanContent}>
            <Box className={classes.navPlanContentChild}>
                <Slider {...slider2DSettings}>
                    {
                        (tourStore.plan && tourStore.plan.length > 0) &&
                        tourStore.plan.map((elem, index) =>
                            <div key={index} id={'plan_' + index} onClick={() => selectedPlan(index)}
                                 style={{width: 115}}
                                 className={cx(classes.navSlide, {[classes.selectedPlan]: index === selectedIndex})}>
                                    <span>
                                        <img className={cx(classes.currentLocation,
                                            {[classes.attachedPlanIcon]: index === attachedPlanIndex})}
                                             src={currentLocation}/>
                                    </span>
                                <span>{elem.sceneName}</span>
                            </div>
                        )
                    }
                </Slider>
            </Box>
        </Box>
    );
})

export default withTranslation()(Plan2DSwitcher)
