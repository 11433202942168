import React from "react";
import {Box} from "@mantine/core";
import hotspotImage from "../../../../assets/images/dot-hotspot-2dplan.png";
import currentLocationHotspotImage from "../../../../assets/images/current-location-hotspot-2dplan.png";
import {
    HotspotVisualType,
    IHotspotVisualDataIcon,
    IHotspotVisualDataPolygon
} from "../../../../models/shared/IHotspotVisual";
import modalPlanStyles from "../ModalPlan2D.module.css";
import {IHotspotData2dPlan} from "../../../../models/shared/Plan2D";
import {HotspotBehaviourLocation} from "../../../../models/shared/HotspotBehaviour";

const editorHalfWidth = 16.5;
const editorHalfHeight = 10;
const defaultIconSize = 38;
// TODO vary depending on minimap size?
const minimapIconMult = 0.25;
const defaultIconColor = '#3F77E1';
const defaultPolygonColor = '#005EEB';

export function AddHotspot({
                               hsd,
                               index,
                               planImage,
                               currentSceneName,
                               changeSceneHandler,
                               isMinimap
                           }: IProps) {
    switch (hsd.visualType) {
        case HotspotVisualType.polygon:
            if (hsd.visualData) {
                const visualData = (hsd.visualData as IHotspotVisualDataPolygon);
                if (!visualData?.points) return <></>;

                const points = visualData.points.map(p => convertPositionEditorToViewer(p.x, p.y, planImage));
                let positionPoints = "";

                points.forEach((point) => {
                    positionPoints += ((point.x) * 100) + '% ';
                    positionPoints += ((point.y) * 100) + '%,';
                });
                positionPoints = positionPoints.slice(0, -1);


                let borderPositionPoints = "";

                [...points, points[0]].forEach((point) => {
                    borderPositionPoints += point.x * planImage.width + ' ';
                    borderPositionPoints += point.y * planImage.height + ',';
                });
                borderPositionPoints = borderPositionPoints.slice(0, -1);



                return <>
                <Box style={{width: '100%', height: '100%'}}
                            className={modalPlanStyles.polygonNew}
                            top={0}
                            left={0}>

                    <div onClick={() => changeSceneHandler(index)}
                         style={{
                             clipPath: 'polygon(' + positionPoints + ')',
                             backgroundColor: visualData?.backgroundColor ?? defaultPolygonColor,
                             opacity: visualData?.backgroundOpacity ?? 1,
                         }}
                         className={modalPlanStyles.shape}>
                        <svg height={'100%'} width={'100%'} style={{top: 0, left: 0}} className={modalPlanStyles.polygonNew}>
                            <polyline points={borderPositionPoints} style={{ fill: 'none', stroke: 'red', strokeWidth: 50 }} />
                        </svg>
                    </div>
                </Box>
                    </>;
            }

            return <></>;
        case HotspotVisualType.icon:
            const pos = convertPositionEditorToViewer(hsd.position.x, hsd.position.y, planImage);

            const positionX = pos.x * 100;
            const positionY = pos.y * 100;
            const vd = hsd.visualData as IHotspotVisualDataIcon;
            let size = vd?.size ?? defaultIconSize;
            isMinimap && (size *= minimapIconMult);

            const bd = hsd.behaviourData as HotspotBehaviourLocation;
            const src = currentSceneName === bd.transitionToScene
                ? hsd?.hotspotCurrentState.iconLink ?? currentLocationHotspotImage
                : (hsd.visualData as IHotspotVisualDataIcon)?.iconLink ?? hotspotImage;
            let color = currentSceneName === bd.transitionToScene
                ? hsd?.hotspotCurrentState.color
                : vd?.color ?? defaultIconColor;

            return <Box id={'hotspotId_' + index}
                        style={{top: positionY + '%', left: positionX + '%', zIndex: 1}} /* on top of the whole size polygons */
                        onClick={() => changeSceneHandler(index)}
                        className={modalPlanStyles.hotspotPosition}>

                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                     viewBox={"0 0 " + size + " " + size} style={{display: "none"}}>
                    <mask
                        id={"mymask" + index}> {/* ids don't duplicate because 2dplan and minimap don't exist at the same time */}
                        <image xlinkHref={src} x="0" y="0" height={size + "px"} width={size + "px"}/>
                    </mask>
                </svg>

                <span className={modalPlanStyles.hotspotImagePosition}
                      style={{
                          width: size + 'px',
                          height: size + 'px',
                          backgroundColor: color,
                          mask: 'url(#mymask' + index + ')',
                          WebkitMaskBoxImage: 'url(' + src + ')',
                      }}>
                </span>
            </Box>;
        default:
            console.error('Unknown 2D plan hotspot VisualType!')
            return <></>
    }
}

interface IProps {
    hsd: IHotspotData2dPlan,
    index: number,
    planImage: HTMLImageElement,
    currentSceneName: string,
    changeSceneHandler: CallableFunction,
    isMinimap: boolean,
}

/** @param pivotRelatedPointX is value relative to pivot point at the center; min is -16.5, max is 16.5
 * @param pivotRelatedPointY is value relative to pivot point at the center; min is -10, max is 10
 * @param image plan image HtmlImageElement
 * @return numbers [0,1]
 * Editor uses fixed ratio 33*20 scene no matter which picture is.
 * This function fits picture by bigger side and converts center-related coordinates to top-left related
 */
function convertPositionEditorToViewer(pivotRelatedPointX, pivotRelatedPointY, image) {
    const imageRatio = image.width / image.height;
    const canonicalRatio = 16.5 / 10;
    const ratioMult = imageRatio / canonicalRatio;
    let x, y;
    if (canonicalRatio > imageRatio) {
        x = pivotRelatedPointX / editorHalfWidth / ratioMult / 2 + 0.5;
        y = -pivotRelatedPointY / editorHalfHeight / 2 + 0.5;
    } else {
        x = pivotRelatedPointX / editorHalfWidth / 2 + 0.5;
        y = -pivotRelatedPointY / editorHalfHeight / ratioMult / 2 + 0.5;
    }

    return {x, y};
}
