export enum HotspotBehaviourType {
    "location" = "location", // the only available for 2D PLAN: switch mode to 360, select given 360 scene
    "audio" = "audio",
    "picture" = "picture",
    "picture360" = "picture360",
    "video" = "video",
    "video360" = "video360",
    "text" = "text",
    "album" = "album",
    "pdf" = "pdf",
    "plan2D" = "plan2D",
    "link" = "link",
    "none" = "none",
}

export type HotspotBehaviourData = object | null
    | HotspotBehaviourLocation // the only available for 2D PLAN
    | HotspotBehaviourAudio
    | HotspotBehaviourPicture
    | HotspotBehaviourAlbum
    | HotspotBehaviourVideo
    | HotspotBehaviourPdf
    | HotspotBehaviourLink
    | HotspotBehaviourText;

export interface HotspotBehaviourLocation {
    /** Corresponds to scene nodeName **/
    transitionToScene: string,
}

export interface HotspotAudioData extends HotspotBehaviourAudio {
    name: string,
}

export interface HotspotBehaviourAudio {
    filePath: string,
    /** Direct link to file; generated by back-end during processing tour .zip archive */
    fileLink?: string,
}

export interface HotspotBehaviourAlbum {
    slides: HotspotAlbumSlide[];
}

export interface HotspotBehaviourPicture {
    filePath: string,
    /** Direct link to file; generated by back-end during processing tour .zip archive */
    fileLink?: string,
}

export interface HotspotBehaviourVideo {
    filePath: string,
    /** Direct link to file; generated by back-end during processing tour .zip archive */
    fileLink?: string,
}

export interface HotspotBehaviourPdf {
    filePath: string,
    /** Direct link to file; generated by back-end during processing tour .zip archive */
    fileLink?: string,
}

export interface HotspotAlbumSlide {
    filePath: string,
    fileLink?: string,
    order?: number,
    thumbnailFilePath: string,
    thumbnailFileLink?: string,
}

export interface HotspotBehaviourText {
    backgroundColor: string,
    borderColor: string,
    text: string
}

export interface HotspotBehaviourLink {
    target: "_blank" | "_self",
    uri: string,
}
