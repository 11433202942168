import {GlobalStore} from "./GlobalStore";
import {makeAutoObservable} from "mobx";
import {doFetch} from "../utils/request";
import ApiEnvironmentSwitcher from "../utils/ApiEnvironmentSwitcher";

export class FirebaseApiStore {
    global: GlobalStore;

    constructor(global: GlobalStore) {
        this.global = global;
        makeAutoObservable(this);
    }

    async getAllTours() {
        try {
            const domain = ApiEnvironmentSwitcher.getApiPath();
            const cloudFunctionUrl = `${domain}/getAllTours`;
            // const cloudFunctionUrl = 'http://127.0.0.1:5001/virtik-virtual-tour-software/us-central1/getAllTours';
            const response = await doFetch(cloudFunctionUrl);

            if (response.ok) {
                const result = await response.json();
                return await result.success;
            } else {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            throw error;
        }
    }

    async getTourByReferenceCode(referenceCode) {
        try {
            const domain = ApiEnvironmentSwitcher.getApiPath();
            const cloudFunctionUrl = `${domain}/getTourByReferenceCode?referenceCode=${referenceCode}`;
            // const cloudFunctionUrl = 'http://127.0.0.1:5001/virtik-virtual-tour-software/us-central1/getTourByReferenceCode?referenceCode=' + referenceCode;
            const response = await doFetch(cloudFunctionUrl);

            if (response.ok) {
                const result = await response.json();
                console.info('Tour data', result.success);
                return result.success;
            } else {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }
        } catch (error) {
            throw error;
        }
    }
}
