AFRAME.registerComponent('image-scene', {
    schema: {
        skyPicture: {type: 'selector'},
        imageTour: {type: 'selector'},
        imgLink: {type: "string"},
    },
    init: function () {
        // this.data.skyPicture.addEventListener('materialtextureloaded', () => {
        //     console.log(44444);
        //
        //     return this.el.emit('image-rendered')
        // });

        const element = this.data.imageTour;
        element.setAttribute('src', this.data.imgLink); // workaround to avoid aframe blue loading screen

        element.onload = () => {
            this.data.skyPicture.setAttribute('src', "#imageTour");
            this.el.emit('image-loaded');
        };
    },
})

export {}
