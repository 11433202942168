import {I2DPlanScene} from "../../../models/shared/Plan2D";

/* Get the lowest order plan index from attached to current scene; otherwise get just the lowest order one*/
export function getSelectedPlanIndex(plan2DData: I2DPlanScene[], sceneStore): number {
    const plansAttachedToCurrentScene = plan2DData
        .filter((plan: I2DPlanScene) => plan?.linkedScenes &&
            Array.isArray(plan.linkedScenes) &&
            !!sceneStore.currentScene?.nodeName &&
            plan.linkedScenes.includes(sceneStore.currentScene!.nodeName)
        );
    switch (plansAttachedToCurrentScene.length) {
        case 0:
            return plan2DData.findIndex(p => p.order === Math.min(...plan2DData.map(plan => plan.order)));
        case 1:
            return plan2DData.findIndex(p => p.nodeName === plansAttachedToCurrentScene[0].nodeName);
        default:
            const lowestOrder = Math.min(...plansAttachedToCurrentScene.map(plan => plan.order));
            const selectedPlan = plansAttachedToCurrentScene.find(plan => plan.order === lowestOrder);
            return plan2DData.findIndex(p => p.nodeName === selectedPlan!.nodeName);
    }
}
