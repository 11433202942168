AFRAME.registerComponent('virtik-text-container', {
    schema: {},
    init: function () {
        this.el.setAttribute('geometry', `primitive: plane; height: 0.4; width: 1;`);
        this.el.setAttribute('material', `opacity: 0;`);
    },
});

AFRAME.registerPrimitive('a-virtik-text-container', {
    defaultComponents: {
        'virtik-item': {type: 'text-container'},
        'virtik-text-container': {}
    },
    mappings: {
        'width': 'virtik-item.width',
        'height': 'virtik-item.height',
        'margin': 'virtik-item.margin',
    }
});

export {}
