import React, {useContext, useEffect, useRef, useState} from 'react';
import ImageScene from "./ImageScene";
import VideoScene from "./VideoScene";
import MapScene from "./MapScene";
import MeasurementScene from "./MeasurementScene";
import {globalContext} from "../../App";
import classes from "./Viewer360.module.css";
import BottomSceneSwitcher from "./view/BottomSceneSwitcher";
import SidebarMenu from "./SidebarMenu";
import {SceneType, ViewMode} from "../../models/shared/SceneType";
import {IMenuSettings} from "../../models/shared/IMenuSettings";
import {INodeData} from "../../models/shared/IProjectBase";
import {HotspotBehaviourData, HotspotBehaviourLink, HotspotBehaviourType} from "../../models/shared/HotspotBehaviour";
import {observer} from "mobx-react-lite";
import {modals} from "@mantine/modals";
import ModalHotspots from "./modals/ModalHotspots"
import {defaultCameraRotation, mobileDeviceMaxWidth} from "../../vars";
import {Box, Button, Image, Text} from "@mantine/core";
import maximize from "../../assets/images/maximize.png";
import {withTranslation} from "react-i18next";
import {useWindowDimensions} from "./hooks/useWindowDimensions";
import {toJS} from "mobx";
import ModalPlan2D from "./plan2d/ModalPlan2D";
import {I2DPlanScene} from "../../models/shared/Plan2D";
import CustomOverlay from "./view/CustomOverlay";
import {pauseSceneSound, playMediaSafely, playSceneSound} from "./utils/AudioVideoUtils";
import {getSelectedPlanIndex} from "./utils/Plan2D";
import PlanMode from "./PlanMode";

const Viewer360 = observer(({t, toggle}: { t: any; toggle: Function }) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const stateStore = global.stateStore;
    const sceneStore = global.sceneStore;
    const plan2DStore = global.plan2DStore;
    const [scene, setScene] = useState<any>();
    const [planElementTest, setPlanElementTest] = useState<any>();
    const [minimap, setMinimap] = useState<any>();
    const sidebarData = useRef<IMenuSettings | null>(tourStore.projectData?.skin?.infoMenu ?? null);
    const sceneWrapperRef = useRef(null);
    const modalOptions = {
        closeOnClickOutside: false,
        size: 'auto',
        radius: 0,
        centered: true,
        withCloseButton: false,
        padding: 0,
        overlayProps: {
            opacity: 0,
        },
        zIndex: 9998,
    };
    const {orientation, width, height, isMobile} = useWindowDimensions()
    const showLoadingFor360Scene = tourStore.showLoadingFor360Scene;
    const plan2d: I2DPlanScene[] = tourStore.plan;

    useEffect(() => {
        init();
    }, [])

    function init() {
        switch (stateStore.state.mode) {
            case ViewMode.map:
            case ViewMode.measurement:
                stateStore.updateAndSave({mode: tourStore.generalSettings.initialMode}, 'viewer init set mode')
                // loadContent(tourStore.generalSettings.initialMode)
                break;
            default:
                selectScene(getInitNodeName(), true);

                if (tourStore.generalSettings.initialMode === ViewMode.plan) {
                    tourStore.setSwitcherMode(ViewMode.plan);
                    tourStore.setIfHideUiForPopup(true);
                    plan2DStore.setCurrent2DPlanElement(null);
                    hotspotBehaviour(HotspotBehaviourType.plan2D);
                }
                break
        }
    }

    function getInitNodeName(): string {
        // high priority
        if ((stateStore.state?.sceneNodeName ?? "") !== "") {
            if (getTourByNodeName(stateStore.state?.sceneNodeName ?? '')) {
                return stateStore.state.sceneNodeName;
            }
        }

        // middle priority
        if (tourStore.tourData?.entryNodeName) {
            const sceneByName = getTourByNodeName(tourStore.tourData?.entryNodeName)

            if (sceneByName) {
                return sceneByName.nodeName;
            }
        }

        // low priority
        return Object.values(toJS(tourStore.tourNodes))[0].nodeName
    }

    function getTourByNodeName(nodeName: string = ''): INodeData | null {
        if (nodeName === '') return null;

        return tourStore.tourNodes.find(item => item.nodeName === nodeName) ?? null
    }

    function prepareSceneData(node: INodeData): INodeData {
        const tempNode = {...node}

        if (tempNode.nodeDataPath?.includes('\\')) {
            tempNode.nodeDataPath = tempNode.nodeDataPath.replace(/\\/g, '/');
        }

        return tempNode;
    }

    /** @param type standardized behaviour type
     * @param data standardized behaviour data
     * @param extra use for custom data not included into interfaces */
    function hotspotBehaviour(type: HotspotBehaviourType, data: HotspotBehaviourData = {}, extra: {[k: string]: any} = {}) {
        sceneStore.setHotspotVolumeOn(stateStore.state.mute)

        const parent: HTMLDivElement = sceneWrapperRef.current!;
        const viewportOffset = parent.getBoundingClientRect()
        const hotspotModalOptions = {
            ...modalOptions,
            withinPortal: false,
            xOffset: viewportOffset.left,
            yOffset: viewportOffset.top,
            overlayProps: {mx: viewportOffset.left, my: viewportOffset.top, backgroundOpacity: 0}
        }

        switch (type) {
            case HotspotBehaviourType.location:
                if (!extra?.sceneNodeName) {
                    return console.error('Missing params for scene transition! Given data:', data);
                }

                selectScene(extra.sceneNodeName);
                break;
            case HotspotBehaviourType.audio:
                const assetsBody = document.getElementById('sceneAssets') as HTMLElement;
                if (!assetsBody) return

                const audioItem = document.getElementById(extra.hotspotName) as HTMLAudioElement;

                if (audioItem?.src) {
                    const soundsAudioData = pauseSceneSound(sceneStore.currentAudioElement, sceneStore.currentVideoElement)

                    audioItem.currentTime = 0
                    playMediaSafely(audioItem)

                    audioItem.onended = function (evt) {
                        console.log('Hotspot audio is ended');
                        playSceneSound(soundsAudioData, sceneStore.currentAudioElement, sceneStore.currentVideoElement)
                    }
                }

                break;
            case HotspotBehaviourType.picture:
                modals.open({
                    ...hotspotModalOptions,
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data} extra={extra}/>
                    ),
                });
                break;
            case HotspotBehaviourType.video:
                const soundsVideoData = pauseSceneSound(sceneStore.currentAudioElement, sceneStore.currentVideoElement)

                modals.open({
                    ...hotspotModalOptions,
                    onClose: () => {
                        playSceneSound(soundsVideoData, sceneStore.currentAudioElement, sceneStore.currentVideoElement)
                    },
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data}/>
                    ),
                });
                break;
            case HotspotBehaviourType.video360:
                const soundsVideo360Data = pauseSceneSound(sceneStore.currentAudioElement, sceneStore.currentVideoElement)

                modals.open({
                    ...hotspotModalOptions,
                    onClose: () => {
                        playSceneSound(soundsVideo360Data, sceneStore.currentAudioElement, sceneStore.currentVideoElement)
                    },
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data}/>
                    ),
                });
                break;
            case HotspotBehaviourType.album:
                modals.open({
                    ...hotspotModalOptions,
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data}/>
                    ),
                });
                break;
            case HotspotBehaviourType.picture360:
                modals.open({
                    ...hotspotModalOptions,
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data}/>
                    )
                });
                break;
            case HotspotBehaviourType.pdf:
            case HotspotBehaviourType.text:
                modals.open({
                    ...hotspotModalOptions,
                    withCloseButton: !isMobile(),
                    children: (
                        <ModalHotspots type={type} hotspotBehaviourData={data} />
                    ),
                });
                break;
            case HotspotBehaviourType.plan2D:
                modals.open({
                    ...hotspotModalOptions,
                    zIndex: 102,
                    children: (
                        <ModalPlan2D plan2DData={plan2d} clickHandler={hotspotBehaviour} minimap={false}/>
                    ),
                });
                break;
            case HotspotBehaviourType.link:
                const linkData = data as HotspotBehaviourLink;
                if (linkData.target === '_blank' || linkData.target === '_self') {
                    window.open(linkData.uri, linkData.target);
                } else {
                    console.error('Unknown target link type!')
                }
                break;
            default:
                console.error('Unknown scene type!')
        }
    }

    function showMinimap() {
        const key = Math.floor(Date.now() / 1000);
        const selectedMinimapIndex = getSelectedPlanIndex(plan2d, sceneStore);

        //Reset for minimap
        plan2DStore.setCurrent2DPlanElement(null);
        sceneStore.setLoadedScene(false);

        if (selectedMinimapIndex !== -1) {
            if(tourStore.mode360TourSettings.show2dPlanMinimap){
                setMinimap(
                    <ModalPlan2D plan2DData={plan2d}
                                 clickHandler={hotspotBehaviour}
                                 minimap={true}
                                 key={key}/>
                );
            }
        }
    }

    const loadContent = (mode: ViewMode, node: INodeData = {} as any, init: boolean = false) => {
        const sceneData = prepareSceneData(node)
        const key = Math.floor(Date.now() / 1000)

        setPlanElementTest(null)
        showMinimap();

        switch (mode) {
            case ViewMode.scene360:
                const sceneType = node.type === 1 ? SceneType.image : SceneType.video;
                tourStore.setSwitcherMode(ViewMode.scene360);
                sceneStore.setCurrentSceneType(sceneType);
                sceneStore.setCurrentSceneZoomData(sceneData.cameraData, init)
                sceneStore.setCurrentScene(node);

                if (!init) {
                    if (sceneData.nodeName !== stateStore.state.sceneNodeName) {
                        stateStore.updateAndSave({rotation: sceneData.cameraData?.initialRotation || defaultCameraRotation}, 'viewer set initial rotation')
                    }
                    // TODO -> feature -> reset scene store
                }

                if(sceneType === SceneType.image) {
                    setScene(<ImageScene sceneData={sceneData}
                                         clickHandler={hotspotBehaviour}
                                         toggle={toggle}
                                         key={key}/>)
                } else {
                    setScene(<VideoScene sceneData={sceneData}
                                         clickHandler={hotspotBehaviour}
                                         toggle={toggle}
                                         key={key}/>)
                }
                break;
            case ViewMode.map:
                tourStore.setSwitcherMode(ViewMode.map);
                setScene(<MapScene toggle={toggle}
                                   key={key}/>)
                break;
            case ViewMode.measurement:
                tourStore.setSwitcherMode(ViewMode.measurement);
                setScene(<MeasurementScene toggle={toggle}
                                           key={key}/>)
                break;
            case ViewMode.plan:
                tourStore.setIfHideUiForPopup(true)
                setPlanElementTest(<PlanMode plan2DData={plan2d}
                                             clickHandler={hotspotBehaviour}
                                             key={key}/>)
                break;
            default:
                console.error('Unknown scene type!')
        }
    }

    function selectScene(nodeName: string = '', init: boolean = false/*, isMenuHs: boolean = false, menuHsData = null*/) {
        const selectedScene: INodeData | null = getTourByNodeName(nodeName);

        if (!selectedScene) return;

        let showMinimapValue;

        console.log(stateStore.checkByKey('zoom'))
        console.log(stateStore.checkByKey('rotation'))

        // TODO zoom and rotation check before set or another way
        const updated = {
            sceneName: nodeName,
            mode: ViewMode.scene360,
            // zoom: selectedScene.cameraData?.initialZoom,
            // rotation: selectedScene.cameraData?.initialRotation,
        };

        if (!init && selectedScene.nodeName !== stateStore.state.sceneNodeName) {
            const selectedPlanIndex = getSelectedPlanIndex(plan2d, sceneStore);

            if (selectedPlanIndex !== -1) {
                showMinimapValue = tourStore.mode360TourSettings.show2dPlanMinimap;
            }
        }

        showMinimapValue && (updated['showMinimap'] = showMinimapValue)
        stateStore.updateAndSave(updated, 'viewer update store')
        loadContent(ViewMode.scene360, selectedScene, init)
    }

    function twoDPlan() {
        if (tourStore.switcherMode === 'plan') {
            return true;
        }

        return !tourStore.ifHideUiForPopup;
    }

    return (
        <Box className={classes.viewerContainer}>
            <Box id="virtikViewerContent" className={classes.contentArea}>
                <Box id="virtikContentWrapper" className={classes.sceneArea} ref={sceneWrapperRef}>
                    {tourStore.switcherMode !== ViewMode.plan && sceneStore.loadedScene &&
                        <>
                            {minimap}
                        </>
                    }

                    {
                        (
                            !stateStore.state.fullscreen &&
                            (
                                (orientation.includes('portrait') && width < mobileDeviceMaxWidth)
                                ||
                                (orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth)
                            )
                        ) &&
                        <Box className={classes.modalWindowModeNotifyBlock}>
                            <Box p={4} className={classes.modalWindowModeNotifyImage}>
                                <Image w={24} h={24} src={maximize}></Image>
                            </Box>

                            <Text className={classes.modalWindowModeNotifyText}>
                                {t('Sorry, software does not support window mode on mobile devices. ' +
                                    'Please, go to the full screen for better experience')}
                            </Text>

                            <Button onClick={() => {
                                toggle()
                            }}
                                    className={classes.modalWindowModeNotifyButton}>
                                {t('Full screen mode')}
                            </Button>
                        </Box>
                    }

                    {sidebarData.current &&
                        <SidebarMenu sidebarData={sidebarData.current}
                                     selectScene={selectScene}
                                     clickHandler={hotspotBehaviour}
                                     switcherCallback={loadContent}
                                     isVisibleUi={stateStore.state.visibleUi ?? false}
                                     classes={classes}/>
                    }

                    {scene}

                    {planElementTest}

                    {tourStore.generalSettings.showModeSwitcher && stateStore.state.visibleUi && twoDPlan() &&
                        <Box className={classes.bottomSceneSwitcher}>
                            <BottomSceneSwitcher switcherCallback={loadContent}/>
                        </Box>
                    }

                    <CustomOverlay visible={showLoadingFor360Scene}></CustomOverlay>
                </Box>
            </Box>
        </Box>
    )
});

export default withTranslation()(Viewer360);
