import {Box, Button, Center, Image, ScrollArea, Title} from "@mantine/core";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {withTranslation} from "react-i18next";
import useEmblaCarousel, {EmblaOptionsType} from 'embla-carousel-react'
import cx from 'clsx';
import {Thumb} from './views/EmblaCarouselThumbsButton'
import './css/embla.css';
import {globalContext} from "../../../App";
import {mobileDeviceMaxWidth} from "../../../vars";
import leftArrow from "../../../assets/images/left_arrow.png";
import rightArrow from "../../../assets/images/right_arrow.png";
import CustomOverlay from "../view/CustomOverlay";
import sceneStyles from "../view/Scenes.module.css";
import scrollbarStyles from "./AlbumScrollbar.module.css"
import {useWindowDimensions} from "../hooks/useWindowDimensions";

const ModalHotspotsAlbum = observer(({t, data}: IProps) => {
    const [imageLoading, setImageLoading] = useState<boolean>(true);
    const [error, setError] = useState(false);
    const SLIDE_COUNT = data.slides.length
    const slides = Array.from(Array(SLIDE_COUNT).keys())
    const options: EmblaOptionsType = {}
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options)
    const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({})
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)
    const [isLandscapeOnMobile, setIsLandscapeOnMobile] = useState<boolean>(false)
    const global = useContext(globalContext);
    const sceneStore = global.sceneStore;
    const {orientation, width, height} = useWindowDimensions();
    // const viewport = useRef<HTMLDivElement>(null);

    const onThumbClick = useCallback(
        (index: number) => {
            if (!emblaMainApi || !emblaThumbsApi) return
            window.dispatchEvent(new Event('resize'));
            emblaMainApi.scrollTo(index)
        },
        [emblaMainApi, emblaThumbsApi]
    )

    const onSelect = useCallback(() => {
        if (!emblaMainApi || !emblaThumbsApi) return

        setSelectedIndex(emblaMainApi.selectedScrollSnap())
        setPrevBtnDisabled(!emblaMainApi.canScrollPrev())
        setNextBtnDisabled(!emblaMainApi.canScrollNext())
        emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap())

        const slide = document.querySelector('#album_slide_' + emblaMainApi.selectedScrollSnap());

        if (slide) {
            setImageLoading(true);
            slide.setAttribute("src", data.slides[emblaMainApi.selectedScrollSnap()].fileLink);

            slide.onload = function () {
                setImageLoading(false);
            };
            slide.onerror = function () {
                setImageLoading(false);
                setError(true);
            };
        }
    }, [emblaMainApi, emblaThumbsApi, setSelectedIndex])

    const scrollPrev = useCallback(() => {
        window.dispatchEvent(new Event('resize'));
        if (emblaMainApi) emblaMainApi.scrollPrev()
        // TODO fix scrollTo selected index
        // @ts-ignore
        // viewport.current.scrollTo({ left: 0, behavior: "smooth" });
    }, [emblaMainApi])

    const scrollNext = useCallback(() => {
        window.dispatchEvent(new Event('resize'));
        if (emblaMainApi) emblaMainApi.scrollNext()
        // TODO fix scrollTo selected index
        // @ts-ignore
        // viewport.current.scrollTo({ left: viewport.current.scrollWidth, behavior: 'smooth' });
        // window.dispatchEvent(new Event('resize'));
    }, [emblaMainApi])

    useEffect(() => {
        if (!emblaMainApi) return

        const thumb: HTMLImageElement = document.querySelector('.embla-thumbs__slide__img:first-child') as unknown as HTMLImageElement;
        const thumbSelected = document.querySelector('.embla-thumbs__slide__button:first-child');
        thumbSelected.classList.add('embla__not__loaded__thumb');

        thumb.onload = function () {
            thumbSelected.classList.remove('embla__not__loaded__thumb');
        }

        if (thumb.complete) {
            thumbSelected.classList.remove('embla__not__loaded__thumb');
        }

        onSelect()
        emblaMainApi.on('select', onSelect)
        emblaMainApi.on('reInit', onSelect)

        if (orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth) {
            setIsLandscapeOnMobile(true)
        }

        window.dispatchEvent(new Event('resize'));
    }, [emblaMainApi, onSelect])

    function showScrollArea(selector: string = '', scrollSelector: string = ''): boolean {
        const parent: HTMLCollectionOf<Element> = document.getElementsByClassName(selector)
        const scrollChild: HTMLCollectionOf<Element> = document.getElementsByClassName(scrollSelector)
        if (parent.length === 0 || scrollChild.length === 0) return false;

        const parentRect = parent[0].getBoundingClientRect();
        const scrollChildRect = scrollChild[0].getBoundingClientRect();
        if (!parentRect || !scrollChildRect) return false;

        return parentRect.width < scrollChildRect.width;
    }

    return (<>
            {error
                /* TODO a single broken prevents all images to be shown, is it good UX? */
                ? <Center><Title mt={200} c={"red"} order={3}>Error while loading pictures</Title></Center>
                : <Box className="embla">
                    <Box className={
                        'embla__viewport ' + (isLandscapeOnMobile ? 'embla__viewport-landscapeOnMobile' : '')
                        + (sceneStore.isMaximizeHotspotMode ? ' embla__viewport__fullscreen' : '')}
                         ref={emblaMainRef}>
                        <Box className="embla__container">
                            {slides.map((index) => (
                                <Box className="embla__slide" key={index}>
                                    <CustomOverlay visible={imageLoading}></CustomOverlay>

                                    {/*TODO can use mantine Image ?*/}
                                    <img id={'album_slide_' + index}
                                         className="embla__slide__img"
                                         src=""
                                    />
                                </Box>
                            ))}
                        </Box>

                        <Button id="buttonPrev"
                                className={cx(
                                    sceneStyles.roundButton,
                                    sceneStyles.albumButton,
                                    sceneStyles.leftArrow,
                                    !sceneStore.isMaximizeHotspotMode ? sceneStyles.albumButtonPositionMinimizeMode : ''
                                )}
                                onClick={scrollPrev}
                                disabled={prevBtnDisabled}>
                            <Image src={leftArrow}/>
                        </Button>

                        <Button id="buttonNext"
                                className={cx(
                                    sceneStyles.roundButton,
                                    sceneStyles.albumButton,
                                    sceneStyles.rightArrow,
                                    !sceneStore.isMaximizeHotspotMode ? sceneStyles.albumButtonPositionMinimizeMode : ''
                                )}
                                onClick={scrollNext}
                                disabled={nextBtnDisabled}>
                            <Image src={rightArrow}/>
                        </Button>
                    </Box>

                    <Box className={
                        'embla-thumbs ' + (isLandscapeOnMobile ? 'embla-thumbs-landscapeOnMobile' : '')
                        + (sceneStore.isMaximizeHotspotMode ? ' embla-thumbs-hide' : '')
                    }>
                        <Box className="embla-thumbs__viewport" ref={emblaThumbsRef}>
                            <ScrollArea offsetScrollbars
                                        className={sceneStyles.scrollbarCustomStyle}
                                        classNames={scrollbarStyles}
                                        type={
                                            showScrollArea('embla-thumbs__viewport', 'embla-thumbs__container')
                                                ? "always"
                                                : 'auto'
                                        }>
                                <Box className="embla-thumbs__container">
                                    {slides.map((index) => (
                                        <Thumb onClick={() => onThumbClick(index)}
                                               selected={index === selectedIndex}
                                               imgSrc={data.slides[index].thumbnailFileLink}
                                               key={index}
                                        />
                                    ))}
                                </Box>
                            </ScrollArea>
                        </Box>
                    </Box>
                </Box>
            }
        </>
    );
})

export default withTranslation()(ModalHotspotsAlbum)

interface IProps {
    t: any,
    data: any,
}
