import {makeAutoObservable} from "mobx";
import {
  AuthErrorCodes,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  UserCredential,
  signOut
} from "firebase/auth";
import {GlobalStore} from "./GlobalStore";

interface loginInterface {
  login: string,
  password: string,
}

interface formResponse {
  status: boolean,
  error?: string,
}

export class UserStore {
  global: GlobalStore;
  isAuthed = false;
  userId = null;
  user: UserCredential | null = null;

  constructor(globalStore: GlobalStore) {
    this.global = globalStore;
    const auth = this.global.firebaseStore.getAuth();
    // signOut(auth).then(() => {
    //   // Sign-out successful.
    // }).catch((error) => {
    //   // An error happened.
    // });
    onAuthStateChanged(auth, (user) => {
      this.isAuthed = !!auth.currentUser;
      // @ts-ignore
      this.userId = auth.currentUser?.uid;
    });
    makeAutoObservable(this);
  }

  async login(params: loginInterface): Promise<formResponse> {
    const auth = this.global.firebaseStore.getAuth();
    const loginEmail = params.login;
    const loginPassword = params.password;

    try {
      const user = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      this.isAuthed = true;
      this.user = user;
      return {
        status: true,
      };
    } catch(error: any) {
      let errorMessage: string = '';
      console.log(`There was an error: ${error}`)

      if (error.code == AuthErrorCodes.INVALID_PASSWORD) {
        errorMessage = `Wrong password. Try again.`;
      } else if (error.code == AuthErrorCodes.INVALID_EMAIL) {
        errorMessage = 'Wrong email. Try again.';
      } else {
        errorMessage = `Error: ${error.message}`;
      }

      return {
        status: false,
        error: errorMessage
      };
    }
  }

  async signUp(params: loginInterface): Promise<formResponse> {
    const auth = this.global.firebaseStore.getAuth();
    const email = params.login;
    const password = params.password;

    try {
      const user = await createUserWithEmailAndPassword(auth, email, password)
      this.isAuthed = true;
      this.user = user;

      return {
        status: true,
      };
    } catch(error) {
      let errorMessage: string = `There was an error: ${error}`;
      console.log(errorMessage)

      return {
        status: false,
        error: errorMessage
      };
    }
  }

}
