import {Button, Image} from "@mantine/core";
import {MouseEventHandler} from "react";
import {observer} from "mobx-react-lite";
import zoomInIcon from "../../../assets/images/zoom-in.png";
import zoomOutIcon from "../../../assets/images/zoom-out.png";
import classes from "./Scenes.module.css";

const ZoomControls = observer((props: IProps) => {
    return (
        <>
            <Button className={classes.roundButton}
                    id="zoomIn"
                    disabled={props.disabledZoomIn}
                    onClick={props.toggleZoomIn}>
                <Image src={zoomInIcon}/>
            </Button>

            <Button className={classes.roundButton}
                    id="zoomOut"
                    disabled={props.disabledZoomOut}
                    onClick={props.toggleZoomOut}>
                <Image src={zoomOutIcon}/>
            </Button>
        </>
    );
})

export default ZoomControls;

interface IProps {
    toggleZoomIn: MouseEventHandler;
    toggleZoomOut: MouseEventHandler;
    disabledZoomIn: boolean;
    disabledZoomOut: boolean;
}
