import React, {useContext, useEffect} from "react";
import {observer} from "mobx-react-lite";

import {globalContext} from "../../../App";
import {
    HotspotBehaviourAlbum,
    HotspotBehaviourAudio,
    HotspotBehaviourLocation,
    HotspotBehaviourPdf,
    HotspotBehaviourPicture,
    HotspotBehaviourText,
    HotspotBehaviourType,
    HotspotBehaviourVideo,
    HotspotBehaviourLink
} from "../../../models/shared/HotspotBehaviour";
import {IHotspot} from "../../../models/shared/IProjectBase";
import {
    HotspotVisualType,
    IHotspotVisualDataIcon,
    IHotspotVisualDataPolygon,
    IHotspotVisualDataText
} from "../../../models/shared/IHotspotVisual";
import {radToDeg, transformCoordinate, vec3ToString} from "../utils/HotspotTools";

const HotspotsRender = observer(({hotspots, clickHandler}: IProps) => {
        const global = useContext(globalContext);
        const tourStore = global.tourStore;
        const stateStore = global.stateStore;

        useEffect(() => {
            hotspots.forEach((h, i) => {
                const id = getId(i);
                const el: typeof AFRAME.AEntity = document.getElementById(id) as typeof AFRAME.AEntity

                if (!el) {
                    return console.error('Hotspot not found! Id: ', id);
                }

                el.addEventListener('click', () => onClick(h))
            })
        }, []);

        function onClick(hotspot: IHotspot) {
            const data = hotspot.hotspotData;
            const tour = tourStore.projectData;

            switch (data.behaviourType) {
                case HotspotBehaviourType.location:
                    const targetSceneNodeName = (data.behaviourData as HotspotBehaviourLocation).transitionToScene;
                    const targetScene = tour?.tour.tourNodes.find(t => t.node.nodeName === targetSceneNodeName);

                    if (!targetScene) {
                        return console.error('Cannot find scene to transfer, given node name:', targetSceneNodeName);
                    }

                    clickHandler(data.behaviourType, {}, {
                        sceneNodeName: targetSceneNodeName
                    });
                    break;
                case HotspotBehaviourType.audio:
                    clickHandler(data.behaviourType, data.behaviourData as HotspotBehaviourAudio, {
                        hotspotName: data.name.toLowerCase().replace(/\s/g, ''),
                    });
                    break;
                case HotspotBehaviourType.picture360:
                case HotspotBehaviourType.picture:
                    clickHandler(data.behaviourType, data.behaviourData as HotspotBehaviourPicture);
                    break;
                case HotspotBehaviourType.video:
                case HotspotBehaviourType.video360:
                    clickHandler(data.behaviourType, data.behaviourData as HotspotBehaviourVideo);
                    break;
                case HotspotBehaviourType.text:
                    clickHandler(data.behaviourType, data.behaviourData as HotspotBehaviourText);
                    break;
                case HotspotBehaviourType.pdf:
                    clickHandler(data.behaviourType, data.behaviourData as HotspotBehaviourPdf);
                    break;
                case HotspotBehaviourType.album:
                    clickHandler(data.behaviourType, data.behaviourData as HotspotBehaviourAlbum);
                    break;
                case HotspotBehaviourType.link:
                    clickHandler(data.behaviourType, data.behaviourData as HotspotBehaviourLink);
                    break;
                case HotspotBehaviourType.none:
                    console.log("Nothing happens");
                    break;
                default:
                    console.log('unknown behaviour type');
            }
        }

        function rotationTowardsCamera() {
            const r = stateStore.state.rotation!;
            return vec3ToString({x: radToDeg(r.x), y: radToDeg(r.y), z: radToDeg(r.z)});
        }

        function getId(index: number) {
            return 'hotspot-' + index;
        }

        return (
            <>
                {hotspots.length > 0 &&
                    hotspots.map((el: IHotspot, index) => {
                        const hotspot = el.hotspotData;
                        const idHotspot = getId(index)

                        return (
                            <a-entity key={idHotspot}>
                                {(() => {
                                    switch (hotspot.visualType) {
                                        case HotspotVisualType.polygon: {
                                            const visualData = (hotspot.visualData as IHotspotVisualDataPolygon);
                                            // @ts-ignore
                                            return <a-virtik-flex-container
                                                // position={cords}
                                            >
                                                {/*@ts-ignore*/}
                                                <a-virtik-hotspot-polygon class="hoverable"
                                                    // position={cords}
                                                                          points={JSON.stringify(visualData.points.map(p => transformCoordinate(p)))}
                                                                          border={visualData.border}
                                                                          id={idHotspot}
                                                                          border-opacity={visualData.borderOpacity}
                                                                          border-thickness={visualData.borderThickness}
                                                                          border-color={visualData.borderColor}
                                                                          polygon-background={visualData.background}
                                                                          background-color={visualData.backgroundColor}
                                                                          background-opacity={visualData.backgroundOpacity}/>
                                                {/*@ts-ignore*/}
                                            </a-virtik-flex-container>
                                        }
                                        case HotspotVisualType.icon: {
                                            const visualData = hotspot.visualData as IHotspotVisualDataIcon
                                            const cords = vec3ToString(transformCoordinate(hotspot.position))
                                            // @ts-ignore
                                            return <a-virtik-flex-container id={idHotspot}
                                                                            flex-direction="column"
                                                                            justify-content="center"
                                                                            align-items="normal"
                                                                            opacity="1"
                                                                            width="1"
                                                                            size={visualData?.size}
                                                                            height="1"
                                                                            panel-color="#fff"
                                                                            panel-rounded="0.1"
                                                                            position={cords}
                                                                            rotation={rotationTowardsCamera()}>

                                                {/*@ts-ignore*/}
                                                <a-virtik-hotspot-button class="hoverable"
                                                                         id={idHotspot + '-btn'}
                                                                         height="1"
                                                                         icon-color={visualData?.color ?? "#FFFFFF"}
                                                                         icon-size={visualData?.size}
                                                                         button-position="0 0 0"
                                                                         button-rotation="0 0 0"
                                                                         png-url={visualData.iconLink}
                                                                         background-color={visualData?.color ?? "#FFFFFF"}/>
                                                {/*@ts-ignore*/}
                                            </a-virtik-flex-container>
                                        }
                                        case HotspotVisualType.text: {
                                            const visualData = hotspot.visualData as IHotspotVisualDataText


                                            return <a-virtik-text-container class="hoverable"
                                                                            id={idHotspot}
                                                                            position={vec3ToString(transformCoordinate(hotspot.position))}
                                                                            rotation={rotationTowardsCamera()}
                                            >

                                                <a-virtik-hotspot-text selector={idHotspot}
                                                                       visual-data={JSON.stringify(visualData)}/>

                                            </a-virtik-text-container>
                                        }
                                    }
                                })()}
                            </a-entity>
                        )
                    })
                }

                <a-entity cursor="rayOrigin: mouse" raycaster="objects: .hoverable;"></a-entity>

            </>
        );
    }
)
export default HotspotsRender;

interface IProps {
    hotspots: IHotspot[];
    clickHandler: CallableFunction;
}

