import {Box, Button, Image} from "@mantine/core";
import React, {useContext, useState} from "react";
import {observer} from "mobx-react-lite";
import "./css/pdf.css";
import close from "../../../assets/images/close.png";
import minimize from "../../../assets/images/minimize.png";
import maximize from "../../../assets/images/maximize.png";
import zoomInIcon from "../../../assets/images/zoom-in.png";
import zoomOutIcon from "../../../assets/images/zoom-out.png";
import {globalContext} from "../../../App";
import {debounce} from "../utils/Common";
import sceneStyles from "../view/Scenes.module.css";
import modalStyles from "./ModalHotspots.module.css";

const ModalHotspotsPdfDesktopHeader = observer(({closeHandler, fullscreenHandler, numPagesInit}: IProps) => {
    const global = useContext(globalContext);
    const hotspotPdfStore = global.hotspotPdfStore;
    const [isMaximize, setIsMaximize] = useState<boolean>(false);
    const minScalePdfDocument = 0.2;
    const maxScalePdfDocument = 3;
    const stepScalePdfDocument = 0.2;
    let countZoomIteration = hotspotPdfStore.scale / stepScalePdfDocument
    const maxZoomIteration = maxScalePdfDocument / stepScalePdfDocument
    const processZoomIn = debounce(() => handleZoomIn());
    const processZoomOut = debounce(() => handleZoomOut());

    function handleZoomIn() {
        if (hotspotPdfStore.scale < maxScalePdfDocument) {
            hotspotPdfStore.setLoading()
            hotspotPdfStore.setScale(Number((countZoomIteration * stepScalePdfDocument).toFixed(2)))
        }
    }

    function handleZoomOut() {
        if (hotspotPdfStore.scale > minScalePdfDocument) {
            hotspotPdfStore.setLoading()
            hotspotPdfStore.setScale(Number((countZoomIteration * stepScalePdfDocument).toFixed(2)))
        }
    }

    return (
        <Box className={modalStyles.hotspotPdfControls}>
            <Button onClick={() => {
                        closeHandler()
                    }}
                    className={sceneStyles.roundButton}>
                <Image src={close}/>
            </Button>

            <Button className={sceneStyles.roundButton}
                    onClick={() => {
                        fullscreenHandler(isMaximize);
                        setIsMaximize(!isMaximize);
                    }}>
                {isMaximize ? <Image src={minimize}/> : <Image src={maximize}/>}
            </Button>

            <Button onClick={() => {
                        if (countZoomIteration > maxZoomIteration || countZoomIteration === maxZoomIteration) {
                            countZoomIteration = maxZoomIteration
                        } else {
                            countZoomIteration++
                        }

                        processZoomIn()
                    }}
                    disabled={hotspotPdfStore.scale >= maxScalePdfDocument}
                    className={sceneStyles.roundButton}>
                <Image src={zoomInIcon}/>
            </Button>

            <span className={modalStyles.percentValue}>
                {Math.round(hotspotPdfStore.scale * 100)}%
            </span>

            <Button onClick={() => {
                        if (countZoomIteration < 1 || countZoomIteration === 1) {
                            countZoomIteration = 1
                        } else {
                            countZoomIteration--
                        }

                        processZoomOut()
                    }}
                    disabled={hotspotPdfStore.scale <= minScalePdfDocument}
                    className={sceneStyles.roundButton}>
                <Image src={zoomOutIcon}/>
            </Button>

            <span>
                {hotspotPdfStore.currentPage}/{numPagesInit || '--'}
            </span>
        </Box>
    );
})

interface IProps {
    closeHandler: CallableFunction;
    fullscreenHandler: CallableFunction;
    numPagesInit: number;
}

export default ModalHotspotsPdfDesktopHeader
