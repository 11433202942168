import {ViewMode} from "./SceneType";

export interface IMenuSettings {
    author?: string;
    bgColor?: string; // hex
    bgImagePath?: string;
    bgImageLink?: string;
    customString?: string;
    description?: string;
    displayName?: string;
    eventDate?: number; // format is customizable by user: 21.11.2023 , 21.11 , 21 November, 21 Nov , November 21, 2023, Npvember 2023, Now 23 etc
    logoPath?: string;
    logoLink?: string;
    options?: IMenuOptions[];
    textColor?: string; //hex
    textFont?: string;
    textSize?: number;
    type: MenuType;
}

/** Nesting level must be limited to 2: grandparent->parent->child */
export interface IMenuOptions {
    options?: IMenuOptions[];
    id: string,
    title: string;
    order?: number;
    behaviourType?: MenuBehaviourType,
    behaviourData?: IMenuBehaviourDataLocation | IMenuBehaviourDataHsFocus | IMenuBehaviourDataHsActivate | IMenuBehaviourDataMode | IMenuBehaviourDataText | IMenuBehaviourDataLink;
}


export enum MenuType {
    "left" = "left",
    "right" = "right",
    "top" = "top",
    "bottom" = "bottom",
}

export enum MenuBehaviourType {
    "location" = "location",
    "focusHotspot" = "focusHotspot",
    "activateHotspot" = "activateHotspot",
    "switchMode" = "switchMode",
    "textModal" = "textModal",
    "link" = "link",
}

export interface IMenuBehaviourDataLocation {
    transitionToScene: string;
}

export interface IMenuBehaviourDataHsFocus {
    hotspotName: string; // камера на хотспот
    sceneNode: string;
    zoomLevel: number;
}

export interface IMenuBehaviourDataHsActivate {
    hotspotName: string;  // виполнить действие хотспота и камера на хотспот
    sceneNode: string;
}

export interface IMenuBehaviourDataMode {
    targetMode: ViewMode;
}

export interface IMenuBehaviourDataText {
    text: string;
}

export interface IMenuBehaviourDataLink {
    target: "_blank" | "_self", // mailto also work
    uri: string,
}
