import 'aframe';
import React, {useContext} from 'react';
import {Box} from "@mantine/core";
import cx from "clsx";
import classes from "./view/Scenes.module.css";
import UiControls from "./view/UiControls";
import {globalContext} from "../../App";

function MeasurementScene({toggle}) {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;

    return (
        <Box style={{
            flex: '1 1 auto',
            padding: '0.5em',
            height: '100%',
            backgroundColor: '#f5f5f5',
            color: '#000000',
        }}>
            Measurement page coming soon!

            <Box className={cx(classes.contentUiVisible, classes[tourStore.menuType + 'UiVisible'])}>
                <UiControls  toggle={toggle}/>
            </Box>
        </Box>
    );
}

export default MeasurementScene;
