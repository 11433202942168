import {FirebaseStore} from "./FirebaseStore";
import {UserStore} from "./UserStore";
import {FirebaseApiStore} from "./FirebaseApiStore";
import {TourStore} from "./TourStore";
import {Plan2DStore} from "./Plan2DStore";
import {SceneStore} from "./SceneStore";
import {HotspotPdfStore} from "./HotspotPdfStore";
import {StateStore} from "./StateStore";

export class GlobalStore {
    firebaseStore: FirebaseStore;
    userStore: UserStore;
    api: FirebaseApiStore;
    tourStore: TourStore;
    plan2DStore: Plan2DStore;
    sceneStore: SceneStore;
    hotspotPdfStore: HotspotPdfStore;
    stateStore: StateStore;

    constructor() {
        this.firebaseStore = new FirebaseStore(this);
        this.userStore = new UserStore(this);
        this.api = new FirebaseApiStore(this);
        this.tourStore = new TourStore(this);
        this.plan2DStore = new Plan2DStore(this);
        this.sceneStore = new SceneStore(this);
        this.hotspotPdfStore = new HotspotPdfStore(this);
        this.stateStore = new StateStore(this);
    }
}
