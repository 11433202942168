import React from 'react'

type PropType = {
    selected: boolean
    imgSrc: string
    onClick: () => void
}

export const Thumb: React.FC<PropType> = (props) => {
    const {selected, imgSrc, onClick} = props

    return (
        // TODO can use mantine element ?
        <div className={'embla-thumbs__slide' + (selected ? ' embla-thumbs__slide--selected' : '')}>
            <button onClick={onClick}
                    className="embla-thumbs__slide__button"
                    type="button">
                <img className="embla-thumbs__slide__img"
                     src={imgSrc}
                     alt="Your alt text"/>
            </button>
        </div>
    )
}
