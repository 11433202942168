import React from "react";
import cx from 'clsx';
import {Button, Image, Tooltip} from "@mantine/core";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";

import {ViewMode} from "../../../models/shared/SceneType";
import c from "./BottomSceneSwitcher.module.css";

const CustomTooltip = observer(({label, disabled = false, src, callbackFunc, mode, id = '', isActive}: IProps) => {
    return (
        <Tooltip label={label}
                 openDelay={2000}
                 closeDelay={500}
                 transitionProps={{transition: 'slide-up', duration: 300}}
                 withArrow
                 arrowPosition="center"
                 arrowSize={6}>
            <Button onClick={(event) => callbackFunc(mode)}
                    disabled={disabled}
                    id={id}
                    className={cx(c.item, {[c.itemActive]: isActive})}>
                <Image src={src} alt={label}/>
            </Button>
        </Tooltip>
    );
})

export default withTranslation()(CustomTooltip)

interface IProps {
    label: string;
    disabled?: boolean;
    isActive: boolean;
    id?: string;
    src: string;
    callbackFunc: CallableFunction;
    mode: ViewMode
}
