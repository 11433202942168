/**
 * varName start with -- (double hyphen)
 */
export function getCssVariable (varName: string = ''): string | number {
    const htmlElement: HTMLElement = document.getElementsByTagName('html')[0]
    return getComputedStyle(htmlElement).getPropertyValue(varName);
}

export function getEmSize (sizeInPixel: number, pxToEm: number = 16): string {
    return Math.round(sizeInPixel / pxToEm) + 'em'
}
