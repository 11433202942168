import {ViewMode} from "./SceneType";
import {MiniMapState} from "./Plan2D";

export interface ITheme {
    colorScheme: {
        uiColorScheme: UiColorScheme
    },
    tourDefaultSettings: {
        general: IGeneralSettings;
        mode360tour: IMode360Settings;
        mode2dPlan: IMode2dPlanSettings;
    }
}

export interface IGeneralSettings {
    initialMode: ViewMode;
    isControlsCollapsed: boolean;
    isMute: boolean;
    modeSwitcherShowButtons: ThemeSwitcherModes;
    screenMode: ScreenModeType;
    showInfoMenuForModes: ThemeSwitcherModes;
    showControlsMenuButton: boolean;
    showModeSwitcher: boolean;
    /** Show open/close menu button */
    showInfoMenuButton?: boolean;
    /** Is opened on tour load */
    showInfoMenu?: boolean;
}

export interface IMode360Settings {
    hideIdleVideoControls: boolean;
    minimapBackground: boolean;
    minimapBorder: boolean;
    minimapState: MiniMapState;
    show2dPlanMinimap: boolean;
    showVideoControls: boolean; // TODO не сделано поскольку требует уточнения что и где скривать
}

export interface IMode2dPlanSettings {
    showPlanSwitcherPanel: boolean;
}

export enum ScreenModeType {
    windowed = "windowed",
    maximized = "maximized",
}

export enum UiColorScheme {
    'Black&Blue&White' = "Black&Blue&White",
    'Black&Red&White' = "Black&Red&White",
}

export interface ThemeSwitcherModes {
    scene360: boolean,
    map: boolean,
    measurement: boolean,
    plan: boolean,
}
