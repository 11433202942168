import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Box, Button, Center, Image, Title} from "@mantine/core";
import cx from 'clsx';
import {globalContext} from "../../../App";
import {useWindowDimensions} from "../hooks/useWindowDimensions";
import {useHotspotVideoControls} from "../hooks/useHotspotVideoControls";
import play from "../../../assets/images/play.png";
import CustomOverlay from "../view/CustomOverlay";
import VideoSlider from "../view/VideoSlider";
import sceneStyles from "../view/Scenes.module.css";
import modalStyles from "./ModalHotspots.module.css";

const ModalHotspotsVideo = observer(({data}: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState(false);

    const global = useContext(globalContext);
    const sceneStore = global.sceneStore;
    const {isIos} = useWindowDimensions();

    const {
        initHotspotVideoElem,
        isPlay, togglePlay,
        rangeValue, updateRangeValue,
        videoDuration,
        backwardStep, forwardStep,
        restart,
        setVideoCurrentTime,
        bufferSize
    } = useHotspotVideoControls()

    useEffect(() => {
        const element = document.getElementById('hotspotVideo')! as HTMLVideoElement;

        element.oncanplay = function () {
            setIsLoading(false);
            initHotspotVideoElem(element)
        };
        element.onerror = function () {
            setIsLoading(false);
            setError(true);
        };
    }, []);

    return (<>
            {error
                ? <Center><Title mt={200} c={"red"} order={3}>Error while loading video file</Title></Center>
                : <Box className={modalStyles.modalVideo} id="videoHotspotBehaviour">
                    <CustomOverlay visible={isLoading}></CustomOverlay>

                    <Box className={modalStyles.hotspotVideoPlayer} onClick={() => {
                        togglePlay(null, true)
                    }}>
                        {!isLoading && !isPlay &&
                            <Button className={cx(sceneStyles.roundButton, modalStyles.hotspotPlayButtonForVideoModal)}>
                                <Image src={play}/>
                            </Button>
                        }

                        {isIos()
                            ? <video id="hotspotVideo"
                                     className={modalStyles.videoHotspot}
                                     playsInline={true}
                                     autoPlay={true}
                                     muted={sceneStore.hotspotVolumeOn}
                                     crossOrigin="anonymous"
                                     src={data.fileLink}/>
                            : <video id="hotspotVideo"
                                     className={modalStyles.videoHotspot}
                                     muted={sceneStore.hotspotVolumeOn}
                                     crossOrigin="anonymous"
                                     src={data.fileLink}/>
                        }

                        {!isLoading &&
                            <Box className={cx(sceneStyles.videoRange, sceneStyles.videoRangeHotspot)}>
                                {/*TODO isMinWidth false ?*/}
                                <VideoSlider isPlay={isPlay}
                                             rangeValue={rangeValue}
                                             bufferSize={bufferSize}
                                             updateRangeValue={updateRangeValue}
                                             maxVideoDuration={videoDuration}
                                             backwardStep={backwardStep}
                                             forwardStep={forwardStep}
                                             togglePlay={togglePlay}
                                             setVideoCurrentTime={setVideoCurrentTime}
                                             rotateVideo={restart}
                                             isMinWidth={false}
                                             isHotspotMode={true}
                                />
                            </Box>
                        }
                    </Box>
                </Box>
            }
        </>
    );
})

export default ModalHotspotsVideo
