import {Box} from "@mantine/core";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useHotkeys} from "@mantine/hooks";
import {modals} from "@mantine/modals";

import {globalContext} from "../../App";
import classes from "./plan2d/Plan2D.module.css"
import {AddHotspot} from "./plan2d/view/AddHotspot";
import {draggableContent} from "./utils/Draggable";
import {getSelectedPlanIndex} from "./utils/Plan2D";
import {I2DPlanScene} from "../../models/shared/Plan2D";
import {HotspotBehaviourLocation, HotspotBehaviourType} from "../../models/shared/HotspotBehaviour";
import Plan2DSwitcher from "./plan2d/Plan2DSwitcher";
import cx from "clsx";
import {mobileDeviceMaxWidth} from "../../vars";
import RightControls2DPlan from "./plan2d/RightControls2DPlan";
import {useWindowDimensions} from "./hooks/useWindowDimensions";
import sceneClasses from "./view/Scenes.module.css";
import viewerClasses from './Viewer360.module.css'
import {ViewMode} from "../../models/shared/SceneType";

const PlanMode = observer(({plan2DData, clickHandler}: IProps) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const stateStore = global.stateStore;
    const plan2DStore = global.plan2DStore;
    const planImages: string[] = [];
    const [currentPlanIndex, setCurrentPlanIndex] = useState<number>(0);
    const [renderHotspots, setRenderHotspots] = useState<any>();
    let content: HTMLElement;
    const {orientation, width, height} = useWindowDimensions()
    const menuType = tourStore.menuType

    useEffect(() => {
        //If 2D plan image not changed
        if (currentPlanIndex === plan2DStore.current2DPlanElement) {
            return;
        }

        setClassNames();

        //Set array with source image link
        if (Object.keys(plan2DData).length !== 0) {
            const result: I2DPlanScene[] = Object.values(plan2DData);

            for (const plan of result) {
                if (plan.nodeDataPath != null && plan.nodeDataLink != null) {
                    planImages.push(plan.nodeDataLink);
                }
            }
        }

        //Set current 2D plan element
        let currentPlanImageSrc = "";

        if (plan2DStore.current2DPlanElement !== null) {
            setCurrentPlanIndex(plan2DStore.current2DPlanElement);
            currentPlanImageSrc = planImages[plan2DStore.current2DPlanElement];
        } else {
            const selectedPlanIndex = getSelectedPlanIndex(plan2DData, sceneStore);

            if (selectedPlanIndex === -1) {
                currentPlanImageSrc = planImages[0];
                plan2DStore.setCurrent2DPlanElement(0);
                setCurrentPlanIndex(0);
            } else {
                currentPlanImageSrc = planImages[selectedPlanIndex];
                plan2DStore.setCurrent2DPlanElement(selectedPlanIndex);
                setCurrentPlanIndex(selectedPlanIndex);
            }
        }

        const planImage = (document.getElementById('current2DPlanImage') as HTMLImageElement);
        const planChild = (document.getElementById('current-plan-child') as HTMLDivElement);

        if (planImage) {
            setPlanImage(planImage, currentPlanImageSrc, planChild);
        }

        draggableContent('#current-plan-content');
    }, [plan2DStore.current2DPlanElement]);

    useEffect(() => {
        //If current hotspot position button clicked
        if (plan2DStore.ifCenterPlanViewport) {
            actionMoveToCurrentHotspot();
            return;
        }
    }, [plan2DStore.ifCenterPlanViewport]);

    function changeScene(hotspotIndex: number) {
        const tour = tourStore.projectData;
        const clickedHotspot = getHotspots()[hotspotIndex];
        const targetSceneName = (clickedHotspot.hotspotData.behaviourData as HotspotBehaviourLocation).transitionToScene;
        const targetScene = tour?.tour.tourNodes.find(t => t.node.nodeName === targetSceneName);

        if (!targetScene) {
            return console.error('Cannot find scene to transfer, given name:', targetSceneName);
        }

        if (targetSceneName === sceneStore.currentScene?.nodeName) {
            return false;
        }

        clickHandler( HotspotBehaviourType.location, {}, {
            sceneNodeName: targetSceneName
        });

        modals.closeAll();
        tourStore.setSwitcherMode(ViewMode.scene360);
        tourStore.setIfHideUiForPopup(false);
    }

    function getHotspots() {
        return tourStore.plan[plan2DStore.current2DPlanElement ?? 0].hotspots
    }

    function setClassNames() {
        // const overlay = document.querySelector('.mantine-Overlay-root');
        // const body = document.querySelector('.mantine-Modal-body');
        // const inner = document.querySelector('.mantine-Modal-inner');
        content = document.querySelector('#current-plan-content');
        //
        // overlay && overlay.classList.add(classes.overlayElementOpenModal);
        // inner && inner.classList.add(classes.innerElementOpenModal);
        content && content.classList.add(classes.contentElementOpenModal);
        // body && body.classList.add(classes.bodyElementOpenModal);
        //
        // return content;
    }

    function setPlanImage(planImage: HTMLImageElement, currentPlanImageSrc: string, planChild: HTMLElement) {
        tourStore.setShowLoadingFor360Scene(true);
        planImage.setAttribute("src", currentPlanImageSrc);

        planImage.onload = function (e) {
            console.log(content);
            plan2DStore.doResetZoomRightControls(true);
            // @ts-ignore
            content.style.cssText = 'max-height: 100%';
            // @ts-ignore
            content.style.cssText = 'height: auto';
            planChild.style.cssText = 'height: 100%';

            if ((e.currentTarget as HTMLImageElement).height < document.querySelector('#virtikContentWrapper').offsetHeight) {
                content.style.cssText = 'height: auto';
            } else {
                content.style.cssText = 'height: auto';
                content.style.cssText = 'max-height: 100%';
                planImage.style.cssText = 'max-height: 100%';
            }

            //Render hotspots
            const nodes: ReactNode[] = [];
            const index = plan2DStore.current2DPlanElement ?? 0;
            (tourStore.plan[index]?.hotspots ?? []).forEach((elem, index) => {
                nodes.push(<AddHotspot hsd={elem.hotspotData}
                                       index={index}
                                       key={index}
                                       planImage={planImage}
                                       isMinimap={false}
                                       currentSceneName={sceneStore.currentScene!?.nodeName}
                                       changeSceneHandler={changeScene}/>)
            });

            setRenderHotspots(<>{nodes}</>);
            tourStore.setShowLoadingFor360Scene(false);
        };
    }

    function actionMoveToCurrentHotspot() {
        // if (!getHotspots()) {
        //     return;
        // }
        //
        // const container = document.querySelector('#current-plan-content');// visible plan section
        // const child = document.querySelector('#current-plan-child'); //draggable plan image
        // const planImage = (document.getElementById('current2DPlanImage') as HTMLImageElement);
        // moveToTargetHotspot(getHotspots(), sceneStore.currentScene!.nodeName, container, child, planImage);
        //
        // plan2DStore.setIfCenterPlanViewport(false);
    }

    useHotkeys([
        ['Escape', closeHandler],
    ]);

    function closeHandler() {
        // const scene: any = document.querySelector('#set360Scene');
        // scene.click();
    }

    return (
        <Box id="current-plan-body" className={classes.currentPlanBody}>
            <Box id="current-plan-content" className={classes.currentPlanContent}>
                <Box id="current-plan-child" className={classes.child}>
                    <img id="current2DPlanImage" src="" alt=""/>
                    {renderHotspots}
                </Box>
            </Box>

            {stateStore.state.visibleUi &&
                <Box className={cx(
                    (orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth)
                        ? sceneClasses.sceneControlsTop
                        : sceneClasses.sceneControls,
                    sceneClasses[menuType + 'SceneControls'],
                    sceneClasses.zIndexForButtons)}>
                    <RightControls2DPlan/>
                </Box>
            }

            {stateStore.state.visibleUi && tourStore.isLoaded && tourStore.mode2dPlanSettings.showPlanSwitcherPanel &&
                <Box className={viewerClasses.bottomScene2DPlanSwitcher}>
                    <Plan2DSwitcher/>
                </Box>
            }
        </Box>

    );
})

export default PlanMode

interface IProps {
    plan2DData: I2DPlanScene[];
    clickHandler: Function;
}
