import {ICameraData, INodeData, IZoomData} from "../models/shared/IProjectBase";
import {GlobalStore} from "./GlobalStore";
import {makeAutoObservable} from "mobx";
import {SceneType} from "../models/shared/SceneType";
import {Entity} from "aframe";
import {Camera} from "three";
import {getCameraRotation} from "../components/tour/utils/Camera";
import {compareObject} from "../components/tour/utils/Objects";
import {defaultZoomData} from "../vars";

let timerId;

export class SceneStore {
    global: GlobalStore;

    // scene
    currentScene?: INodeData;
    currentSceneType?: SceneType;
    loadedScene: boolean = false;
    sceneCamera: any = null;
    currentSceneZoomData: IZoomData;

    // video
    currentVideoElement!: HTMLVideoElement;
    videoDuration: number = 0;

    // audio
    currentAudioElement!: HTMLAudioElement;

    // hotspot
    hotspotVolumeOn: boolean = false;
    isMaximizeHotspotMode: boolean = false;

    constructor(globalStore: GlobalStore) {
        this.global = globalStore;
        makeAutoObservable(this);
    }

    setCurrentScene(tour) {
        this.currentScene = tour;
    }

    setCurrentSceneType(type) {
        this.currentSceneType = type;
    }

    setCurrentVideoElement(videoElement: HTMLVideoElement) {
        this.currentVideoElement = videoElement;
    }

    setCurrentSceneZoomData(cameraData: ICameraData | undefined, init: boolean = false) {
        this.currentSceneZoomData = this.prepareZoomDataForStore(cameraData, init);
    }

    setCurrentAudioElement(audioElement: HTMLAudioElement) {
        this.currentAudioElement = audioElement;
    }

    setVideoDuration(duration: number = 0) {
        this.videoDuration = duration;
    }

    setHotspotVolumeOn(level) {
        this.hotspotVolumeOn = level
    }

    setMaximizeHotspotMode(maximize: boolean = false) {
        this.isMaximizeHotspotMode = maximize
    }

    setLoadedScene(val: boolean = false) {
        this.loadedScene = val;
    }

    setSceneCamera(camEl: Entity<Camera> | null) {
        if (!camEl) {
            this.sceneCamera = null;
            timerId && clearInterval(timerId);
            return;
        }

        if (!this.sceneCamera) {
            this.sceneCamera = camEl;
            this.watchCameraRotation(camEl);
        }
    }

    private prepareZoomDataForStore(cameraData: ICameraData | undefined, init: boolean = false): IZoomData {
        const stateStore = this.global.stateStore;
        const zoomData: IZoomData = {
            zoomIn: (cameraData?.zoomIn && cameraData.zoomIn > 0) ? cameraData?.zoomIn : defaultZoomData.zoomIn,
            zoomOut: (cameraData?.zoomOut && cameraData.zoomOut > 0) ? cameraData?.zoomOut : defaultZoomData.zoomOut,
            initialZoom: (cameraData?.initialZoom && cameraData.initialZoom > 0) ? cameraData?.initialZoom : defaultZoomData.initialZoom,
        }

        if (init) {
            if (stateStore.state.zoom && stateStore.state.zoom !== defaultZoomData.initialZoom) {
                zoomData.initialZoom = stateStore.state.zoom > 0 ? stateStore.state.zoom : defaultZoomData.initialZoom;
            }
        }

        return zoomData;
    }

    // there is no sane way to observe external object changes, right? Let me know otherwise pls
    private watchCameraRotation(camEl: Entity<Camera>) {
        const lookControls = camEl.components['look-controls'];
        const stateStore = this.global.stateStore;

        timerId = window.setInterval(() => {
            const currentRotation = getCameraRotation(lookControls);

            if (!compareObject(currentRotation, stateStore.state.rotation)) {
                stateStore.updateAndSave({rotation: getCameraRotation(lookControls)}, 'save rotation from scene store')
            }
        }, 800)
    }
}
