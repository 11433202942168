import {defaultHotspotSize, maxHotspotSize} from "../../../vars";

AFRAME.registerComponent('virtik-flex-container', {
    schema: {
        size: {type: 'number', default: 0},
    },
    init: function () {
        var containerItem = this.el.getAttribute("virtik-item");

        let hotspotWidth = containerItem.width
        let hotspotHeight = containerItem.height

        if (this.data.size && this.data.size > 0) {
            if (this.data.size > maxHotspotSize) {
                hotspotWidth = maxHotspotSize
                hotspotHeight = maxHotspotSize
            } else {
                hotspotWidth = this.data.size / defaultHotspotSize
                hotspotHeight = this.data.size / defaultHotspotSize
            }
        }


        /*const el = this.el;
        // @ts-ignore
        this.children = el.getChildEntities();
        const data = this.data;

        // @ts-ignore
        for (let i = 0; i < this.children.length; i++) {
            // @ts-ignore
            const childElement = this.children[i];
            // const childItem = childElement.getAttribute("virtik-item");

            // childElement.setAttribute('geometry', `primitive: plane; height: ${childItem.height}; width: ${childItem.width};`)
            // childElement.setAttribute('geometry', `primitive: box; height: 1; width: 1;`)
            childElement.setAttribute('material', `side: double;`)
            childElement.setAttribute('rotation', `${data.rotation};`)
        }*/




        this.el.setAttribute('rounded', `height: ${hotspotHeight}; width: ${hotspotWidth}; opacity: ${this.data.opacity}; color: ${this.data.panelColor}; radius:${this.data.panelRounded}; depthWrite:false; polygonOffset:true; polygonOffsetFactor: 1;`);



    },
    update: function () {
    },
    tick: function () {
    },
    remove: function () {
    },
    pause: function () {
    },
    play: function () {
    },
    setBackground: function () {
        if (this.data.opacity > 0) {
            var item = this.el.getAttribute("virtik-item");
            var panelBackground = document.createElement("a-entity");

            panelBackground.setAttribute('rounded', `height: ${item.height}; width: ${item.width}; opacity: ${this.data.opacity}; color: ${this.data.panelColor}; radius:${this.data.panelRounded}; depthWrite:false; polygonOffset:true; polygonOffsetFactor: 2;`);
            panelBackground.setAttribute('position', this.el.getAttribute("position").x + ' ' + this.el.getAttribute("position").y + ' ' + (this.el.getAttribute("position").z - 0.0125));
            panelBackground.setAttribute('rotation', this.el.getAttribute("rotation").x + ' ' + this.el.getAttribute("rotation").y + ' ' + this.el.getAttribute("rotation").z);

            // @ts-ignore
            this.el.parentNode.insertBefore(panelBackground, this.el);
        }
    },
});

AFRAME.registerPrimitive('a-virtik-flex-container', {
    defaultComponents: {
        'virtik-item': {type: 'flex-container'},
        'virtik-flex-container': {}
    },
    mappings: {
        'width': 'virtik-item.width',
        'height': 'virtik-item.height',
        'margin': 'virtik-item.margin',
        'size': 'virtik-item.size',
    }
});

export {}
