import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Box, Center, Title} from "@mantine/core";
import cx from 'clsx';
import {globalContext} from "../../../App"
import {useHotspotVideoControls} from "../hooks/useHotspotVideoControls";
import {useWindowDimensions} from "../hooks/useWindowDimensions";
import {useZoomControls} from "../hooks/useZoomControls";
import ZoomControls from "../view/ZoomControls";
import VideoSlider from "../view/VideoSlider";
import CustomOverlay from "../view/CustomOverlay";
import {Logo360} from "../view/Logo360";
import sceneStyles from "../view/Scenes.module.css";
import modalStyles from "./ModalHotspots.module.css";

const ModalHotspotsVideo360 = observer(({data}: any) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState(false);
    const global = useContext(globalContext);
    const sceneStore = global.sceneStore;
    const cameraId: string = 'video360HotspotCamera';
    const {isIos} = useWindowDimensions();

    const {
        initHotspotVideoElem,
        isPlay, togglePlay,
        rangeValue, updateRangeValue,
        videoDuration,
        backwardStep, forwardStep,
        resetVideoRotation,
        setVideoCurrentTime,
        bufferSize
    } = useHotspotVideoControls()

    const {
        disabledZoomIn, disabledZoomOut,
        toggleZoomIn, toggleZoomOut,
        addZoomListener, removeZoomListener,
    } = useZoomControls(cameraId)

    useEffect(() => {
        addZoomListener('hotspotVideoScene')
        const element = document.getElementById('hotspotVideo')! as HTMLVideoElement;

        element.oncanplay = function () {
            setIsLoading(false);
            initHotspotVideoElem(element)
        };
        element.onerror = function () {
            setError(true);
            setIsLoading(false);
        };

        return function cleanup() {
            removeZoomListener("hotspotVideoScene");
        };
    }, []);

    return (<>
            {error
                ? <Center><Title mt={200} c={"red"} order={3}>Error while loading picture</Title></Center>
                : <Box className={modalStyles.modalVideo360} id="video360HotspotBehaviour">
                    <CustomOverlay visible={isLoading}></CustomOverlay>

                    <a-scene device-orientation-permission-ui="enabled: false"
                             embedded='true'
                             scale='1 1 1'
                             vr-mode-ui="enabled: false"
                             id="hotspotVideoScene"
                             loaded-scene>
                        <a-assets id="sceneAssets">
                            {isIos()
                                ? <video id="hotspotVideo"
                                         playsInline={true}
                                         autoPlay={true}
                                         muted={sceneStore.hotspotVolumeOn}
                                         crossOrigin="anonymous"
                                         src={data.fileLink}/>
                                : <video id="hotspotVideo"
                                         muted={sceneStore.hotspotVolumeOn}
                                         crossOrigin="anonymous"
                                         src={data.fileLink}/>
                            }
                        </a-assets>

                        {/*@ts-ignore*/}
                        <a-videosphere id='videosphereCustom' src='#hotspotVideo' position="0 0 -1.5"/>

                        <a-entity id={cameraId}
                                  camera='active: true; zoom: 1;'
                                  look-controls="reverseMouseDrag: true; magicWindowTrackingEnabled: false"
                                  wasd-controls-enabled={false}>
                        </a-entity>
                    </a-scene>

                    {!isLoading &&
                        <>
                            <Logo360/>

                            <Box className={cx(sceneStyles.sceneControls, sceneStyles.leftSceneControls, sceneStyles.hotspotSceneControls)}>
                                <ZoomControls toggleZoomIn={toggleZoomIn}
                                              toggleZoomOut={toggleZoomOut}
                                              disabledZoomIn={disabledZoomIn}
                                              disabledZoomOut={disabledZoomOut}/>
                            </Box>

                            <Box className={cx(sceneStyles.videoRange, sceneStyles.videoRangeHotspot)}>
                                {/*TODO isMinWidth true ?*/}
                                <VideoSlider isPlay={isPlay}
                                             rangeValue={rangeValue}
                                             bufferSize={bufferSize}
                                             updateRangeValue={updateRangeValue}
                                             maxVideoDuration={videoDuration}
                                             backwardStep={backwardStep}
                                             forwardStep={forwardStep}
                                             togglePlay={togglePlay}
                                             setVideoCurrentTime={setVideoCurrentTime}
                                             rotateVideo={resetVideoRotation}
                                             isMinWidth={true}
                                />
                            </Box>
                        </>
                    }
                </Box>
            }
        </>
    );
})

export default ModalHotspotsVideo360
