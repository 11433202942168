import React, {useContext} from "react";
import {Box} from "@mantine/core";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import {modals} from "@mantine/modals";

import {globalContext} from "../../../App";
import tour from "../../../assets/images/mode-switcher/360-tour-v4.svg";
import roadMap from "../../../assets/images/mode-switcher/road-map-fill.svg";
import ruler from "../../../assets/images/mode-switcher/ruler-fill.svg";
import plan from "../../../assets/images/mode-switcher/plan.svg";
import classes from "./BottomSceneSwitcher.module.css";
import {ViewMode} from "../../../models/shared/SceneType";
import {INodeData} from "../../../models/shared/IProjectBase";
import {I2DPlanScene} from "../../../models/shared/Plan2D";
import CustomTooltip from "./CustomTooltip";

const BottomSceneSwitcher = observer(({t, switcherCallback}: IProps) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const plan2DStore = global.plan2DStore;
    const stateStore = global.stateStore;
    const switcherMode = tourStore.switcherMode;
    const plan2d: I2DPlanScene[] = tourStore.plan;

    function switchMode(mode?: ViewMode) {
        const tourObject = sceneStore.currentScene!;

        if (switcherMode === mode) {
            return;
        }

        stateStore.updateAndSave({mode: mode}, 'switcher update view mode')
        switch (mode) {
            case ViewMode.scene360:
                tourStore.setSwitcherMode(ViewMode.scene360);
                tourStore.setIfHideUiForPopup(false);
                break;
            case ViewMode.plan:
                tourStore.setSwitcherMode(ViewMode.plan);
                tourStore.setIfHideUiForPopup(true);
                plan2DStore.setCurrent2DPlanElement(null);
                // renderModal({type: 'plan2D'});
                break;
            case ViewMode.map:
                tourStore.setIfHideUiForPopup(false);
                plan2DStore.setCurrent2DPlanElement(null);
                break;
            case ViewMode.measurement:
                tourStore.setIfHideUiForPopup(false);
                plan2DStore.setCurrent2DPlanElement(null);
                break;
            default:
                console.warn("Unknown SwitcherType");
                return;
        }
        switcherCallback(mode, tourObject);
    }

    return (
        <Box className={classes.block}>
            {tourStore.generalSettings.modeSwitcherShowButtons.scene360 &&
                <CustomTooltip label={t("360 Tour")}
                               mode={ViewMode.scene360}
                               src={tour}
                               id={"set360Scene"}
                               isActive={switcherMode === ViewMode.scene360}
                               callbackFunc={switchMode}/>
            }

            {tourStore.generalSettings.modeSwitcherShowButtons.map &&
                <CustomTooltip label={t("Map")}
                               mode={ViewMode.map}
                               src={roadMap}
                               isActive={switcherMode === ViewMode.map}
                               callbackFunc={switchMode}/>
            }

            {tourStore.generalSettings.modeSwitcherShowButtons.measurement &&
                <CustomTooltip label={t("Measurement")}
                               mode={ViewMode.measurement}
                               src={ruler}
                               isActive={switcherMode === ViewMode.measurement}
                               callbackFunc={switchMode}/>
            }

            {tourStore.generalSettings.modeSwitcherShowButtons.plan &&
                <CustomTooltip label={t("2D Plan")}
                               mode={ViewMode.plan}
                               src={plan}
                               disabled={Object.keys(plan2d).length === 0}
                               isActive={switcherMode === ViewMode.plan}
                               callbackFunc={switchMode}/>
            }
        </Box>
    );
})

export default withTranslation()(BottomSceneSwitcher)

interface IProps {
    t: any;
    switcherCallback: (mode: ViewMode, node?: INodeData, init?: boolean) => void;
}
