import {useForm, isNotEmpty, matchesField, hasLength} from '@mantine/form';
import {Button, Group, TextInput, Box, PasswordInput} from '@mantine/core';
import {useContext} from "react";
import {globalContext} from "../../App";

export default function RegistrationForm() {
  const globalStore = useContext(globalContext);
  const form = useForm({
    initialValues: {
      login: '',
      password: '',
      confirmPassword: '',
    },

    validate: {
      login: hasLength({min: 2, max: 50}, 'Name must be 2-50 characters long'),
      password: isNotEmpty('Enter your Password'),
      confirmPassword: matchesField('password', 'Passwords are not the same'),
    },
  });

  async function firebaseSignUp(params: any) {
    const response = await globalStore.userStore.signUp(params);

    if (response.status) {
      alert('Congrats! User created!');
    } else {
      alert(response.error);
    }
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!form.validate().hasErrors) {
      firebaseSignUp(form.values);
    }
  }

  return (
    <Box component="form" maw={400} mx="auto" onSubmit={handleSubmit}>
      <TextInput label="Login" placeholder="Login" withAsterisk {...form.getInputProps('login')} />
      <PasswordInput
        label="Password"
        placeholder="Password"
        withAsterisk
        mt="md"
        {...form.getInputProps('password')}
      />
      <PasswordInput
        label="Confirm Password"
        placeholder="Confirm Password"
        withAsterisk
        mt="md"
        {...form.getInputProps('confirmPassword')}
      />
      <Group justify="flex-end" mt="md">
        <Button type="submit">Submit</Button>
      </Group>
    </Box>
  );
}
