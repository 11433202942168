import {DEMO_SERVER} from '../cfg/config'

export async function doFetch(url: string): Promise<Response> {
    const headers = {
        "Set-Env": isDemoServer() ? "demo" : "stage",
    };

    return fetch(url, {headers})
}

function isDemoServer() {
    return document.location.hostname === DEMO_SERVER
}
