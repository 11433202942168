import {GlobalStore} from "./GlobalStore";
import {makeAutoObservable} from "mobx";

export class HotspotPdfStore {
    global: GlobalStore;
    currentPage: number = 1
    scale: number = 1
    onLoad: boolean = false

    constructor(global: GlobalStore) {
        this.global = global;
        makeAutoObservable(this);
    }

    setCurrentPage(page: number = 1) {
        this.currentPage = page;
    }

    setScale(pdfScale: number) {
        this.scale = pdfScale;
    }

    setLoading(load: boolean = false) {
        this.onLoad = load;
    }
}
