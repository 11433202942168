// TODO should it be different for demo server?..
const cloudFunctionsURL = 'https://us-central1-virtik-virtual-tour-software.cloudfunctions.net'
const localCloudFunctionsURL = 'http://127.0.0.1:5001/virtik-virtual-tour-software/us-central1'
const isLocal = false; //Todo need set false before commit

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyAHycaUp67CetA2PuDE136QwWQHqeKW2Nw",
  authDomain: "virtik-virtual-tour-software.firebaseapp.com",
  projectId: "virtik-virtual-tour-software",
  cloudFunctionsURL,
  localCloudFunctionsURL,
  isLocal,
  storageBucket: "virtik-virtual-tour-software.appspot.com",
  messagingSenderId: "728710468186",
  appId: "1:728710468186:web:bcf5557f282790ec79be63",
  measurementId: "G-N9BMVRHGRD"
};
