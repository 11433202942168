import React, {useContext, useState} from 'react';
import {Box, Button, Grid, Image, Text, Title} from "@mantine/core";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import Moment from 'moment';
import cx from 'clsx';

import logoDefault from "../assets/images/vertical virtik white.png";
import minimize from "../assets/images/minimize.png";
import maximize from "../assets/images/maximize.png";
import {globalContext} from "../App";
import pageClasses from "./Page.module.css";
import sceneClasses from "../components/tour/view/Scenes.module.css"
import {useFullscreenMode} from "../components/tour/hooks/useFullscreenMode";

const TourNotFound = observer(({t}: any) => {
    const global = useContext(globalContext);
    const lastSeeTours = global.tourStore.lastVisitedTours;
    const {toggleFullscreenWithoutState} = useFullscreenMode();
    const [isFullscreen, setIsFullscreen] = useState(false)

    function navigateToVisitedTour(referenceCode: string = '') {
        window.location.assign(window.location.origin + '/' + referenceCode)
    }

    function toggleFullscreen() {
        setIsFullscreen(!isFullscreen)
        toggleFullscreenWithoutState(isFullscreen)
    }

    return (
        <Box className={pageClasses.parentBlock}>
            <Box className={pageClasses.mainBlock}>
                <Box className={pageClasses.titleBlock}>
                    <Title size="h1">
                        {t('Virtual experience')}
                    </Title>
                </Box>

                <Box id="virtikPageContent"
                     className={cx(
                         pageClasses.virtikContainer,
                         pageClasses.startContainer
                     )}>

                    <Box className={pageClasses.previewContainer}>
                        <Box className={pageClasses.startSceneBlock}>
                            <Box className={pageClasses.title404}>
                                <Title fz={120} size="h1">404</Title>
                            </Box>

                            <Box className={pageClasses.notFoundMessage}>
                                <Title fz={34} size={"h2"}>The requested tour was not found.</Title>
                            </Box>

                            <Box className={pageClasses.tryAgain}>
                                <Title fz={20} size="h3">Please check the link and try again!</Title>
                            </Box>
                        </Box>
                    </Box>

                    <Box>
                        <Button className={cx(sceneClasses.roundButton, pageClasses.fullscreenButton)}
                                onClick={toggleFullscreen}>
                            <Image src={isFullscreen ? minimize : maximize}/>
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box className={pageClasses.parentLastVisitedBlock}>
                <Box>
                    <Text className={pageClasses.lastVisitedTitle}>
                        {t('Last visited tours')}:
                    </Text>

                    {lastSeeTours.length === 0 && (
                        <Box className={pageClasses.childBlock}>
                            <Grid className={pageClasses.gridBlock}>
                                <Grid.Col span={12}>
                                    <Text ta={"center"}>There is no last visited tours yet</Text>
                                </Grid.Col>
                            </Grid>
                        </Box>
                    )}
                </Box>

                {lastSeeTours.map(function (item) {
                    return <Box className={pageClasses.childBlock} key={item.date}>
                        <Grid className={pageClasses.gridBlock}
                              gutter={{ base: 5, xs: 'xs', md: 'xl', xl: 50 }}
                              classNames={{inner: pageClasses.mantineGridInner}}
                              justify="space-between"
                              align="stretch">
                            <Grid.Col className={pageClasses.gridColumnImage} span={2}>
                                <Image fit={"cover"} className={pageClasses.gridLogo} src={logoDefault} alt=""/>
                            </Grid.Col>

                            <Grid.Col className={cx(pageClasses.gridColumnText, pageClasses.gridColumnTourName)} span={5}>
                                <Text ta={"center"}>
                                    {item.name}
                                </Text>
                            </Grid.Col>

                            <Grid.Col className={cx(pageClasses.gridColumnText, pageClasses.gridColumnDate)} span={3}>
                                <Text ta={"center"}>
                                    {Moment(item.date).format('DD.MM.YYYY HH:mm')}
                                </Text>
                            </Grid.Col>

                            <Grid.Col className={pageClasses.gridColumnRight} span={2}>
                                <Button className={pageClasses.gridLink}
                                        variant={"outline"}
                                        onClick={() => navigateToVisitedTour(item.referenceCode)}>
                                    View
                                </Button>
                            </Grid.Col>
                        </Grid>
                    </Box>
                })}
            </Box>
        </Box>
    );
})

export default withTranslation()(TourNotFound);
