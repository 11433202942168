import React, {useContext} from "react";
import {LoadingOverlay} from "@mantine/core";
import classes from '../../../App.module.css';
import {globalContext} from "../../../App";

export default function CustomOverlay({className, visible}: IProps) {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;

    return (
        <LoadingOverlay className={className}
                        loaderProps={{size: 'xl', color: tourStore.getColorUiScheme()}}
                        classNames={{
                            overlay: classes.customOverlay,
                        }}
                        visible={visible}/>
    );
}

interface IProps {
    className?: string;
    visible: boolean;
}
