import {useContext} from 'react';
import {globalContext} from "../../../App";
import classes from "../view/Scenes.module.css";

export function useFullscreenMode() {
    // TODo не прокидівать а использовать там где нужно ?
    const selectorId = 'virtikPageContent';
    const global = useContext(globalContext);
    const stateStore = global.stateStore

    function toggleFullscreen(force: boolean = false) {
        const contentDiv: HTMLElement = document.getElementById(selectorId)!;
        const fullscreen: boolean = stateStore.state.fullscreen

        if (!contentDiv) return
        emulatedFullScreen(fullscreen, contentDiv)

        if (force) {
            stateStore.updateOne('fullscreen', !fullscreen, true)
        } else {
            stateStore.updateAndSave({fullscreen: !fullscreen}, 'fullscreen hook toggle')
        }
    }

    function toggleFullscreenWithoutState(fullscreen: boolean) {
        const contentDiv: HTMLElement = document.getElementById(selectorId)!;

        if (!contentDiv) return

        emulatedFullScreen(fullscreen, contentDiv)
    }

    function emulatedFullScreen(fullscreen: boolean, contentDiv: HTMLElement) {
        if (fullscreen) {
            contentDiv.classList.remove(classes.emulatedFullScreen)
        } else {
            contentDiv.classList.add(classes.emulatedFullScreen)
        }
    }

    return {toggleFullscreen, toggleFullscreenWithoutState};
}
