import {Box} from "@mantine/core";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {withTranslation} from "react-i18next";
import {useHotkeys} from "@mantine/hooks";

import {globalContext} from "../../../../App";
import classes from "../ModalPlan2D.module.css";
import {I2DPlanScene} from "../../../../models/shared/Plan2D";
import {AddHotspot} from "./AddHotspot";
import {moveToTargetHotspot} from "../utils/targetHotspot";
import {draggableContent} from "../../utils/Draggable";
import {getSelectedPlanIndex} from "../../utils/Plan2D";

const Content2DPlan = observer(({t, plan2DData, getHotspots, changeSceneHandler}: IProps) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const plan2DStore = global.plan2DStore;
    const planImages: string[] = [];
    const [currentPlanIndex, setCurrentPlanIndex] = useState<number>(0);
    const [renderHotspots, setRenderHotspots] = useState<any>();
    let content: HTMLElement;

    useEffect(() => {
        //If 2D plan image not changed
        if (currentPlanIndex === plan2DStore.current2DPlanElement) {
            return;
        }

        setClassNames();

        //Set array with source image link
        if (Object.keys(plan2DData).length !== 0) {
            const result: I2DPlanScene[] = Object.values(plan2DData);

            for (const plan of result) {
                if (plan.nodeDataPath != null && plan.nodeDataLink != null) {
                    planImages.push(plan.nodeDataLink);
                }
            }
        }

        //Set current 2D plan element
        let currentPlanImageSrc = "";

        if (plan2DStore.current2DPlanElement !== null) {
            setCurrentPlanIndex(plan2DStore.current2DPlanElement);
            currentPlanImageSrc = planImages[plan2DStore.current2DPlanElement];
        } else {
            const selectedPlanIndex = getSelectedPlanIndex(plan2DData, sceneStore);

            if (selectedPlanIndex === -1) {
                currentPlanImageSrc = planImages[0];
                plan2DStore.setCurrent2DPlanElement(0);
                setCurrentPlanIndex(0);
            } else {
                currentPlanImageSrc = planImages[selectedPlanIndex];
                plan2DStore.setCurrent2DPlanElement(selectedPlanIndex);
                setCurrentPlanIndex(selectedPlanIndex);
            }
        }

        const planImage = (document.getElementById('current2DPlanImage') as HTMLImageElement);
        const planChild = (document.getElementById('current-plan-child') as HTMLDivElement);

        if (planImage) {
            setPlanImage(planImage, currentPlanImageSrc, planChild);
        }

        draggableContent('#current-plan-content');
    }, [plan2DStore.current2DPlanElement]);

    useEffect(() => {
        //If current hotspot position button clicked
        if (plan2DStore.ifCenterPlanViewport) {
            actionMoveToCurrentHotspot();
            return;
        }
    }, [plan2DStore.ifCenterPlanViewport]);

    function setClassNames() {
        const overlay = document.querySelector('.mantine-Overlay-root');
        const body = document.querySelector('.mantine-Modal-body');
        const inner = document.querySelector('.mantine-Modal-inner');
        content = document.querySelector('.mantine-Modal-content');

        overlay && overlay.classList.add(classes.overlayElementOpenModal);
        inner && inner.classList.add(classes.innerElementOpenModal);
        content && content.classList.add(classes.contentElementOpenModal);
        body && body.classList.add(classes.bodyElementOpenModal);

        return content;
    }

    function setPlanImage(planImage: HTMLImageElement, currentPlanImageSrc: string, planChild: HTMLElement) {
        tourStore.setShowLoadingFor360Scene(true);
        planImage.setAttribute("src", currentPlanImageSrc);

        planImage.onload = function (e) {
            plan2DStore.doResetZoomRightControls(true);
            content.style.cssText = 'max-height: 100%';
            content.style.cssText = 'height: auto';
            planChild.style.cssText = 'height: 100%';

            if ((e.currentTarget as HTMLImageElement).height < document.querySelector('#virtikContentWrapper').offsetHeight) {
                content.style.cssText = 'height: auto';
            } else {
                content.style.cssText = 'height: auto';
                content.style.cssText = 'max-height: 100%';
                planImage.style.cssText = 'max-height: 100%';
            }

            //Render hotspots
            const nodes: ReactNode[] = [];
            const index = plan2DStore.current2DPlanElement ?? 0;
            (tourStore.plan[index]?.hotspots ?? []).forEach((elem, index) => {
                nodes.push(<AddHotspot hsd={elem.hotspotData}
                                       index={index}
                                       key={index}
                                       planImage={planImage}
                                       isMinimap={false}
                                       currentSceneName={sceneStore.currentScene!?.nodeName}
                                       changeSceneHandler={changeSceneHandler}/>)
            });

            setRenderHotspots(<>{nodes}</>);
            tourStore.setShowLoadingFor360Scene(false);
        };
    }

    function actionMoveToCurrentHotspot() {
        if (!getHotspots()) {
            return;
        }

        const container = document.querySelector('#current-plan-content');// visible plan section
        const child = document.querySelector('#current-plan-child'); //draggable plan image
        const planImage = (document.getElementById('current2DPlanImage') as HTMLImageElement);
        moveToTargetHotspot(getHotspots(), sceneStore.currentScene!.nodeName, container, child, planImage);

        plan2DStore.setIfCenterPlanViewport(false);
    }

    useHotkeys([
        ['Escape', closeHandler],
    ]);

    function closeHandler() {
        const scene: any = document.querySelector('#set360Scene');
        scene.click();
    }

    return (
        <Box id="current-plan-content" className={classes.currentPlanContent}>
            <Box id="current-plan-child" className={classes.child}>
                <img id="current2DPlanImage" src="" alt=""/>
                {renderHotspots}
            </Box>
        </Box>
    );
})

export default withTranslation()(Content2DPlan)

interface IProps {
    t: any;
    plan2DData: I2DPlanScene[];
    changeSceneHandler: Function;
    getHotspots: Function;
}
