import {Button, CopyButton} from '@mantine/core';
import React from "react";
import {observer} from "mobx-react-lite";
import {withTranslation} from "react-i18next";
import cx from 'clsx';
import headerClasses from "./Header.module.css";

const CopyButtonElement = observer(({t, value, copyButtonClassName = ''}: IProps) => {
        return (
            <CopyButton value={value}>
                {({copied, copy}) => (
                    <Button className={cx(copyButtonClassName, (copied ? headerClasses.copiedButton : headerClasses.copyButton))}
                            variant="filled"
                            color={copied ? 'teal' : 'blue'}
                            onClick={copy}>
                        {copied ? t('Copied Link') : t('Copy Link')}
                    </Button>
                )}
            </CopyButton>
        );
    }
)

export default withTranslation()(CopyButtonElement)

interface IProps {
    t: any;
    value: string;
    copyButtonClassName?: string
}
