import {Box, ScrollArea} from "@mantine/core";
import React, {useContext, useRef, useState} from "react";
import {Document, Page, pdfjs} from 'react-pdf';
import {observer} from "mobx-react-lite";
import "./css/pdf.css";
import ModalHotspotsPdfDesktopHeader from "./ModalHotspotsPdfDesktopHeader";
import {globalContext} from "../../../App";
import CustomOverlay from "../view/CustomOverlay";
import modalStyles from "./ModalHotspots.module.css";
import scrollbarStyles from "./TextScrollbar.module.css"
import {HotspotBehaviourPdf} from "../../../models/shared/HotspotBehaviour";

const initialOptions = {
    cMapUrl: `${process.env.PUBLIC_URL}/pdfjs/cmaps/`,
    standardFontDataUrl: `${process.env.PUBLIC_URL}/pdfjs/standard_fonts/`,
};

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdfjs/pdf.worker.min.js`;

const ModalHotspotsPdfDesktop = observer(({data, closeHandler, fullscreenHandler}: IProps) => {
    const [pdfLoading, setPdfLoading] = useState<boolean>(true);
    const numPagesInit = useRef<number>(1);
    const global = useContext(globalContext);
    const hotspotPdfStore = global.hotspotPdfStore;

    function getCurrentPage(el) {
        hotspotPdfStore.setLoading()

        let pageHeight = el.target.scrollHeight / numPagesInit.current;
        let page = Math.ceil((el.target.scrollTop + el.target.clientHeight) / pageHeight);

        if (page === 0) {
            page = 1;
        }

        if (page > numPagesInit.current) {
            page = numPagesInit.current;
        }

        hotspotPdfStore.setCurrentPage(page)
    }

    function onSourceSuccess() {
        document.querySelector(".mantine-ScrollArea-viewport").addEventListener("scroll", getCurrentPage, true);
    }

    function onDocumentLoadSuccess({numPages}) {
        hotspotPdfStore.setLoading(true)

        if (pdfLoading) {
            setPdfLoading(false);
            numPagesInit.current = numPages
        }
    }

    return (
        <Box className={modalStyles.pdfBody}>
            <CustomOverlay visible={pdfLoading}></CustomOverlay>

            <ModalHotspotsPdfDesktopHeader closeHandler={closeHandler}
                                           fullscreenHandler={fullscreenHandler}
                                           numPagesInit={numPagesInit.current}/>

            <ScrollArea classNames={scrollbarStyles}>
                <Box className={modalStyles.pdfWrapper}>
                    {data.fileLink &&
                        <Box>
                            <Document loading={''}
                                      file={data.fileLink}
                                      options={initialOptions}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                      onSourceSuccess={onSourceSuccess}>
                                {Array.from(
                                    new Array(numPagesInit.current),
                                    (el, index) => (
                                        <Page loading={''}
                                              className={modalStyles.pdfOnePage}
                                              renderAnnotationLayer={false}
                                              renderTextLayer={false}
                                              renderMode={'canvas'}
                                              scale={hotspotPdfStore.scale}
                                              key={`page_${index + 1}`}
                                              pageNumber={index + 1}
                                        />
                                    )
                                )}
                            </Document>
                        </Box>
                    }
                </Box>
            </ScrollArea>
        </Box>
    );
})

interface IProps {
    data: HotspotBehaviourPdf;
    closeHandler: CallableFunction;
    fullscreenHandler: CallableFunction;
}

export default ModalHotspotsPdfDesktop
