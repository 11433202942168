import {Box, Button, Image} from "@mantine/core";
import {modals} from "@mantine/modals";
import {observer} from "mobx-react-lite";
import {useHotkeys} from "@mantine/hooks";
import React, {useContext, useEffect, useState} from "react";

import close from "../../../assets/images/close.png";
import minimize from "../../../assets/images/minimize.png";
import maximize from "../../../assets/images/maximize.png";
import mute from "../../../assets/images/mute.png";
import unmute from "../../../assets/images/unmute.png";
import {globalContext} from "../../../App";
import ModalHotspotsPicture from "./ModalHotspotsPicture";
import ModalHotspotsAlbum from "./ModalHotspotsAlbum";
import ModalHotspotsPdfDesktop from "./ModalHotspotsPdfDesktop";
import ModalHotspotsPdfMobile from "./ModalHotspotsPdfMobile";
import ModalHotspotsVideo360 from "./ModalHotspotsVideo360";
import ModalHotspotsPicture360 from "./ModalHotspotsPicture360";
import ModalHotspotsVideo from "./ModalHotspotsVideo";
import ModalHotspotsText from "./ModalHotspotsText";
import sceneStyles from "../view/Scenes.module.css";
import modalStyles from "./ModalHotspots.module.css";
import {
    HotspotBehaviourAlbum,
    HotspotBehaviourData,
    HotspotBehaviourPdf,
    HotspotBehaviourText,
    HotspotBehaviourType
} from "../../../models/shared/HotspotBehaviour";
import {mobileDeviceMaxWidth} from "../../../vars";
import {useWindowDimensions} from "../hooks/useWindowDimensions";

const ModalHotspots = observer(({type, hotspotBehaviourData, extra}: IProps) => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const stateStore = global.stateStore;
    const [isMaximize, setIsMaximize] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<any>();
    const hotspotsWithSound: string[] = [HotspotBehaviourType.video360, HotspotBehaviourType.video];
    const {orientation, width, height, isMobile} = useWindowDimensions();

    useEffect(() => {
        const body = document.querySelector('.mantine-Modal-body');
        const inner = document.querySelector('.mantine-Modal-inner');
        const content = document.querySelector('.mantine-Modal-content');
        const header = document.querySelector('.mantine-Modal-header');
        const overlay = document.querySelector('.mantine-Modal-overlay')

        body && body.classList.add(modalStyles.customBodyElement);
        inner && inner.classList.add(modalStyles.customInnerElement);

        if (content) {
            content.classList.add(modalStyles.customContentElement);

            if (stateStore.state.fullscreen) {
                content.classList.add(modalStyles.customContentElementMaximize);

                if (orientation.includes('landscape') && width > height && height < mobileDeviceMaxWidth) {
                    content.classList.add(modalStyles.customContentElementMaximizeLandscapeOnMobile);
                }
            }
        }

        overlay && overlay.classList.add(modalStyles.customOverlay);

        switch (type) {
            case HotspotBehaviourType.picture:
                setModalContent(<ModalHotspotsPicture data={hotspotBehaviourData}/>)
                break;
            case HotspotBehaviourType.picture360:
                setModalContent(<ModalHotspotsPicture360 data={hotspotBehaviourData}/>)
                break;
            case HotspotBehaviourType.video:
                setModalContent(<ModalHotspotsVideo data={hotspotBehaviourData}/>)
                break;
            case HotspotBehaviourType.video360:
                setModalContent(<ModalHotspotsVideo360 data={hotspotBehaviourData}/>)
                break;
            case HotspotBehaviourType.album:
                body && body.classList.add(modalStyles.customBodyElementFullscreen);
                setModalContent(<ModalHotspotsAlbum data={hotspotBehaviourData}/>)
                break;
            case HotspotBehaviourType.pdf:
                content.classList.add(modalStyles.customContentPdfElement);

                if (isMobile()) {
                    setModalContent(<ModalHotspotsPdfMobile data={hotspotBehaviourData as HotspotBehaviourPdf}
                                                            closeHandler={closeHandler}
                                                            fullscreenHandler={toggleFullscreenMode}
                    />)
                } else {
                    header && header.classList.add(modalStyles.customHeaderPdfElement);
                    setModalContent(<ModalHotspotsPdfDesktop data={hotspotBehaviourData as HotspotBehaviourPdf}
                                                             closeHandler={closeHandler}
                                                             fullscreenHandler={toggleFullscreenMode}
                    />)
                }
                break;
            case HotspotBehaviourType.text:
                content.classList.add(modalStyles.customContentPdfElement);
                header.classList.add(modalStyles.customHeaderPdfElement);
                setModalContent(<ModalHotspotsText
                    data={hotspotBehaviourData as HotspotBehaviourText}
                    closeHandler={closeHandler}
                    fullscreenHandler={toggleFullscreenMode}
                />)
                break;
            default:
                console.error('Unsupported hotspot type!')
        }

        tourStore.setIfHideUiForPopup(true);
    }, []);

    useHotkeys([
        ['Escape', closeHandler],
    ]);

    function toggleFullscreenMode(value = false) {
        switch (type) {
            case HotspotBehaviourType.video360:
            case HotspotBehaviourType.video:
            case HotspotBehaviourType.picture360:
            case HotspotBehaviourType.picture:
            case HotspotBehaviourType.album:
                setCustomStylesForModal(!isMaximize, type === HotspotBehaviourType.album);
                window.dispatchEvent(new Event('resize'));
                break;
            case HotspotBehaviourType.pdf:
            case HotspotBehaviourType.text:
                setCustomStylesForModalPdf(!value);
                break;
            default:
                setCustomStylesForModalPicture(!isMaximize);
        }

        setIsMaximize(!isMaximize);
        sceneStore.setMaximizeHotspotMode(!isMaximize)
    }

    function closeHandler() {
        sceneStore.setMaximizeHotspotMode(false)
        switch (type) {
            case HotspotBehaviourType.picture:
                stopLoadingImage();
                setCustomStylesForModalPicture(false);
                tourStore.setIfHideUiForPopup(false);
                modals.closeAll();
                break;
            case HotspotBehaviourType.album:
                console.log(hotspotBehaviourData);
                stopLoadingImage();
                setCustomStylesForModal(false, true);
                tourStore.setIfHideUiForPopup(false);
                modals.closeAll();
                break;
            case HotspotBehaviourType.picture360:
            case HotspotBehaviourType.video:
            case HotspotBehaviourType.video360:
                stopLoadingImage();
                setCustomStylesForModalPicture(false);
                tourStore.setIfHideUiForPopup(false);
                modals.closeAll();
                break;
            default:
                tourStore.setIfHideUiForPopup(false);
                modals.closeAll();
        }
    }

    function setCustomStylesForModalPicture(isMaximize: boolean) {
        const root = document.querySelector('.mantine-Modal-root');
        const inner = document.querySelector('.mantine-Modal-inner');
        const content = document.querySelector('.mantine-Modal-content');

        if (isMaximize) {
            root.classList.add(modalStyles.rootElementOpenModal);
            inner.classList.add(modalStyles.innerElementOpenModal);
            content.classList.add(modalStyles.contentElementOpenModal);
        } else {
            root.classList.remove(modalStyles.rootElementOpenModal);
            inner.classList.remove(modalStyles.innerElementOpenModal);
            content.classList.remove(modalStyles.contentElementOpenModal);
        }
    }

    function setCustomStylesForModal(isMaximize: boolean, isAlbum: boolean = false) {
        const root = document.querySelector('.mantine-Modal-root');
        const body = document.querySelector('.mantine-Modal-body');
        const inner = document.querySelector('.mantine-Modal-inner');
        const content = document.querySelector('.mantine-Modal-content');

        if (isMaximize) {
            isAlbum && body.classList.remove(modalStyles.customBodyElementFullscreen);
            root.classList.add(modalStyles.rootElementOpenModal);
            inner.classList.add(modalStyles.innerElementOpenModal);
            content.classList.add(modalStyles.contentElementOpenModalAlbum);
            content.classList.add(modalStyles.contentDisableBorder);
        } else {
            isAlbum && body.classList.add(modalStyles.customBodyElementFullscreen);
            root.classList.remove(modalStyles.rootElementOpenModal);
            inner.classList.remove(modalStyles.innerElementOpenModal);
            content.classList.remove(modalStyles.contentElementOpenModalAlbum);
            content.classList.remove(modalStyles.contentDisableBorder);
        }
    }

    function setCustomStylesForModalPdf(isMaximize: boolean) {
        const inner = document.querySelector('.mantine-Modal-inner');
        const content = document.querySelector('.mantine-Modal-content');

        if (isMaximize) {
            inner.classList.add(modalStyles.innerElementOpenModal);
            content.classList.add(modalStyles.contentElementOpenModal);
            content.classList.add(modalStyles.contentDisableBorder);
        } else {
            inner.classList.remove(modalStyles.innerElementOpenModal);
            content.classList.remove(modalStyles.contentElementOpenModal);
            content.classList.remove(modalStyles.contentDisableBorder);
        }
    }

    function stopLoadingImage() {
        switch (type) {
            case HotspotBehaviourType.picture:
                const element = document.getElementById('imageHotspotBehaviour');
                element?.setAttribute('src', "");
                break;
            case HotspotBehaviourType.album:
                (hotspotBehaviourData as HotspotBehaviourAlbum).slides.forEach((h, i) => {
                    const slide = document.querySelector('#album_slide_' + i);
                    slide?.setAttribute('src', "");
                })
                break;
            default:
        }
    }

    function toggleHotspotSound() {
        sceneStore.setHotspotVolumeOn(!sceneStore.hotspotVolumeOn)
    }

    return (
        <>
            {type !== HotspotBehaviourType.pdf &&
                type !== HotspotBehaviourType.text &&
                <Box className={modalStyles.hotspotControls}>
                    {
                        hotspotsWithSound.indexOf(type) > -1 &&
                        <Button className={sceneStyles.roundButton}
                                onClick={toggleHotspotSound}>
                            {sceneStore.hotspotVolumeOn ? <Image src={mute}/> : <Image src={unmute}/>}
                        </Button>
                    }

                    <Button className={sceneStyles.roundButton}
                            onClick={() => {
                                toggleFullscreenMode()
                            }}>
                        {isMaximize ? <Image src={minimize}/> : <Image src={maximize}/>}
                    </Button>

                    <Button onClick={closeHandler}
                            className={sceneStyles.roundButton}>
                        <Image src={close}/>
                    </Button>
                </Box>
            }

            {modalContent}
        </>
    );
})

export default ModalHotspots

interface IProps {
    type: HotspotBehaviourType,
    hotspotBehaviourData?: HotspotBehaviourData,
    extra?: {[k: string]: any},
}
