import React from "react";
import {IMenuOptions} from "../../models/shared/IMenuSettings";
import classes from "./SidebarMenu.module.css";
import cx from 'clsx';
import {NavLink} from "@mantine/core";

export default function SidebarMenuLevel({elem, optionsOnClick, nestLvl}: IProps) {
    const indentClass = nestLvl === 0 ? ""
        : (nestLvl === 1 ? classes.sidebarOptionsBody1
            : classes.sidebarOptionsBody2);

    function onClick() {
        if ((elem?.options?.length ?? 0) === 0) {
            optionsOnClick(elem)
        }
    }

    return <>
        {nestLvl < 3 &&
            <NavLink label={elem.title}
                     onClick={onClick}
                     className={cx(classes.borderItem, classes.sidebarOptionsItem, indentClass, classes.interFont)}
                     childrenOffset={0} p={'0 24px'}>

                {(elem.options && elem.options?.length > 0) &&
                    elem.options.map(item =>
                        <SidebarMenuLevel key={item.id + item.order + item.title}
                                          elem={item}
                                          optionsOnClick={optionsOnClick}
                                          nestLvl={nestLvl + 1}
                        />
                    )}
            </NavLink>}
    </>
}

interface IProps {
    elem: IMenuOptions;
    optionsOnClick: CallableFunction;
    nestLvl: number;
}
