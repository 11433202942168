import React, {createContext} from 'react';
import {CSSVariablesResolver} from "@mantine/core/lib";
import {Box, createTheme, MantineProvider} from '@mantine/core';
import {ModalsProvider} from "@mantine/modals";
import '@mantine/core/styles.css';

import {GlobalStore} from "./stores/GlobalStore";
import Page from './layout/Page';
import "./utils/i18n";
import {
    breakpointLG,
    breakpointMD,
    breakpointSM,
    breakpointXL,
    breakpointXS,
    breakpointXSDefault,
    buttonOutlineColor,
    buttonTextTransparentColor,
    contentButtonActiveColor,
    contentButtonBackgroundColor,
    contentButtonDisabledColor,
    contentButtonHoverColor,
    contentMaxHeight,
    contentMaxWidth,
    defaultBackgroundContentDarkColor,
    defaultBackgroundDarkColor,
    defaultContentTitleColor,
    headerButtonBackgroundColor,
    headerCopiedButtonBackgroundColor,
    headerTourNameTextColor,
    switcherBackgroundActiveColor,
    switcherBackgroundColor,
    switcherIconDefaultColor,
    switcherIconHoverColor,
} from "./vars";
import classes from './App.module.css';

export const globalContext = createContext(new GlobalStore());

const themeOverride = createTheme({
    fontFamily: 'Open Sans',
    other: {
        maxWidth: contentMaxWidth,
        maxHeight: contentMaxHeight,
        defaultBackgroundDarkColor,
        headerButtonBackgroundColor,
        buttonTextTransparentColor,
        headerCopiedButtonBackgroundColor,
        headerTourNameTextColor,
        defaultBackgroundContentDarkColor,
        defaultContentTitleColor,
        buttonOutlineColor,
        contentButtonBackgroundColor,
        contentButtonHoverColor,
        contentButtonActiveColor,
        contentButtonDisabledColor,
        switcherBackgroundActiveColor,
        switcherBackgroundColor,
        switcherIconDefaultColor,
        switcherIconHoverColor,
    },
    breakpoints: {
        xs: breakpointXS,
        xsDefault: breakpointXSDefault,
        sm: breakpointSM,
        md: breakpointMD,
        lg: breakpointLG,
        xl: breakpointXL,
    },
});

const resolver: CSSVariablesResolver = (theme) => ({
    variables: {
        '--mantine-virtik-content-max-width': theme.other.maxWidth,
        '--mantine-virtik-content-max-height': theme.other.maxHeight,
        '--mantine-virtik-default-background-dark-color': theme.other.defaultBackgroundDarkColor,
        '--mantine-virtik-header-button-background-color': theme.other.headerButtonBackgroundColor,
        '--mantine-virtik-button-text-transparent-color': theme.other.buttonTextTransparentColor,
        '--mantine-virtik-button-outline-color': theme.other.buttonOutlineColor,
        '--mantine-virtik-header-copied-button-background-color': theme.other.headerCopiedButtonBackgroundColor,
        '--mantine-virtik-header-tour-name-text-color': theme.other.headerTourNameTextColor,
        '--mantine-virtik-default-background-content-dark-color': theme.other.defaultBackgroundContentDarkColor,
        '--mantine-virtik-default-content-title-color': theme.other.defaultContentTitleColor,
        '--mantine-virtik-content-button-background-color': theme.other.contentButtonBackgroundColor,
        '--mantine-virtik-content-button-hover-color': theme.other.contentButtonHoverColor,
        '--mantine-virtik-content-button-active-color': theme.other.contentButtonActiveColor,
        '--mantine-virtik-content-button-disabled-color': theme.other.contentButtonDisabledColor,
        '--mantine-virtik-switcher-background-active-color': theme.other.switcherBackgroundActiveColor,
        '--mantine-virtik-switcher-background-color': theme.other.switcherBackgroundColor,
        '--mantine-virtik-switcher-icon-default-color': theme.other.switcherIconDefaultColor,
        '--mantine-virtik-switcher-icon-hover-color': theme.other.switcherIconHoverColor,
    },
    light: {},
    dark: {},
});

function App() {
    return (
        <MantineProvider theme={themeOverride} cssVariablesResolver={resolver}>
            <ModalsProvider>
                <Box className={classes.appBody}>
                    <Page/>
                </Box>
            </ModalsProvider>
        </MantineProvider>
    );
}

export default App;
