import {useForm, isNotEmpty, hasLength} from '@mantine/form';
import {Button, Group, TextInput, Box, PasswordInput} from '@mantine/core';
import {useContext} from "react";
import {globalContext} from "../../App";

export default function LoginForm() {
  const globalStore = useContext(globalContext);

  const form = useForm({
    initialValues: {
      login: '',
      password: '',
    },

    validate: {
      login: hasLength({min: 2, max: 50}, 'Name must be 2-50 characters long'),
      password: isNotEmpty('Enter your Password'),
    },
  });

  async function firebaseLogin(params: any) {
    const response = await globalStore.userStore.login(params);

    if (response.status) {
      alert('Congrats! You are logged in!');
    } else {
      alert(response.error);
    }
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!form.validate().hasErrors) {
      firebaseLogin(form.values);
    }
  }

  return (
    <Box component="form" maw={400} mx="auto" onSubmit={handleSubmit}>
      <TextInput label="Login" placeholder="Login" withAsterisk {...form.getInputProps('login')} />
      <PasswordInput
        label="Password"
        placeholder="Password"
        withAsterisk
        mt="md"
        {...form.getInputProps('password')}
      />
      <Group justify="flex-end" mt="md">
        <Button type="submit">Submit</Button>
      </Group>
    </Box>
  );
}
