import React from 'react';
import { Box, Button, Textarea } from '@mantine/core';
import { useForm } from "@mantine/form";

export default function UploaderFile() {
  const form = useForm({
    initialValues: {
      file: '',
      jsonContent: '',
    },
  });
  const handleSubmit = (event: any) => {
    event.preventDefault();
    // let formdata = new FormData();

// console.log(form.values.jsonContent);
    // formdata.append(
    //   "file",
    //   "C:\\Users\\boome\\Downloads\\Screenshot_2.png"
    // );
    // const json = {
    //   "projectName": "Project 1",
    //   "projectDescription": " IHIGFkjdshfh sdfjhs jdkfh",
    //   "authToken": "hdfhjkghdfjghdfjkhg",
    //   "templateName": "Template 1",
    //   "userName": "Misha",
    //   "privacy": "Public"
    // };

    // @ts-ignore
    fetch("http://127.0.0.1:5001/virtik-virtual-tour-software/us-central1/createProject", {
    // fetch("https://us-central1-virtik-virtual-tour-software.cloudfunctions.net/createProject", {
      method: "POST",
      body: form.values.jsonContent,
      // body: formdata,
      // mode: 'no-cors',
      // headers: {
      //   'Origin': 'http://localhost:3333',
      //   'Content-type': 'application/json',
      // },
    })
      .then(function (response) {
        console.log(response);
        return response.text();
      })
      .then(function (data) {
        console.log(data);
      });
  }

  return (
    <div>
      <Box component="form" maw={400} mx="auto" onSubmit={handleSubmit}>
        <div>
          <Textarea
            {...form.getInputProps('jsonContent')}
            placeholder="Insert your JSON content"
            label="JSON content"
            autosize
            minRows={5}
          />
          {/*<textarea {...form.getInputProps('jsonContent')} placeholder="Insert your JSON content"></textarea>*/}
        </div>
        {/*<FileInput*/}
        {/*    placeholder="Pick file"*/}
        {/*    label="Your file"*/}
        {/*    {...form.getInputProps('file')}*/}
        {/*/>*/}
        <div>
          <Button type="submit">Send File</Button>
        </div>
      </Box>
      {/*<form action="https://us-central1-virtik-virtual-tour-software.cloudfunctions.net/checkJson" method="post" encType="multipart/form-data">*/}
      {/*  <input type="file" name="file" />*/}
      {/*  <Button type="submit">Submit to Cloud</Button>*/}
      {/*</form>*/}
    </div>
  )
}
