import {THREE} from "aframe";
import {validateFont, validateFontSize, validateText} from "./hotspot-text-utils";
import {createSprite, drawTextureOnCanvas} from "./draw-text-sprites";
import {IHotspotVisualDataText} from "../../../models/shared/IHotspotVisual";

AFRAME.registerComponent('virtik-hotspot-text', {
    schema: {
        textVisualData: {type: 'string', default: ''},
        selector: {type: 'string', default: ''},
    },
    init: async function () {
        const hotspotEntity = this.el as typeof AFRAME.AEntity;
        const spriteParentEntity: typeof AFRAME.AEntity = document.createElement('a-entity') as typeof AFRAME.AEntity
        const hotspotContainer = hotspotEntity.parentElement

        const textOptions: IHotspotVisualDataText = JSON.parse(this.data.textVisualData)

        if (!textOptions) {
            console.error("Text options was not provided")
            return
        }
        console.log(textOptions)
        const message = validateText(textOptions.text);
        const fontFace = await validateFont(textOptions.font);
        const fontSize = validateFontSize(textOptions.fontSize);
        drawTextureOnCanvas(textOptions, message, fontSize, fontFace).then(canvas =>{
            const texture = new THREE.CanvasTexture(canvas)

            createSprite(texture, canvas, spriteParentEntity)
            if (hotspotContainer){
                hotspotContainer.removeAttribute('geometry')
                hotspotContainer.setAttribute('geometry', `primitive: plane; height: ${canvas.height / 500}; width: ${canvas.width / 400}`)
                hotspotContainer.setAttribute('scale', '4 4 4')// Scale equalization factor with Editor
            }
            hotspotEntity.appendChild(spriteParentEntity)
            hotspotEntity.setAttribute('position', '0 -0.05 0') //TODO need to understand why plane needs to be down shifted
        });
    }
});

AFRAME.registerPrimitive('a-virtik-hotspot-text', {
    defaultComponents: {
        'virtik-item': {type: 'hotspot-text'},
        'virtik-hotspot-text': {}
    },
    mappings: {
        'width': 'virtik-item.width',
        'height': 'virtik-item.height',
        'margin': 'virtik-item.margin',
        'visual-data': 'virtik-hotspot-text.textVisualData',
        'selector': 'virtik-hotspot-text.selector',
    },
});



