import {Box, Button, Image, Slider} from "@mantine/core";
import cx from 'clsx';
import {useContext, useEffect, useRef} from "react";
import {useHotkeys, useIdle} from "@mantine/hooks";
import classes from "./Scenes.module.css";
import sliderStyles from "./VideoSlider.module.css";
import play from '../../../assets/images/play.png'
import pause from '../../../assets/images/pause.png'
import rotate from '../../../assets/images/rotate.png'
import {globalContext} from "../../../App";

export default function VideoSlider(props: IProps) {
    const idle = useIdle(3000, {initialState: false});
    const trackerRef = useRef<HTMLDivElement>(null)
    const global = useContext(globalContext);
    const tourStore = global.tourStore;

    useEffect(() => {
        if(!trackerRef.current) return;
        const sliderTrack: HTMLDivElement = trackerRef.current!.querySelector('div.mantine-Slider-track')!
        sliderTrack.style.setProperty('--virtik-video-buffer-size', props.bufferSize + '%')
    }, [props.bufferSize]);

    useHotkeys([
        ['ArrowLeft', () => {
            props.backwardStep()
        }],
        ['Left', () => {
            props.backwardStep()
        }], // Edge (16 and earlier) and Firefox (36 and earlier) use "Left"  instead of "ArrowLeft"
        ['ArrowRight', () => {
            props.forwardStep()
        }],
        ['Right', () => {
            props.forwardStep()
        }], // Edge (16 and earlier) and Firefox (36 and earlier) use "Right"  instead of "ArrowRight"
    ]);

    return (
        <Box maw={props.isMinWidth ? '950px' : '1070px'}
             w="100%"
             mx="auto"
             className={cx(
                 classes.videoRangeControls,
                 (props.isPlay && tourStore.mode360TourSettings.hideIdleVideoControls && idle) ? classes.videoHiddenControls : ''
             )}>

            {(!props?.isHotspotMode || (props?.isHotspotMode && props.isPlay)) &&
                <>
                    <Button className={classes.roundButton}
                            onClick={props.togglePlay}>
                        {props.isPlay ? <Image src={pause}/> : <Image src={play}/>}
                    </Button>

                    <Button className={classes.roundButton}
                            onClick={() => {
                                props.rotateVideo()
                            }}>
                        <Image src={rotate}/>
                    </Button>

                    <Slider defaultValue={0}
                            ref={trackerRef}
                            classNames={{track: sliderStyles.track}}
                            value={props.rangeValue}
                            color={tourStore.getColorUiScheme()}
                            label={null}
                            onClick={(event) => {
                                props.setVideoCurrentTime(event)
                            }}
                            onChange={props.updateRangeValue}
                            onChangeEnd={props.updateRangeValue}
                            step={0.01}
                            max={props.maxVideoDuration}
                            min={0}/>
                </>
            }
        </Box>
    );
}

interface IProps {
    bufferSize: number;
    isMinWidth: boolean;
    isPlay: boolean;
    rangeValue: number;
    maxVideoDuration: number;
    backwardStep: Function;
    forwardStep: Function;
    rotateVideo: Function;
    setVideoCurrentTime: Function;
    togglePlay: (event?) => void | Function;
    updateRangeValue: (value: number) => void;
    isHotspotMode?: boolean;
}
