import {Box, Center, Title} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import cx from 'clsx';
import {useZoomControls} from "../hooks/useZoomControls";
import sceneStyles from "../view/Scenes.module.css";
import ZoomControls from "../view/ZoomControls";
import {Logo360} from "../view/Logo360";
import modalStyles from "./ModalHotspots.module.css";
import CustomOverlay from "../view/CustomOverlay";

const ModalHotspotsPicture360 = observer(({data}: any) => {
    const cameraId: string = 'imageTourHotspotCamera';
    const {
        disabledZoomIn, disabledZoomOut,
        toggleZoomIn, toggleZoomOut,
        addZoomListener, removeZoomListener,
    } = useZoomControls(cameraId)

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const element = document.getElementById('imageHotspot')!;
        element.setAttribute('src', data.fileLink);
        addZoomListener('hotspotImageScene')

        element.onload = function () {
            setIsLoading(false);
            const elementSky: any = document.getElementById('skyTourHotspot');
            elementSky.setAttribute('src', "#imageHotspot");
        };
        element.onerror = function () {
            setError(true);
            setIsLoading(false);
        };

        return function cleanup() {
            removeZoomListener("hotspotImageScene");
        };
    }, []);

    return (<>
            {error
                ? <Center><Title mt={200} c={"red"} order={3}>Error while loading picture</Title></Center>
                : <Box className={modalStyles.modalImage360} id="image360HotspotBehaviour">
                    <CustomOverlay visible={isLoading}></CustomOverlay>

                    <a-scene device-orientation-permission-ui="enabled: false"
                             embedded={"true"}
                             vr-mode-ui="enabled: false"
                             id="hotspotImageScene"
                             scale="1 1 1">
                        <a-assets id="hotspotSceneAssets" timeout="100000">
                            <img id="imageHotspot"
                                 crossOrigin="anonymous"
                                 alt=''/>
                        </a-assets>

                        <a-entity id={cameraId}
                                  camera='active: true; zoom: 1;'
                                  look-controls="reverseMouseDrag: true; magicWindowTrackingEnabled: false">
                        </a-entity>

                        <a-sky id="skyTourHotspot"/>
                    </a-scene>

                    {!isLoading &&
                        <>
                            <Logo360/>

                            <Box className={cx(sceneStyles.sceneControls, sceneStyles.leftSceneControls, sceneStyles.hotspotZoomControls)}>
                                <ZoomControls toggleZoomIn={toggleZoomIn}
                                              toggleZoomOut={toggleZoomOut}
                                              disabledZoomIn={disabledZoomIn}
                                              disabledZoomOut={disabledZoomOut}/>
                            </Box>
                        </>
                    }
                </Box>
            }
        </>);
})

export default ModalHotspotsPicture360
