
const minFontSize = 10
const maxFontSize = 128
const maxTextLength = 20
const fonts = ["Arial", "Inter", "Montserrat", "Roboto", "Times New Roman"];
export function measureText(message: string, fontSize: number, font: FontFace): number {
    const canvas = document.createElement('canvas') as any;
    canvas.height = fontSize * 1.4
    canvas.width = 1920

    const context = canvas.getContext('2d');
    context.fillStyle = "rgb(255,235,7)";
    context.font = `${font.style} ${fontSize}px ${font.family}`;

    const metrics = context.measureText(message);
    const textWidth = metrics.width;

    if (textWidth){
        return textWidth
    }else {
        console.error("Something wrong with text measurement")
        return 0
    }
}
export function downloadImgFromCanvas(canvas: HTMLCanvasElement){
    const dataURL = canvas.toDataURL("image/jpeg");
    const link = document.createElement("a") as any;
    link.href = dataURL;
    link.download = "image-from-canvas.jpg";
    link.click();
}
export function validateText(text: string): string {
    return text.length > maxTextLength ? text.slice(0, maxTextLength) : text
}

export function validateFontSize(fontSize: number): number {
    return fontSize < minFontSize
        ? minFontSize
        : (
            fontSize > maxFontSize
                ? maxFontSize
                : fontSize
        )
}

export async function validateFont(font: string): Promise<string> {
    if (fonts.includes(font)) {
        await new FontFace(font, `url("/fonts/${font}.ttf")`, {}).load()
        return font
    } else {
        return 'Inter'
    }
}
