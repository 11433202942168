if (typeof AFRAME === 'undefined') {
    throw new Error('Component attempted to register before AFRAME was available.');
}

require('../aframe-components/third-party/aframe-troika-text.js');
require('../aframe-components/item');
require('../aframe-components/hotspot-polygon');
require('../aframe-components/flex-container');
require('../aframe-components/text-container');
require('../aframe-components/hotspot-button');
require('../aframe-components/hotspot-text');
require('../aframe-components/label');
require('../aframe-components/image-scene');


export {}
