import React from "react";
import cx from 'clsx';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./switcher.css";
import classes from "./Plan2DSwitcher.module.css";

const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={cx(classes.arrowSlide, classes.arrowLeft, className)}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
        </div>
    );
};
const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={cx(classes.arrowSlide, classes.arrowRight, className)}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
        </div>
    );
}

export const slider2DSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    draggable:false,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    onReInit: (s) => {
        const slider = document.querySelector('.slick-slider');

        if (window.innerWidth > 1040 && window.innerWidth < 1131) {
            slider.style.width = 7 * 115 + 'px';
        } else if(window.innerWidth > 910 && window.innerWidth < 1041) {
            slider.style.width = 6 * 115 + 'px';
        } else if(window.innerWidth > 810 && window.innerWidth < 911) {
            slider.style.width = 5 * 115 + 'px';
        } else if(window.innerWidth > 691 && window.innerWidth < 811) {
            slider.style.width = 4 * 115 + 'px';
        } else if(window.innerWidth > 581 && window.innerWidth < 691) {
            slider.style.width = 3 * 115 + 'px';
        } else if(window.innerWidth > 490 && window.innerWidth < 581) {
            slider.style.width = 2 * 115 + 'px';
        } else if(window.innerWidth > 375 && window.innerWidth < 491) {
            slider.style.width = 115 + 'px';
        } else if(window.innerWidth <= 375) {
            slider.style.width = 115 + 'px';
        } else {
            slider.style.width = 8 * 115 + 'px';
        }
    },
    responsive: [
        {
            breakpoint: 8500,
            settings: {
                slidesToShow: 8
            }
        },
        {
            breakpoint: 1920,
            settings: {
                slidesToShow: 8
            }
        },
        {
            breakpoint: 1130,
            settings: {
                slidesToShow: 7
            }
        },
        {
            breakpoint: 1040,
            settings: {
                slidesToShow: 6
            }
        },
        {
            breakpoint: 910,
            settings: {
                slidesToShow: 5
            }
        },
        {
            breakpoint: 810,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 691,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 581,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 490,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 375,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};
