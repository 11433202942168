import {useContext, useState} from 'react';
import {globalContext} from "../../../App";
import {defaultCameraRotation, videoStep} from "../../../vars";
import {playMediaSafely} from "../utils/AudioVideoUtils";

export function useVideoControls() {
    const global = useContext(globalContext);
    const sceneStore = global.sceneStore;
    const stateStore = global.stateStore;
    const [isPlay, setIsPlay] = useState<boolean>(false);
    const [rangeValue, setRangeValue] = useState<number>(stateStore.state.videoLastTime!);

    function resetVideoElem() {
        if (sceneStore.currentVideoElement) {
            sceneStore.currentVideoElement.ontimeupdate = null
            sceneStore.currentVideoElement.onprogress = null
            sceneStore.currentVideoElement.onloadstart = null
        }

        stateStore.updateAndSave({
            videoLastTime: 0,
            rotation: defaultCameraRotation
        }, 'video controls hook reset')
    }

    function initVideoElem(elem: HTMLVideoElement) {
        if (elem) {
            sceneStore.setCurrentVideoElement(elem)
        }
    }

    function initCustomAudioElem(elem: HTMLAudioElement | null) {
        if (elem) {
            sceneStore.setCurrentAudioElement(elem)
        }
    }

    function togglePlay(clickEvent = null) {
        if (clickEvent) {
            if (isPlay) {
                sceneStore.currentVideoElement.pause()
                sceneStore.currentAudioElement?.src && sceneStore.currentAudioElement.pause()
            } else {
                playMediaSafely(sceneStore.currentVideoElement)
                sceneStore.currentAudioElement?.src && playMediaSafely(sceneStore.currentAudioElement)
            }

            setIsPlay(!isPlay)
        } else {
            sceneStore.currentVideoElement.pause();

            if (sceneStore.currentAudioElement?.src) {
                sceneStore.currentAudioElement.pause()

                if (sceneStore.currentVideoElement.currentTime === sceneStore.currentVideoElement.duration) {
                    sceneStore.currentAudioElement.currentTime = 0;
                }
            }

            setIsPlay(false)
        }
    }

    function backwardStep() {
        sceneStore.currentVideoElement.currentTime = sceneStore.currentVideoElement.currentTime - videoStep;

        if (sceneStore.currentAudioElement?.src) {
            sceneStore.currentAudioElement.currentTime = sceneStore.currentAudioElement.currentTime - videoStep;
        }
    }

    function forwardStep() {
        sceneStore.currentVideoElement.currentTime = sceneStore.currentVideoElement.currentTime + videoStep;

        if (sceneStore.currentAudioElement?.src) {
            sceneStore.currentAudioElement.currentTime = sceneStore.currentAudioElement.currentTime + videoStep;
        }
    }

    function resetVideoRotation() {
        // no matching type due to Aframe issue
        const cameraEl: any = document.getElementById('customCamera');
        const lookControls = cameraEl.components['look-controls'];
        lookControls.pitchObject.rotation.set(0, 0, 0);
        lookControls.yawObject.rotation.set(0, 0, 0);
        lookControls.updateOrientation();
        updateRangeValue(0);
        sceneStore.currentVideoElement.currentTime = 0;
        sceneStore.currentAudioElement.currentTime = 0;
        togglePlay()
    }

    function setVideoCurrentTime() {
        sceneStore.currentVideoElement.currentTime = Number(rangeValue.toFixed(2));
        sceneStore.currentAudioElement?.src && (sceneStore.currentAudioElement.currentTime = Number(rangeValue.toFixed(2)))
    }

    function updateRangeValue(val) {
        const newCurrentTime = Number(val.toFixed(2));

        if (Number(sceneStore.currentVideoElement.currentTime.toFixed(2)) !== newCurrentTime) {
            sceneStore.currentVideoElement.currentTime = newCurrentTime > sceneStore.videoDuration ? sceneStore.videoDuration : newCurrentTime;

            if (sceneStore.currentAudioElement?.src) {
                sceneStore.currentAudioElement.currentTime = newCurrentTime > sceneStore.videoDuration ? sceneStore.videoDuration : newCurrentTime;
            }
        }

        setRangeValue(val)
    }

    return {
        isPlay, togglePlay,
        rangeValue,
        updateRangeValue,
        backwardStep,
        forwardStep,
        resetVideoRotation,
        initVideoElem,
        initCustomAudioElem,
        setVideoCurrentTime,
        resetVideoElem
    };
}
