export function flushToDom() {
    if (AFRAME.scenes.length > 0) {
        AFRAME.scenes[0].flushToDOM(true);
    }
}

// function getCursorPosition(canvas, event) {
//     const rect = canvas.getBoundingClientRect()
//     const x = event.clientX - rect.left
//     const y = event.clientY - rect.top
//     console.log("x: " + x + " y: " + y)
// }