import {compareObject} from "./Objects";
import {defaultCameraRotation} from "../../../vars";
import {ICameraData, IVec3} from "../../../models/shared/IProjectBase";

// TODO попитка повернуть камеру !!!
export function updateCameraAngle(lookControls: any, x = 0, y = 0) {
    lookControls.pitchObject.rotation.set(x, 0, 0);
    lookControls.yawObject.rotation.set(0, y, 0);
    lookControls.updateOrientation();
}

export function getInitCameraRotation(lookControls: any, cameraData: ICameraData, rotationFromStorage: any) {
    let x, y;

    if (cameraData?.initialRotation && compareObject(rotationFromStorage, defaultCameraRotation)) {
        x = cameraData.initialRotation.x
        y = cameraData.initialRotation.y
    } else {
        x = rotationFromStorage.x;
        y = rotationFromStorage.y;
    }

    return {
        x,
        y,
        z: 0
    }
}

export function getCameraRotation(lookControls: any) {
    return {
        x: parseFloat(lookControls.pitchObject.rotation._x.toFixed(2)),
        y: parseFloat(lookControls.yawObject.rotation._y.toFixed(2)),
        z: 0
    }
}
