import {THREE} from "aframe";
import {HotspotBehaviourAudio, HotspotBehaviourType} from "../../../models/shared/HotspotBehaviour";
import {IHotspot, IVec3} from "../../../models/shared/IProjectBase";
import {DoubleSide, Vector3} from "three";

const minBorderSize = 1
const maxBorderSize = 40
const defaultBorderSize = 40
const delimiterBorderSizeToBorderThickness = 100

export function prepareAudioHotspots(hotspots: IHotspot[]) {
    if (!hotspots || hotspots.length === 0) {
        return []
    }

    return hotspots
        .filter(item => item.hotspotData.behaviourType === HotspotBehaviourType.audio)
        .map(item => ({
            ...(item.hotspotData.behaviourData as HotspotBehaviourAudio),
            name: item.hotspotData.name.toLowerCase().replace(/\s/g, '')
        }))
}

export function transformCoordinate(position: IVec3): IVec3 {
    return {x: position.z, y: position.y, z: position.x}
}

export function vec3ToString(p: IVec3): string {
    return Object.values(p).join(' ')
}

export function radToDeg(rad: number): number {
    return rad * (180 / Math.PI)
}

export function validateOpacity(opacity: number): number {
    return ((opacity > 0 || opacity === 0) && (opacity < 1 || opacity === 1)) ? opacity : 1
}

export function validateColor(color: string, defaultColor: string): string {
    const colorData = new THREE.Color(color ? color : defaultColor)

    return '#' + colorData.getHexString();
}

export function validateBorderThickness(size: number = 0): number {
    const borderThickness =  ((size > minBorderSize || size === minBorderSize) && (size < maxBorderSize || size === maxBorderSize)) ? size : defaultBorderSize;
    return borderThickness / delimiterBorderSizeToBorderThickness
}

export function createDebugSphere(sceneEl, coords: Vector3, color: string | number = 0xffff00): THREE.Mesh {
    const geometry = new THREE.SphereGeometry( 0.025, 32, 16 );
    const material = new THREE.MeshBasicMaterial( { color: color, side: DoubleSide } );
    const sphere = new THREE.Mesh( geometry, material );
    sphere.position.set(coords.x, coords.y, coords.z)
    sceneEl.object3D.add(sphere)
    return sphere
}
export function HEXToRGB(hex: string){
    let c: any;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return {
            r: +(c>>16)&255,
            g: +(c>>8)&255,
            b: +c&255
        }
    }
    throw new Error('Bad Hex');
}
