import {Box, Burger, Drawer, Group, AppShell, Image, Text} from '@mantine/core';
import LogoVirtik from "../assets/images/logo_virtik.svg";
import {useDisclosure} from '@mantine/hooks';
import React, {useContext} from "react";
import {observer} from "mobx-react-lite";

import {globalContext} from "../App";
import CopyButtonElement from "./CopyButtonElement";
import LoginButtons from "./LoginButton";
import headerClasses from "./Header.module.css";
import cx from 'clsx';

const HeaderMegaMenu = observer(() => {
        const [drawerOpened, {toggle: toggleDrawer, close: closeDrawer}] = useDisclosure(false);
        const globalStore = useContext(globalContext);
        const tourName = globalStore.tourStore.projectName;
        const headerHeight: number = 60;
        const linkToCopy: string = window.location.href;
        const loadCopyButton: boolean = !!(tourName && tourName !== '');

        return (
            <Box h={headerHeight}>
                <AppShell header={{ height: headerHeight }}>
                    <AppShell.Header className={headerClasses.headerBackgroundColor}>
                        <Group justify="space-between"
                               gap={'xs'}
                               wrap="nowrap"
                               className={headerClasses.headerCenter}>
                            <Box>
                                <Box className={headerClasses.projectContainerLogo}>
                                    <Image fit={"cover"}
                                           onClick={() => {
                                               window.location.assign("/");
                                           }}
                                           src={LogoVirtik}
                                           className={headerClasses.projectLogo}/>
                                </Box>
                            </Box>

                            <Box visibleFrom="xsDefault">
                                <Box className={headerClasses.projectNameContainer}>
                                    <Text className={cx(headerClasses.projectName, headerClasses.projectNameHeader)} title={tourName}>
                                        {tourName}
                                    </Text>
                                </Box>
                            </Box>

                            <Box visibleFrom="xsDefault" className={headerClasses.headerButtonContainer}>
                                {loadCopyButton &&
                                    <CopyButtonElement value={linkToCopy}/>
                                }

                                <LoginButtons/>
                            </Box>

                            <Burger className={headerClasses.burger}
                                    hiddenFrom="xsDefault"
                                    opened={drawerOpened}
                                    onClick={toggleDrawer}/>
                        </Group>
                    </AppShell.Header>
                </AppShell>

                <Drawer.Root opened={drawerOpened}
                             onClose={closeDrawer}
                             size="100%"
                             hiddenFrom="xsDefault"
                             zIndex={200}>
                    <Drawer.Overlay/>

                    <Drawer.Content className={headerClasses.headerDrawerBackgroundColor}>
                        <Drawer.Header className={headerClasses.headerDrawerBackgroundColor}>
                            <Text className={cx(headerClasses.projectName, headerClasses.projectNameDrawer)}>
                                {tourName}
                            </Text>

                            <Drawer.CloseButton/>
                        </Drawer.Header>

                        <Drawer.Body>
                            {loadCopyButton &&
                                <CopyButtonElement copyButtonClassName={headerClasses.drawerCopyButton} value={linkToCopy}/>
                            }

                            <LoginButtons drawerClassName={headerClasses.drawerButtonContainer}/>
                        </Drawer.Body>
                    </Drawer.Content>
                </Drawer.Root>
            </Box>
        );
    }
)

export default HeaderMegaMenu;
