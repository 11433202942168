// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "Sign in": "Sign in",
      "Sign up": "Sign up",
      "Sign out": "Sign out",
      "Virtual experience": "Virtual experience",
    }
  },
  fr: {
    translation: {
      "Welcome to React": "Bienvenue à React et react-i18next",
      "Sign in": "Connectez-vous",
      "Sign up": "Inscrivez-vous",
      "Sign out": "Se déconnecter",
      "Virtual experience": "Expérience virtuelle",
    }
  }
};
