import {Box, Center, Image, Title} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import classes from "./ModalHotspots.module.css";
import CustomOverlay from "../view/CustomOverlay";

const ModalHotspotsPicture = observer(({data}: any) => {
    const [imageLoading, setImageLoading] = useState<boolean>(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const element = document.getElementById('imageHotspotBehaviour')!;
        element.setAttribute('src', data.fileLink);

        element.onload = function () {
            setImageLoading(false);
        };
        element.onerror = function () {
            setError(true);
            setImageLoading(false);
        };
    }, []);

    return (
        <Box className={classes.modalImage}>
            <CustomOverlay visible={imageLoading}></CustomOverlay>
            {error
                ? <Center><Title mt={200} c={"red"} order={3}>Error while loading picture</Title></Center>
                : <Image fit={"contain"} id="imageHotspotBehaviour" className={classes.modalImage} alt=""/>
            }
        </Box>
    );
})

export default ModalHotspotsPicture
