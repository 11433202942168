import {useState} from 'react';
import {videoStep} from "../../../vars";
import {playMediaSafely} from "../utils/AudioVideoUtils";

export function useHotspotVideoControls() {
    const [isPlay, setIsPlay] = useState<boolean>(false);
    const [videoEl, setVideoEl] = useState<any>(null);
    const [videoDuration, setVideoDuration] = useState<any>(null);
    const [rangeValue, setRangeValue] = useState<number>(0);
    const [bufferSize, setBufferSize] = useState<number>(0)
    const [isLoadListeners, setIsLoadListeners] = useState<boolean>(false)

    function initHotspotVideoElem(elem: HTMLVideoElement) {
        if (elem) {
            setVideoEl(elem)
            setVideoDuration(elem.duration)
        }
    }

    function togglePlay(event = null, isHotspotArea: boolean = false) {
        // @ts-ignore
        event && event.stopPropagation()

        !isLoadListeners && loadListeners()

        if (event) {
            playPauseVideoEl()
            setIsPlay(!isPlay)
        } else {
            if (isHotspotArea) {
                playPauseVideoEl()
                setIsPlay(!isPlay)
            } else {
                videoEl.pause();
                setIsPlay(false)
            }
        }
    }

    function playPauseVideoEl() {
        if (isPlay) {
            videoEl.pause()
        } else {
            playMediaSafely(videoEl)
        }
    }

    function resetVideoRotation() {
        // no matching type due to Aframe issue
        const cameraEl: any = document.getElementById('video360HotspotCamera');

        if (!cameraEl) return;

        const lookControls = cameraEl.components['look-controls'];
        lookControls.pitchObject.rotation.set(0, 0, 0);
        lookControls.yawObject.rotation.set(0, 0, 0);
        lookControls.updateOrientation();
        restart();
    }

    function restart() {
        !isLoadListeners && loadListeners()
        updateRangeValue(0);
        videoEl.currentTime = 0;
        togglePlay()
        playMediaSafely(videoEl)
        setIsPlay(true)
    }

    function setVideoCurrentTime(event: any = null) {
        event && event.stopPropagation()
        videoEl.currentTime = Number(rangeValue.toFixed(2));
    }

    function updateRangeValue(val) {
        const newCurrentTime = Number(val.toFixed(2));

        if (Number(videoEl.currentTime.toFixed(2)) !== newCurrentTime) {
            videoEl.currentTime = newCurrentTime > videoDuration ? videoDuration : newCurrentTime;
        }

        setRangeValue(val)
    }

    function loadListeners() {
        setIsLoadListeners(true)
        videoEl.onended = function (event) {
            togglePlay()
        };

        videoEl.onprogress = function (event) {
            if (videoDuration > 0) {
                if (videoEl.buffered.length > 0) {

                    for (let i = 1; i <= videoEl.buffered.length; i++) {
                        const bufValue = (100 * (videoEl.buffered.end(i - 1)) / videoDuration);
                        setBufferSize(parseInt(bufValue.toFixed(2)));
                    }
                }
            }
        };

        videoEl.ontimeupdate = function (event) {
            const timeVideo = videoDuration / (videoDuration / videoEl.currentTime);
            const newRangeValue = Math.round((timeVideo + Number.EPSILON) * 100) / 100;
            updateRangeValue(newRangeValue);
        };
    }

    function backwardStep() {
        videoEl.currentTime = videoEl.currentTime - videoStep;
    }

    function forwardStep() {
        videoEl.currentTime = videoEl.currentTime + videoStep;
    }

    return {
        initHotspotVideoElem,
        togglePlay, isPlay,
        rangeValue, updateRangeValue,
        backwardStep, forwardStep,
        videoDuration,
        resetVideoRotation, restart,
        setVideoCurrentTime,
        bufferSize,
    };
}
