import {Anchor, Box, List, Text} from '@mantine/core';
import classes from "./Footer.module.css";
import {withTranslation} from "react-i18next";
import Moment from "moment/moment";
import cx from 'clsx';

function FooterCentered({t}) {
    const links = [
        { link: '#', label: 'Terms Of Service' },
        { link: '#', label: 'Conditions Privacy Policy' },
        { link: '#', label: 'Distribution Agreement' },
        { link: '#', label: 'FAQ' },
        { link: '#', label: 'Support' },
        { link: '#', label: 'About' },
    ];
    const items = links.map((link) => (
        <List.Item key={link.label} className={classes.footerLinksLi}>
            <Anchor href={link.link}
                    onClick={(event) => event.preventDefault()}
                    size="sm">
                {t(link.label)}
            </Anchor>
        </List.Item>
    ));

    return (
        <Box className={classes.footer}>
            <Box className={classes.inner}>
                <List className={classes.footerLinksUl} listStyleType={"none"}>
                    {items}
                </List>
            </Box>

            <Box className={cx(classes.inner, classes.footerText)}>
                <Text ta="center">
                    {t(`All rights reserved. © ${Moment().year()} Filmatik`)}
                </Text>
            </Box>
        </Box>
    );
}

export default withTranslation()(FooterCentered)
