import {firebaseConfig} from "../cfg/FirebaseCfg";

class ApiEnvironmentSwitcher {
    static getApiPath() {
        if (firebaseConfig.isLocal) {
            return firebaseConfig.localCloudFunctionsURL;
        } else {
            return firebaseConfig.cloudFunctionsURL;
        }
    }
}

export default ApiEnvironmentSwitcher;
