import {useEffect, useState} from 'react';
import {mobileDeviceMaxWidth} from "../../../vars";

export function useWindowDimensions() {
    // eslint-disable-next-line no-restricted-globals
    const [orientation, setOrientation] = useState(isIos() ? getScreenOrientation() : window.screen?.orientation?.type)
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerWidth);
    type ScreenOrientation = "landscape-primary" | "landscape-secondary" | "portrait-primary" | "portrait-secondary";

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange, true);
        window.addEventListener("orientationchange", getTypeOrientation, true);

        window.dispatchEvent(new Event('resize'));

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange, true);
            window.removeEventListener("orientationchange", getTypeOrientation, true);
        };
    }, [])

    function getScreenOrientation(): ScreenOrientation {
        if (window.innerHeight > window.innerWidth) {
            return "portrait-primary";
        } else {
            return "landscape-primary";
        }
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);

        if (isIos() && (window.innerWidth < mobileDeviceMaxWidth || window.innerHeight < mobileDeviceMaxWidth)) {
            getTypeOrientation()
        }
    }

    function getTypeOrientation() {
        if (isIos()) {
            setOrientation(getScreenOrientation())
        } else {
            // eslint-disable-next-line no-restricted-globals
            setOrientation(screen.orientation.type)
        }
    }

    function isIos(): boolean {
        return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    }

    function isMobile(): boolean {
        return [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ].some((item) => {
            return navigator.userAgent.match(item);
        });
    }

    return {orientation, width, height, isIos, isMobile};
}
