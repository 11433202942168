import React, {useContext, useEffect, useState} from 'react';
import classes from "../App.module.css";
import {globalContext} from "../App";
import {Box} from "@mantine/core";

export default function ToursList() {
    const global = useContext(globalContext);
    const api = global.api;
    const [projectNames, setProjectNames] = useState<any>([]);

    async function getProjects() {
        const names: any = [];
        const tour = await api.getAllTours();

        for (const [key, value] of Object.entries(tour)) {
            names.push(value)
        }

        setProjectNames(names.sort(compare))
    }

    useEffect(() => {
        getProjects();
    }, []);

    function compare(a, b) {
        if (a.displayName < b.displayName) {
            return -1;
        }

        if (a.displayName > b.displayName) {
            return 1;
        }

        return 0;
    }

    return (
        <Box className={classes.appBody}>
            {projectNames.length > 0 &&
                <Box>
                    {
                        projectNames.map((el) =>
                            <Box key={el.referenceCode + el.displayName}>
                                <a href={el.referenceCode}>{el.displayName}</a>
                            </Box>
                        )
                    }
                </Box>
            }
        </Box>
    )
}
