import {Box, Button, Image} from "@mantine/core";
import React, {useContext} from "react";
import {globalContext} from "../../../App";
import classes from "./Scenes.module.css";
import minimap from "../../../assets/images/2d_plan.png";
import {observer} from "mobx-react-lite";

const Minimap = observer(() => {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const plan2DStore = global.plan2DStore;
    const stateStore = global.stateStore;

    function toggleMinimap() {
        const content: HTMLElement = document.querySelector('#minimapBlock');

        if (stateStore.state.showMinimap) {
            content.style.cssText = 'display: none';
        } else {
            content.style.cssText = 'display: block';
        }

        stateStore.updateAndSave({showMinimap: !stateStore.state.showMinimap}, 'image scene set minimap')
    }

    return (
        <>
            {tourStore.mode360TourSettings.show2dPlanMinimap && !tourStore.ifHideUiForPopup && plan2DStore.issetMinimap &&
                <Box className={classes.minimapButton}>
                    <Button onClick={toggleMinimap}
                            className={classes.roundButton}>
                        <Image src={minimap}/>
                    </Button>
                </Box>
            }
        </>
    );
})

export default Minimap;
