import {Button, Image} from "@mantine/core";
import {MouseEventHandler, useContext} from "react";
import {observer} from "mobx-react-lite";
import {globalContext} from "../../../App";
import close from "../../../assets/images/close.png";
import mute from "../../../assets/images/mute.png";
import unmute from "../../../assets/images/unmute.png";
import burger from "../../../assets/images/burger.png";
import c from "./Scenes.module.css";
import ZoomControls from "./ZoomControls";

const RightControls = observer((props: IProps) => {
    const global = useContext(globalContext);
    const stateStore = global.stateStore;
    const tourStore = global.tourStore;

    function toggleVolume() {
        stateStore.updateAndSave({mute: !stateStore.state.mute}, 'right controls update volume')
    }

    function toggleVisible() {
        stateStore.updateAndSave({isRightControlPanelExpanded: !stateStore.state.isRightControlPanelExpanded}, 'right controls update RightControlPanelExpanded')
    }

    return (
        <>
            {stateStore.state.isRightControlPanelExpanded && !tourStore.ifHideUiForPopup &&
                <>
                    <Button className={c.roundButton}
                            onClick={toggleVisible}>
                        <Image src={close}/>
                    </Button>

                    <Button className={c.roundButton}
                            onClick={toggleVolume}>
                        {stateStore.state.mute ? <Image src={mute}/> : <Image src={unmute}/>}
                    </Button>

                    <ZoomControls disabledZoomIn={props.disabledZoomIn}
                                  disabledZoomOut={props.disabledZoomOut}
                                  toggleZoomIn={props.toggleZoomIn}
                                  toggleZoomOut={props.toggleZoomOut}/>
                </>
            }

            {!stateStore.state.isRightControlPanelExpanded && !tourStore.ifHideUiForPopup &&
                <Button className={c.roundButton}
                        onClick={toggleVisible}>
                    <Image src={burger}/>
                </Button>
            }
        </>
    );
})
export default RightControls;

interface IProps {
    toggleZoomIn: MouseEventHandler;
    toggleZoomOut: MouseEventHandler;
    disabledZoomIn: boolean;
    disabledZoomOut: boolean;
}
