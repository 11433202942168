import {useContext, useState} from 'react';
import {Entity, THREE} from "aframe";
import {Camera} from "three";
import {PerspectiveCamera} from "three/src/cameras/PerspectiveCamera";
import {globalContext} from "../../../App";
import {debounce} from "../utils/Common";

export function useZoomControls(selector: string = '') {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;
    const stateStore = global.stateStore;
    const sceneStore = global.sceneStore;
    const maxZoomValue = sceneStore.currentSceneZoomData?.zoomIn
    const minZoomValue = sceneStore.currentSceneZoomData?.zoomOut
    const initialZoomValue = sceneStore.currentSceneZoomData?.initialZoom
    const [disabledZoomIn, setDisabledZoomIn] = useState<boolean>(!tourStore.ifHideUiForPopup ? initialZoomValue === maxZoomValue : false);
    const [disabledZoomOut, setDisabledZoomOut] = useState<boolean>(!tourStore.ifHideUiForPopup ? initialZoomValue === minZoomValue : false);
    const processZoomLevel = debounce(saveZoomLevel, 1000);

    function saveZoomLevel(zoom) {
        stateStore.updateAndSave({zoom}, 'zoom hook update zoom')
    }

    const step: number = 0.1;
    let finalZoom: number = 1;

    function changeZoom(delta) {
        const item: Entity<Camera> = document.getElementById(selector) as Entity<THREE.Camera>;

        if (!item) return;

        const cameraElem: PerspectiveCamera = item.getAttribute("camera") as THREE.PerspectiveCamera;

        if (!cameraElem) return;

        cameraElem.aspect = (item.getObject3D('camera') as THREE.PerspectiveCamera).aspect;
        finalZoom = parseFloat((cameraElem.zoom + delta).toFixed(2));

        // limiting the zoom
        if (finalZoom < minZoomValue) finalZoom = minZoomValue;
        setDisabledZoomOut(finalZoom === minZoomValue);
        if (finalZoom > maxZoomValue) finalZoom = maxZoomValue;
        setDisabledZoomIn(finalZoom === maxZoomValue);

        cameraElem.zoom = finalZoom;
        !tourStore.ifHideUiForPopup && processZoomLevel(cameraElem.zoom);

        item.setAttribute("camera", cameraElem);
    }

    function wheelZoom(event) {
        event.stopPropagation();
        if (event.target.localName.toLowerCase() !== 'canvas') return;

        changeZoom(event.wheelDelta / 120 / 10)
    }

    const addZoomListener = function (sceneId: string = '') {
        let sceneEl = document.getElementById(sceneId);
        if (!sceneEl) return;

        sceneEl.addEventListener("wheel", wheelZoom);
    };

    const removeZoomListener = function (sceneId: string = '') {
        let sceneEl = document.getElementById(sceneId);
        if (!sceneEl) return;

        sceneEl.removeEventListener("wheel", wheelZoom);
    };

    function getCameraSettings() {
        return 'active: true; zoom: ' + initialZoomValue + ';';
    }

    function updateCameraAspect(pageWrapperRef) {
        const item = document.querySelector("a-entity[camera]") as Entity<THREE.Camera>;

        if (!item) return;

        const cameraElem: PerspectiveCamera = item.getAttribute("camera") as THREE.PerspectiveCamera;
        if (!cameraElem) return;
        cameraElem.aspect = pageWrapperRef.current.clientWidth / pageWrapperRef.current.clientHeight;
        item.setAttribute('camera', cameraElem);
    }

    function toggleZoomIn() {
        setDisabledZoomIn(calculateZoom(1) === maxZoomValue)
        disabledZoomOut && setDisabledZoomOut(false)
        changeZoom(step)
    }

    function toggleZoomOut() {
        setDisabledZoomOut(calculateZoom(-1) === minZoomValue)
        disabledZoomIn && setDisabledZoomIn(false)
        changeZoom(-Math.abs(step))
    }

    function calculateZoom(direction) {
        return parseFloat((finalZoom + step * direction).toFixed(2))
    }

    return {
        addZoomListener, removeZoomListener,
        disabledZoomIn, disabledZoomOut, toggleZoomIn, toggleZoomOut,
        getCameraSettings,
        updateCameraAspect
    };
}
