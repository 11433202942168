import React, {useContext} from "react";
import {globalContext} from "../../App";
import {observer} from "mobx-react-lite";
import {HotspotAudioData} from "../../models/shared/HotspotBehaviour";
import {INodeData} from "../../models/shared/IProjectBase";

const AudioAssets = observer((props: IProps) => {
    const global = useContext(globalContext);
    const stateStore = global.stateStore

    return <>
        {props.hotspotsAudio.map((item) =>
            <audio key={item.name}
                   src={item.fileLink}
                   id={item.name}
                   crossOrigin="anonymous"
                   muted={stateStore.state.mute}/>
        )}

        {!props.sceneData.audioData?.isMuted &&
            <audio id="customAudio"
                   src={props.sceneData.audioData?.nodeAudioLink}
                   loop
                   crossOrigin="anonymous"
                   muted={stateStore.state.mute}/>
        }
    </>
})

export default AudioAssets;

interface IProps {
    hotspotsAudio: HotspotAudioData[];
    sceneData: INodeData;
}
