export enum SceneType {
  "image" = "image",
  "video" = "video",
  "map" = "map",
  "measurement" = "measurement",
  // "plan" = "plan",
}

export enum ViewMode {
  "scene360" = "scene360",
  "map" = "map",
  "measurement" = "measurement",
  "plan" = "plan",
}
