import {HotspotBehaviourLocation} from "../../../../models/shared/HotspotBehaviour";
import {IHotspot} from "../../../../models/shared/IProjectBase";

export function moveToTargetHotspot(hotspots: IHotspot[] = [], currenSceneName: string, container, child, planImage) {
    //TODO move this to plan init function because it doesn't need to be recomputed with every zoom
    const targetHotspotPos = hotspots
        .filter(h => currenSceneName === (h.hotspotData.behaviourData as HotspotBehaviourLocation).transitionToScene)
        .map(h => h.hotspotData.position)
        .slice(0, 1).shift();

    if (!targetHotspotPos) {
        return;
    }

    // wait before centering minimap after switching from maximap
    // use setTimeout for 2D plan mode as well for unification's sake
    setTimeout(() => {
        if (!planImage || !container) return

        const proportionImageY = planImage.naturalHeight / child?.clientHeight;
        const positionY = targetHotspotPos.y / proportionImageY;
        const positionYTemp = container.clientHeight / 2;
        container.scrollTop = positionY - positionYTemp;

        const proportionImageX = planImage.naturalWidth / child.clientWidth;
        const positionX = targetHotspotPos.x / proportionImageX
        const positionXTemp = container.clientWidth / 2;
        container.scrollLeft = positionX - positionXTemp;
    }, 100);
}
