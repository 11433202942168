import React, {useState} from "react";
import HeaderMegaMenu from "./Header";
import {RouterProvider} from "react-router-dom";
import FooterCentered from "./Footer";
import classes from '../App.module.css';
import {Box} from "@mantine/core";
import getRouter from "./RouterConfig";
import CustomOverlay from "../components/tour/view/CustomOverlay";

export default function Page() {
    const [overlay, setOverlay] = useState<boolean>(true);
    const router = getRouter(overlay, setOverlay)

    return (
        <>
            <HeaderMegaMenu/>

            <Box className={classes.contentBody}>
                <CustomOverlay className={classes.overlayBody} visible={overlay}></CustomOverlay>

                <RouterProvider router={router}/>
            </Box>

            <FooterCentered/>
        </>
    )
}
