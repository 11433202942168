import {FirebaseApp, initializeApp} from 'firebase/app';
import {makeAutoObservable} from "mobx";
import {Auth, getAuth} from "firebase/auth";
import {connectDatabaseEmulator, Database, getDatabase} from "firebase/database";
import {firebaseConfig} from '../cfg/FirebaseCfg';
import {GlobalStore} from "./GlobalStore";

export class FirebaseStore {
    global: GlobalStore;
    app: FirebaseApp | null = null;
    auth: Auth | null = null;
    database: Database | null = null;

    constructor(globalStore: GlobalStore) {
        this.global = globalStore;
        makeAutoObservable(this);
    }

    getInstance() {
        if(this.app) return this.app;

        return this.app = initializeApp(firebaseConfig);
        // connectAuthEmulator(auth, "http://localhost:9099"); // for debug mode, uncomment and up emulator 'firebase emulators:start'
    }

    getInstanceEmulator() {
        if(this.app) return this.app;

        const db = getDatabase(this.getInstance());
        connectDatabaseEmulator(db, "localhost", 9000);
    }

    getAuth() {
        if(this.auth) return this.auth;

        return this.auth = getAuth(this.getInstance());
    }

}
