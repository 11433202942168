import React, {useContext} from "react";
import {Badge, Box, NavLink, Tabs} from "@mantine/core";
import cx from 'clsx';
import {globalContext} from "../../App";
import classes from "./SidebarMenu.module.css";

export default function SidebarMenuDevelopMode(props: { selectScene: CallableFunction }) {
    const global = useContext(globalContext);
    const tourStore = global.tourStore;

    return (
        <>
            {
                tourStore?.tourNodes && Object.keys(tourStore?.tourNodes).length > 0 &&
                <Tabs className={classes.interFont} defaultValue="gallery" p={'0 24px'} mt={'16px'}>
                    <Badge className={classes.interFont} color="indigo" w={'100%'} variant="light">
                        These are development tabs
                    </Badge>
                    <Tabs.List bg={'rgb(118, 151, 184, 0.8)'}>
                        <Tabs.Tab className={classes.interFont} value="imageScene">Image</Tabs.Tab>
                        <Tabs.Tab className={classes.interFont} value="videoScene">Video</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="imageScene" p={'5px 0'} bg={'rgb(118, 151, 184, 0.8)'}>
                        {tourStore?.tourNodes.map((elem) =>
                            elem.type === 1 &&
                            // @ts-ignore
                            <Box key={elem.sceneName} className={classes.sidebarOptionsBox}>
                                <NavLink key={elem.nodeDataPath}
                                         className={cx(classes.borderItem, classes.sidebarOptionsItem, classes.interFont)}
                                         onClick={() => props.selectScene(elem.nodeName)}
                                         label={elem.sceneName}/>
                            </Box>
                        )}
                    </Tabs.Panel>

                    <Tabs.Panel value="videoScene" p={'5px 0'} bg={'rgb(118, 151, 184, 0.8)'}>
                        {tourStore?.tourNodes.map((elem) =>
                            elem.type == 2 &&
                            // @ts-ignore
                            <Box key={elem.sceneName} className={classes.sidebarOptionsBox}>
                                <NavLink key={elem.nodeDataPath}
                                         className={cx(classes.borderItem, classes.sidebarOptionsItem, classes.interFont)}
                                         onClick={() => props.selectScene(elem.nodeName)}
                                         label={elem.sceneName}/>
                            </Box>
                        )}
                    </Tabs.Panel>
                </Tabs>
            }
        </>
    );
}
