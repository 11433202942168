import {Box} from "@mantine/core";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import cx from 'clsx';
import {withTranslation} from "react-i18next";
import {globalContext} from "../../../../App";
import {I2DPlanScene, MiniMapState} from "../../../../models/shared/Plan2D";
import {AddHotspot} from "./AddHotspot";
import {moveToTargetHotspot} from "../utils/targetHotspot";
import modalPlanStyles from "../ModalPlan2D.module.css";
import contentStyles from "./ContentMinimap2DPlan.module.css";
import {getSelectedPlanIndex} from "../../utils/Plan2D";

const ContentMinimap2DPlan = observer(({t, plan2DData, getHotspots, changeSceneHandler}: IProps) => {
    const global = useContext(globalContext);
    const stateStore = global.stateStore;
    const tourStore = global.tourStore;
    const sceneStore = global.sceneStore;
    const plan2DStore = global.plan2DStore;
    const planImages: string[] = [];
    const [currentPlanIndex, setCurrentPlanIndex] = useState<number>(0);
    const [renderHotspots, setRenderHotspots] = useState<any>();
    const [notRender, setNotRender] = useState<boolean>(false);
    const [showMinimap, setShowMinimap] = useState<"block" | "none">('none');
    const [sizeMinimap, setSizeMinimap] = useState<string>(MiniMapState.small);
    const [scaleMinimap, setScaleMinimap] = useState<number>(1);

    useEffect(() => {
        if (notRender) {
            return;
        }

        //Set array with source image link
        if (Object.keys(plan2DData).length !== 0) {
            const result: I2DPlanScene[] = Object.values(plan2DData);

            for (const plan of result) {
                if (plan.nodeDataPath != null && plan.nodeDataLink != null) {
                    planImages.push(plan.nodeDataLink);
                }
            }
        }

        //Set current 2D plan element
        let currentPlanImageSrc = "";
        const selectedPlanIndex = getSelectedPlanIndex(plan2DData, sceneStore);

        if (selectedPlanIndex === -1) {
            plan2DStore.setIssetMinimap(false);
            return;
        } else {
            currentPlanImageSrc = planImages[selectedPlanIndex];
            setSizeMinimap(tourStore.mode360TourSettings.minimapState || MiniMapState.small);

            if (stateStore.state.showMinimap === null) {
                stateStore.updateAndSave(
                    {showMinimap: tourStore.mode360TourSettings.show2dPlanMinimap ?? true},
                    'content minimap update showMinimap')
            }

            plan2DStore.setCurrent2DPlanElement(selectedPlanIndex);
            setCurrentPlanIndex(selectedPlanIndex);
            plan2DStore.setIssetMinimap(true);
        }

        let i = setInterval(function () {
            const content: HTMLElement = document.querySelector('#minimapBlock');
            const planChild = (document.getElementById('minimap-child') as HTMLDivElement);
            const planImage = (document.getElementById('minimapImage') as HTMLImageElement);

            if (planImage) {
                if (stateStore.state.showMinimap) {
                    setShowMinimap('block');
                } else {
                    setShowMinimap('none');
                }

                clearInterval(i);
                setPlanImage(planImage, currentPlanImageSrc, content, planChild);
            }
        }, 200);
    }, [sizeMinimap, currentPlanIndex, stateStore.state.showMinimap]);

    function setPlanImage(planImage: HTMLImageElement, currentPlanImageSrc: string, content: HTMLElement, planChild: HTMLElement) {
        planImage.setAttribute("src", currentPlanImageSrc);

        const scaleToSet = /*tourStore.plan[currentPlanIndex ?? 0].miniMap?.scale ||*/ 1; // not available for skin version but can be easily added here
        setScaleMinimap(scaleToSet);
        // TODO is it necessary yet? What's the difference of scaleToValue with scaleToSet?
        if (/*tourStore.plan[currentPlanIndex ?? 0]?.miniMap?.scale && */sizeMinimap === MiniMapState.small) {
            const scaleToValue = /*tourStore.plan[currentPlanIndex ?? 0].miniMap?.scale || */1;
            planChild.style.cssText = 'width: ' + (100 * scaleToValue) + '%';
        } else {
            planChild.style.cssText = 'width: 100%; height: 100%';
        }

        planImage.onload = function (e) {
            //Render hotspots
            const nodes: ReactNode[] = [];

            tourStore.plan[currentPlanIndex ?? 0]?.hotspots?.forEach((elem, index) => {
                nodes.push(<AddHotspot hsd={elem.hotspotData}
                                       index={index}
                                       key={index}
                                       planImage={planImage}
                                       isMinimap={true}
                                       currentSceneName={sceneStore.currentScene!.nodeName}
                                       changeSceneHandler={changeSceneHandler}/>)
            });

            setRenderHotspots(<>{nodes}</>);
            actionMoveToCurrentHotspot();
            tourStore.setShowLoadingFor360Scene(false);
        };

        //Need if image load from cache
        actionMoveToCurrentHotspot();
    }

    function actionMoveToCurrentHotspot() {
        const container = document.querySelector('#minimap-content');// visible plan section
        const child = document.querySelector('#minimap-child'); //draggable plan image
        const planImage = (document.getElementById('minimapImage') as HTMLImageElement);
        moveToTargetHotspot(getHotspots(), sceneStore.currentScene!.nodeName, container, child, planImage);
    }

    const changeSizeMinimap = (event: any) => {
        const child = document.querySelector('#minimap-child');

        if (sizeMinimap === MiniMapState.small) {
            setSizeMinimap(MiniMapState.big);
            child.style.cssText = 'width: 100%; height: 100%';
        } else {
            setSizeMinimap(MiniMapState.small);
            child.style.cssText = 'width: ' + (100 * scaleMinimap) + '%';
            actionMoveToCurrentHotspot();
        }

        setNotRender(true);
    }

    return (
        <>
            {tourStore.mode360TourSettings.show2dPlanMinimap && plan2DStore.issetMinimap &&
                <Box style={{display: showMinimap}}
                     onClick={changeSizeMinimap}
                     id="minimapBlock"
                     className={cx(contentStyles.minimap, sizeMinimap === MiniMapState.small ? contentStyles.minimapSmallSize : contentStyles.minimapBigSize)}>
                    <Box id="minimap-content" className={modalPlanStyles.currentPlanContent}>
                        <Box id="minimap-child" className={contentStyles.childMinimap}>
                            <img id="minimapImage" className={contentStyles.minimapImage} src="" alt=""/>
                            {renderHotspots}
                        </Box>
                    </Box>
                </Box>
            }
        </>
    );
})

export default withTranslation()(ContentMinimap2DPlan)

interface IProps {
    t: any;
    plan2DData: I2DPlanScene[];
    getHotspots: Function;
    changeSceneHandler: Function;
}
