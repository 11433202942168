import {GlobalStore} from "./GlobalStore";
import {makeAutoObservable} from "mobx";

export class Plan2DStore {
    global: GlobalStore;

    current2DPlanElement: number | null = null;
    current2DPlanScale: number = 1;
    resetZoomRightControls: boolean = false;
    ifCenterPlanViewport: boolean = false;
    issetMinimap: boolean = false;

    constructor(global: GlobalStore) {
        this.global = global;
        makeAutoObservable(this);
    }

    setCurrent2DPlanElement(index: any) {
        this.current2DPlanElement = index;
    }

    setCurrent2DPlanScale(val: number) {
        this.current2DPlanScale = val;
    }

    doResetZoomRightControls(val: boolean) {
        this.resetZoomRightControls = val;
    }

    setIfCenterPlanViewport(val: boolean) {
        this.ifCenterPlanViewport = val;
    }

    setIssetMinimap(val: boolean) {
        this.issetMinimap = val;
    }

}
